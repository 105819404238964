import { Icon, Stack } from '@mui/material';
import { SyncUser } from '@schooly/api';
import { StringOrNull, UploadFile } from '@schooly/api';
import { ApiError } from '@schooly/api';
import { AvatarAuth } from '@schooly/components/avatar-auth';
import { useNotifications } from '@schooly/components/notifications';
import { CameraIcon, EditIcon } from '@schooly/style';
import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import useConvertFileToBuffer from '../../../hooks/useConvertFileToBuffer';
import useFlag from '../../../hooks/useFlag';
import useRequestWithProgress from '../../../hooks/useRequestWithProgress';
import IntlError from '../../../utils/intlError';
import { AttachmentFile } from '../../ui/Input/RichText/Attachments/attachment-types';
import AvatarEditModal from '../AvatarEditModal';

import './index.scss';

interface AvatarEditableProps {
  currentAvatarUrl?: StringOrNull;
  className?: string;
  isLoading?: boolean;
  onChange: (avatar: UploadFile) => void;
  onUploadStart?: () => void;
  onUploadComplete?: () => void;
  initials?: string;
  user?: SyncUser;
  canEdit?: boolean;
  width?: number;
  withAvatarPreview?: boolean;
}

const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png'];

const MAX_FILE_SIZE = 200 * 1024; // 200 kB;

export const AvatarEditable: React.FC<AvatarEditableProps> = ({
  currentAvatarUrl,
  width = 40,
  isLoading,
  onChange,
  onUploadStart,
  onUploadComplete,
  initials,
  user,
  canEdit,
  withAvatarPreview,
}) => {
  const { formatMessage } = useIntl();
  const [isDialogOpen, showDialog, hideDialog] = useFlag();
  const [file, setFile] = useState<AttachmentFile>();
  const [hover, setHover] = useState(false);

  const { showError } = useNotifications();

  const fileAsArray = useMemo(() => {
    if (!file) return;

    return [file];
  }, [file]);

  const convertedFiles = useConvertFileToBuffer(fileAsArray);

  const uploadImage = useCallback(async () => {
    try {
      if (!convertedFiles || !convertedFiles.length) {
        return;
      }

      const { type, size } = file?.file!;

      if (!ALLOWED_FILE_TYPES.includes(type!)) {
        throw new IntlError('error-InvalidFileFormat');
      }

      if (size > MAX_FILE_SIZE) {
        throw new IntlError('error-InvalidFileSize');
      }

      await onChange({
        data: convertedFiles[0].data,
        mime_type: type,
      });
      hideDialog();

      if (onUploadComplete) {
        onUploadComplete();
      }
    } catch (err) {
      showError(err as ApiError | IntlError);
    }
  }, [convertedFiles, file, hideDialog, onChange, onUploadComplete, showError]);

  const [handleChange, isUploading] = useRequestWithProgress(uploadImage);

  return (
    <>
      <Stack direction="row" justifyContent="center">
        <Stack
          role="button"
          title={canEdit ? formatMessage({ id: 'ariaLabel-UploadImage' }) : undefined}
          onClick={canEdit ? showDialog : undefined}
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          sx={(theme) => ({
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            height: width,
            width: width,
            borderRadius: '50%',
            overflow: 'hidden',
            border: theme.mixins.borderValue(),
            cursor: 'pointer',
            backgroundColor: theme.palette.common.lightBg,
          })}
        >
          {!isLoading && user && (
            <AvatarAuth
              withAvatarPreview={withAvatarPreview}
              user={user}
              sx={{ width: 40, height: 40 }}
            />
          )}

          {hover && (
            <Stack position="absolute" sx={{ pointerEvents: 'none' }}>
              <Icon>{user?.avatar ? <EditIcon /> : <CameraIcon />}</Icon>
            </Stack>
          )}
        </Stack>
      </Stack>
      <AvatarEditModal
        user={user}
        file={file}
        setFile={setFile}
        onClose={hideDialog}
        isOpen={isDialogOpen}
        handleChange={handleChange}
        isUploading={isUploading || !!isLoading}
      />
    </>
  );
};

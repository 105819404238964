import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { DEFAULT_DATE_FORMAT_FNS, FilterKeys } from '@schooly/api';
import {
  AgeGroupExpandedSelect,
  DateDropdown,
  DateTagSelect,
  FilterDropdown,
  filterExistingFilterOptions,
  FiltersContainer,
  GenderExpandedSelect,
  GenderTagSelect,
  getSelectedItemsWithGrouping,
  GroupExpandedSelect,
  GroupTagSelect,
  MoreButton,
  MoreButtonOption,
  NationalityExpandedSelect,
  NationalityTagSelect,
  PropertyTypeExpandedSelect,
  PropertyTypeTagSelect,
  renderPropertyGroupTags,
  SelectedItem,
  SelectedItemWithGrouping,
  SubjectExpandedSelect,
  SubjectTagSelect,
  toggleMultipleValueArrayProperty,
} from '@schooly/components/filters';
import { Genders, Nationalities, SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import { useAgeGroups, useSchoolProperties } from '@schooly/hooks/use-school-properties';
import { useSubjects } from '@schooly/hooks/use-subjects';
import { CheckIcon, DeleteIcon, InformationIcon, PlusIcon, SimpleButton } from '@schooly/style';
import { format } from 'date-fns';
import { forwardRef, LegacyRef, useCallback, useImperativeHandle, useMemo, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { MessageFormCriteria } from '../../../../context/messages/MessageContext';
import { GroupSelectFilterDropdown } from './GroupSelectFilterDropdown';
import { RecipientsFormContainer } from './ModalCreateModalRecipients.styled';

const MESSAGE_RECIPIENT_CRITERIA = [
  FilterKeys.Status,
  FilterKeys.Department,
  FilterKeys.AgeGroup,
  FilterKeys.Group,
  FilterKeys.Subject,
  FilterKeys.House,
  FilterKeys.Gender,
  FilterKeys.Nationality,
] as const;

export interface RecipientsByCriteriaFormProps {
  error: boolean;
  title: string;
  inputRef: LegacyRef<HTMLInputElement>;
  schoolId: string;
  isInitOpen?: boolean;
  criteria: MessageFormCriteria;
  userType: SchoolUserRole.Student | SchoolUserRole.Staff;
  invalidGroupIds?: string[];
  loadingGroups?: boolean;
  onCriteriaChange: (cb: (criteria: MessageFormCriteria) => MessageFormCriteria) => void;
  onClose?: (close: () => void) => void;
}

export type RecipientsByCriteriaFormRef = {
  openRecipientsForm: () => void;
};

export const RecipientsByCriteriaForm = forwardRef<
  RecipientsByCriteriaFormRef,
  RecipientsByCriteriaFormProps
>(
  (
    {
      title,
      error,
      inputRef,
      schoolId,
      isInitOpen,
      criteria,
      userType,
      invalidGroupIds,
      loadingGroups,
      onCriteriaChange,
      onClose,
    },
    ref,
  ) => {
    const { formatMessage } = useIntl();
    const [isOpen, open, close] = useFlag(isInitOpen);

    const moreButton = useRef<MoreButton | null>(null);

    const handleClose = onClose ? () => onClose(close) : close;

    const isStudentCriteria = userType === SchoolUserRole.Student;

    const { activePropertiesMap } = useSchoolProperties({ schoolId, userType });
    const { activeSubjects } = useSubjects({ schoolId });
    const {
      getAgeGroupsByLevelId,
      getAgeGroupById,
      schoolLevelsWithAgeGroupsMap,
      getSchoolLevelById,
    } = useAgeGroups({ schoolId, userType });

    const hasSubjects = Boolean(activeSubjects.length);
    const hasHouses = Boolean(activePropertiesMap.house.length);
    const hasDepartments = Boolean(activePropertiesMap.department.length);

    const criteriaStatus = criteria.status;
    const criteriaDepartment = criteria.department;
    const criteriaAgeGroup = criteria.age_group;
    const criteriaGroup = criteria.group;
    const criteriaSubject = criteria.subject;
    const criteriaHouse = criteria.house;
    const criteriaGender = criteria.gender;
    const criteriaNationality = criteria.nationality;
    const criteriaDate = criteria.date;

    const hasInvalidGroups =
      !!invalidGroupIds?.length && !!criteriaGroup?.some((id) => invalidGroupIds?.includes(id));

    const statusLabel = formatMessage({ id: 'schoolProperty-Status' });
    const departmentLabel = formatMessage({ id: 'schoolProperty-Department' });
    const ageGroupLabel = formatMessage({ id: 'schoolProperty-AgeGroup' });
    const groupLabel = formatMessage({ id: 'groups-Group' });
    const subjectLabel = formatMessage({ id: 'groups-GroupSubject' });
    const houseLabel = formatMessage({ id: 'schoolProperty-House' });
    const genderLabel = formatMessage({ id: 'peopleDetail-Gender' });
    const nationalityLabel = formatMessage({ id: 'peopleDetail-Nationality' });
    const dateLabel = formatMessage({ id: 'peopleDetail-RecipientsByCriteriaDate' });
    const dateTagLabel = formatMessage({ id: 'peopleDetail-RecipientsByCriteriaDateTag' });

    const selectedItemsForAgeGroups: SelectedItemWithGrouping[] = useMemo(() => {
      const selectedAgeGroups =
        criteriaAgeGroup?.reduce<SelectedItem[]>((acc, id) => {
          const ageGroup = getAgeGroupById(id);

          return ageGroup ? [...acc, { id: ageGroup.id, groupId: ageGroup.level_id }] : acc;
        }, []) ?? [];

      return getSelectedItemsWithGrouping(selectedAgeGroups, schoolLevelsWithAgeGroupsMap);
    }, [criteriaAgeGroup, schoolLevelsWithAgeGroupsMap, getAgeGroupById]);

    const showInvalidGroupsError = !!criteriaGroup?.some((id) => invalidGroupIds?.includes(id));

    const studentCriteriaOptions: MoreButtonOption<
      | FilterKeys.Status
      | FilterKeys.Department
      | FilterKeys.AgeGroup
      | FilterKeys.Group
      | FilterKeys.Subject
      | FilterKeys.House
      | FilterKeys.Gender
      | FilterKeys.Nationality
      | FilterKeys.Date
    >[] = [
      { value: FilterKeys.Status, label: statusLabel },
      { value: FilterKeys.Department, label: departmentLabel, visible: !isStudentCriteria },
      { value: FilterKeys.AgeGroup, label: ageGroupLabel },
      { value: FilterKeys.Group, label: groupLabel },
      { value: FilterKeys.Subject, label: subjectLabel },
      { value: FilterKeys.House, label: houseLabel },
      { value: FilterKeys.Gender, label: genderLabel },
      { value: FilterKeys.Nationality, label: nationalityLabel },
      { value: FilterKeys.Date, label: dateLabel },
    ];

    const staffCriteriaOptions = studentCriteriaOptions.filter(
      (o) => o.value !== FilterKeys.Gender && o.value !== FilterKeys.Nationality,
    );

    useImperativeHandle(ref, () => ({
      openRecipientsForm: open,
    }));

    const toggleFiltersVisible = useCallback(
      (key: keyof MessageFormCriteria) => {
        onCriteriaChange((criteria) => {
          const isCriteriaExists = criteria[key] !== undefined;

          if (isCriteriaExists) {
            const currentCriteria = { ...criteria };
            delete currentCriteria[key];
            return currentCriteria;
          }

          return {
            ...criteria,
            [key]: [],
          };
        });
      },
      [onCriteriaChange],
    );

    const {
      onSelectStatus,
      onClearStatus,
      onSelectDepartment,
      onClearDepartment,
      onSelectAgeGroup,
      onClearAgeGroup,
      onSelectGroup,
      onSelectSubject,
      onClearSubject,
      onClearGroup,
      onSelectHouse,
      onClearHouse,
      onSelectGender,
      onClearGender,
      onSelectNationality,
      onClearNationality,
      onSelectDate,
      onClearDate,
    } = useMemo(() => {
      const updateCriteria =
        (
          key:
            | FilterKeys.Status
            | FilterKeys.House
            | FilterKeys.Subject
            | FilterKeys.Group
            | FilterKeys.Department,
        ) =>
        (id: string) => {
          onCriteriaChange((criteria) => {
            return {
              ...criteria,
              [key]: criteria[key]?.includes(id)
                ? criteria[key]?.filter((ct) => ct !== id)
                : [...(criteria[key] || []), id],
            };
          });
        };
      const onSelectAgeGroup = (v: string[]) => {
        onCriteriaChange((criteria) => ({
          ...criteria,
          [FilterKeys.AgeGroup]: toggleMultipleValueArrayProperty(criteria[FilterKeys.AgeGroup], v),
        }));
      };
      const clearCriteria = (key: keyof typeof criteria) => () => {
        onCriteriaChange((criteria) => ({
          ...criteria,
          [key]: [],
        }));
      };

      const onSelectGender = (v: Genders) => {
        onCriteriaChange((criteria) => ({
          ...criteria,
          [FilterKeys.Gender]: criteria['gender']?.includes(v)
            ? criteria['gender']?.filter((ct) => ct !== v)
            : [...(criteria['gender'] || []), v],
        }));
      };

      const onSelectNationality = (v: Nationalities) => {
        onCriteriaChange((criteria) => ({
          ...criteria,
          [FilterKeys.Nationality]: criteria['nationality']?.includes(v)
            ? criteria['nationality']?.filter((ct) => ct !== v)
            : [...(criteria['nationality'] || []), v],
        }));
      };

      const onSelectDate = (v: string) => {
        onCriteriaChange((criteria) => ({
          ...criteria,
          [FilterKeys.Date]: v ? [v] : undefined,
        }));
      };

      return {
        onSelectStatus: updateCriteria(FilterKeys.Status),
        onClearStatus: clearCriteria(FilterKeys.Status),
        onSelectDepartment: updateCriteria(FilterKeys.Department),
        onClearDepartment: clearCriteria(FilterKeys.Department),
        onSelectAgeGroup,
        onClearAgeGroup: clearCriteria(FilterKeys.AgeGroup),
        onSelectGroup: updateCriteria(FilterKeys.Group),
        onClearGroup: clearCriteria(FilterKeys.Group),
        onSelectSubject: updateCriteria(FilterKeys.Subject),
        onClearSubject: clearCriteria(FilterKeys.Subject),
        onSelectHouse: updateCriteria(FilterKeys.House),
        onClearHouse: clearCriteria(FilterKeys.House),
        onSelectGender,
        onClearGender: clearCriteria(FilterKeys.Gender),
        onSelectNationality,
        onClearNationality: clearCriteria(FilterKeys.Nationality),
        onSelectDate,
        onClearDate: clearCriteria(FilterKeys.Date),
      };
    }, [onCriteriaChange]);

    const moreButtonPrefixFilters = useCallback(() => {
      const isSelectedCriteriaDate = !!criteriaDate;

      if (!isStudentCriteria && isSelectedCriteriaDate) {
        return (
          <>
            <FormControlLabel
              control={
                <IconButton sx={{ p: '9px' }}>
                  <CheckIcon />
                </IconButton>
              }
              label={
                <Stack direction="column" alignItems="center" gap={1.25}>
                  <Typography
                    variant="h3"
                    color={isSelectedCriteriaDate ? undefined : 'common.grey'}
                  >
                    <FormattedMessage id="peopleDetail-RecipientsByCriteriaDate" />
                  </Typography>
                </Stack>
              }
            />

            <Divider sx={{ my: 1 }} />
          </>
        );
      } else if (isStudentCriteria) {
        return (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isSelectedCriteriaDate}
                  onChange={() => {
                    onClearDate();
                    toggleFiltersVisible(FilterKeys.Date);
                  }}
                  sx={{ alignSelf: 'flex-start' }}
                />
              }
              label={
                <Stack direction="column" alignItems="center" gap={1.25}>
                  <Typography
                    variant="h3"
                    color={isSelectedCriteriaDate ? undefined : 'common.grey'}
                  >
                    <FormattedMessage id="peopleDetail-RecipientsByCriteriaDate" />
                  </Typography>

                  {isSelectedCriteriaDate && (
                    <Typography
                      variant="body1"
                      sx={(theme) => ({ color: `${theme.palette.common.grey} !important` })}
                    >
                      <FormattedMessage id="peopleDetail-RecipientsByCriteriaDateDescription" />
                    </Typography>
                  )}
                </Stack>
              }
            />

            <Divider sx={{ my: 1 }} />
          </>
        );
      }
    }, [criteriaDate, isStudentCriteria, toggleFiltersVisible, onClearDate]);

    return (
      <>
        <input type="hidden" ref={inputRef} />

        {isOpen ? (
          <>
            <RecipientsFormContainer error={error || hasInvalidGroups} gap={1.5}>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h4">{title}</Typography>
                <IconButton inverse onClick={handleClose}>
                  <DeleteIcon />
                </IconButton>
              </Stack>

              <FiltersContainer>
                {criteriaDate && isStudentCriteria && (
                  <Stack direction="row" alignItems="center" gap={0.5}>
                    <DateDropdown
                      disabled={loadingGroups}
                      date={criteriaDate[0]}
                      label={dateTagLabel}
                      onSetDate={(date) =>
                        onSelectDate(date ? format(date, DEFAULT_DATE_FORMAT_FNS) : '')
                      }
                      onClear={() => {
                        onClearDate();
                        toggleFiltersVisible(FilterKeys.Date);
                      }}
                    />

                    <Tooltip
                      PopperProps={{
                        placement: 'top',
                      }}
                      title={formatMessage({ id: 'messages-CriteriaDateParentsTooltip' })}
                    >
                      <IconButton inverse>
                        <InformationIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                )}

                {criteriaDate && !isStudentCriteria && (
                  <Stack direction="row" alignItems="center" gap={0.25}>
                    <DateTagSelect key="date" date={criteriaDate[0]} disabled />

                    <Tooltip
                      title={formatMessage({ id: 'messages-CriteriaDateStaffTooltip' })}
                      PopperProps={{
                        placement: 'top',
                      }}
                    >
                      <IconButton inverse>
                        <InformationIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                )}

                {criteriaStatus && (
                  <FilterDropdown
                    selectLabel={formatMessage({ id: 'select' })}
                    onClear={() => toggleFiltersVisible(FilterKeys.Status)}
                    label={statusLabel}
                    tags={(open) =>
                      criteriaStatus.map((id) => (
                        <PropertyTypeTagSelect
                          userRole={userType}
                          schoolId={schoolId}
                          sx={{ maxWidth: 200, backgroundColor: 'background.paper' }}
                          key={id}
                          id={id}
                          onClick={open}
                        />
                      ))
                    }
                  >
                    {(onClose) => (
                      <PropertyTypeExpandedSelect
                        propertyType={SchoolPropertyType.Status}
                        userRole={userType}
                        schoolId={schoolId}
                        selectedIds={criteriaStatus}
                        onSelectId={onSelectStatus}
                        onClose={onClose}
                        onClear={onClearStatus}
                        showArchived={false}
                      />
                    )}
                  </FilterDropdown>
                )}
                {!isStudentCriteria && criteriaDepartment && (
                  <FilterDropdown
                    onClear={() => toggleFiltersVisible(FilterKeys.Department)}
                    label={departmentLabel}
                    tags={(open) =>
                      criteriaDepartment.map((id) => (
                        <PropertyTypeTagSelect
                          userRole={SchoolUserRole.Staff}
                          sx={{ maxWidth: 200, backgroundColor: 'background.paper' }}
                          schoolId={schoolId}
                          key={id}
                          id={id}
                          onClick={open}
                        />
                      ))
                    }
                  >
                    {(onClose) => (
                      <PropertyTypeExpandedSelect
                        propertyType={SchoolPropertyType.Department}
                        userRole={SchoolUserRole.Staff}
                        schoolId={schoolId}
                        selectedIds={criteriaDepartment}
                        onSelectId={onSelectDepartment}
                        onClose={onClose}
                        onClear={onClearDepartment}
                        showArchived={false}
                      />
                    )}
                  </FilterDropdown>
                )}
                {criteriaAgeGroup && (
                  <FilterDropdown
                    selectLabel={formatMessage({ id: 'select' })}
                    onClear={() => toggleFiltersVisible(FilterKeys.AgeGroup)}
                    label={ageGroupLabel}
                    tags={(open) =>
                      renderPropertyGroupTags({
                        selectedItems: selectedItemsForAgeGroups,
                        onClick: open,
                        getProperty: (i) =>
                          i.isGroup
                            ? getSchoolLevelById(i.id)
                            : { ...getAgeGroupById(i.id), type: SchoolPropertyType.AgeGroup },
                        getTooltip: (i) =>
                          i.isGroup
                            ? getAgeGroupsByLevelId(i.id).map((ageGroup) => (
                                <Typography key={ageGroup.id}>{ageGroup.name}</Typography>
                              ))
                            : null,
                        tagProps: {
                          userRole: SchoolUserRole.Student,
                          sx: { maxWidth: 200, backgroundColor: 'background.paper' },
                        },
                      })
                    }
                  >
                    {(onClose) => (
                      <AgeGroupExpandedSelect
                        userRole={SchoolUserRole.Student}
                        schoolId={schoolId}
                        selectedIds={criteriaAgeGroup}
                        onSelect={onSelectAgeGroup}
                        onClose={onClose}
                        onClear={onClearAgeGroup}
                        showArchived={false}
                      />
                    )}
                  </FilterDropdown>
                )}
                {criteriaGroup && (
                  <GroupSelectFilterDropdown
                    selectLabel={formatMessage({ id: 'select' })}
                    onClear={() => toggleFiltersVisible(FilterKeys.Group)}
                    label={groupLabel}
                    width={500}
                    disabled={loadingGroups}
                    groupIds={criteriaGroup}
                    invalidGroupIds={invalidGroupIds}
                    tags={(open) =>
                      criteriaGroup.map((id) => (
                        <>
                          <GroupTagSelect
                            key={id}
                            disabled={loadingGroups}
                            sx={(theme) => ({
                              maxWidth: 200,
                              backgroundColor: 'background.paper',
                              '.MuiChip-label': {
                                color: !!invalidGroupIds?.includes(id)
                                  ? theme.palette.error.main
                                  : theme.palette.primary.main,
                              },
                              borderColor: `${
                                !!invalidGroupIds?.includes(id)
                                  ? theme.palette.error.main
                                  : undefined
                              } !important`,
                            })}
                            id={id}
                            onClick={open}
                          />
                        </>
                      ))
                    }
                  >
                    {(onClose) => (
                      <GroupExpandedSelect
                        selectedIds={criteriaGroup}
                        schoolId={schoolId}
                        onSelectGroup={onSelectGroup}
                        onClose={onClose}
                        onClear={onClearGroup}
                        filters={{
                          [FilterKeys.SingleDate]: [
                            criteriaDate?.[0] ?? format(new Date(), DEFAULT_DATE_FORMAT_FNS),
                          ],
                        }}
                        invalidGroupIds={invalidGroupIds}
                      />
                    )}
                  </GroupSelectFilterDropdown>
                )}
                {criteriaSubject && (
                  <FilterDropdown
                    onClear={() => toggleFiltersVisible(FilterKeys.Subject)}
                    label={subjectLabel}
                    tags={(open) =>
                      criteriaSubject.map((id) => (
                        <SubjectTagSelect
                          schoolId={schoolId}
                          sx={{ maxWidth: 200, backgroundColor: 'background.paper' }}
                          key={id}
                          id={id}
                          onClick={open}
                        />
                      ))
                    }
                  >
                    {(onClose) => (
                      <SubjectExpandedSelect
                        schoolId={schoolId}
                        selectedIds={criteriaSubject}
                        onSelectSubjectId={onSelectSubject}
                        onClose={onClose}
                        onClear={onClearSubject}
                        showArchived={false}
                      />
                    )}
                  </FilterDropdown>
                )}
                {criteriaHouse && (
                  <FilterDropdown
                    selectLabel={formatMessage({ id: 'select' })}
                    onClear={() => toggleFiltersVisible(FilterKeys.House)}
                    label={houseLabel}
                    tags={(open) =>
                      criteriaHouse.map((id) => (
                        <PropertyTypeTagSelect
                          userRole={SchoolUserRole.Student}
                          sx={{ maxWidth: 200, backgroundColor: 'background.paper' }}
                          schoolId={schoolId}
                          key={id}
                          id={id}
                          onClick={open}
                        />
                      ))
                    }
                  >
                    {(onClose) => (
                      <PropertyTypeExpandedSelect
                        propertyType={SchoolPropertyType.House}
                        userRole={SchoolUserRole.Student}
                        schoolId={schoolId}
                        selectedIds={criteriaHouse}
                        onSelectId={onSelectHouse}
                        onClose={onClose}
                        onClear={onClearHouse}
                        showArchived={false}
                      />
                    )}
                  </FilterDropdown>
                )}
                {isStudentCriteria && criteriaGender && (
                  <FilterDropdown
                    onClear={() => toggleFiltersVisible(FilterKeys.Gender)}
                    label={genderLabel}
                    tags={(open) =>
                      criteriaGender.map((v) => (
                        <GenderTagSelect
                          sx={{ maxWidth: 200, backgroundColor: 'background.paper' }}
                          key={v}
                          gender={v}
                          onClick={open}
                        />
                      ))
                    }
                  >
                    {(onClose) => (
                      <GenderExpandedSelect
                        selectedGenders={criteriaGender}
                        onSelectGender={onSelectGender}
                        onClose={onClose}
                        onClear={onClearGender}
                      />
                    )}
                  </FilterDropdown>
                )}

                {isStudentCriteria && criteriaNationality && (
                  <FilterDropdown
                    onClear={() => toggleFiltersVisible(FilterKeys.Nationality)}
                    label={nationalityLabel}
                    tags={(open) =>
                      criteriaNationality.map((v) => (
                        <NationalityTagSelect
                          sx={{ maxWidth: 200, backgroundColor: 'background.paper' }}
                          key={v}
                          nationality={v}
                          onClick={open}
                        />
                      ))
                    }
                  >
                    {(onClose) => (
                      <NationalityExpandedSelect
                        selectedNationalities={criteriaNationality}
                        onSelectNationality={onSelectNationality}
                        onClose={onClose}
                        onClear={onClearNationality}
                      />
                    )}
                  </FilterDropdown>
                )}

                <Box
                  sx={{
                    '.MuiTooltip-tooltipArrow > .MuiStack-root': {
                      paddingBottom: 1.25,
                    },
                    '.MuiTypography-h4': {
                      marginBottom: 1,
                    },
                    '.MuiTooltip-tooltip': {
                      width: 320,
                      maxWidth: 320,
                    },
                  }}
                >
                  <MoreButton
                    ref={moreButton}
                    options={filterExistingFilterOptions({
                      filterOptions: isStudentCriteria
                        ? studentCriteriaOptions.filter((o) => o.value !== FilterKeys.Date)
                        : staffCriteriaOptions.filter((o) => o.value !== FilterKeys.Date),
                      hasHouses,
                      hasSubjects,
                      hasDepartments,
                    })}
                    selectedOptions={MESSAGE_RECIPIENT_CRITERIA.filter((key) => !!criteria[key])}
                    onToggleOption={toggleFiltersVisible}
                    buttonTitle={formatMessage({ id: 'action-AddCriteria' })}
                    title={formatMessage({ id: 'groups-AvailableCriteria' })}
                    renderPrefixFilters={moreButtonPrefixFilters}
                  />
                </Box>
              </FiltersContainer>
            </RecipientsFormContainer>

            {showInvalidGroupsError && (
              <Typography color="error.main" mt={-1.5} ml={1.5}>
                <FormattedMessage id="messages-InvalidGroupsError" />
              </Typography>
            )}
          </>
        ) : (
          <SimpleButton startIcon={<PlusIcon />} onClick={open} sx={{ alignSelf: 'flex-start' }}>
            {formatMessage({ id: 'messages-AddByCriteria' })}
          </SimpleButton>
        )}
      </>
    );
  },
);

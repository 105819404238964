import {
  Backdrop,
  Box,
  ClickAwayListener,
  Stack,
  Tooltip,
  TooltipProps,
  Typography,
} from '@mui/material';
import { CheckOverflow, FiltersDropdownButton } from '@schooly/components/filters';
import { useFlag } from '@schooly/hooks/use-flag';
import { ChevronDownSmallIcon, TagSelect } from '@schooly/style';
import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

type FilterDropdownProps = {
  label: string;
  selectLabel?: string;
  onClear?: () => void;
  tags: (onOpen: () => void) => ReactNode[];
  children: (onClose: () => void) => ReactNode;
  disabled?: boolean;
  width?: number;
  groupIds: string[];
  invalidGroupIds?: string[];
} & Partial<Omit<TooltipProps, 'children'>>;

export type FilterDropdownComponentRef = {
  hiddenTags: Set<number>;
};

export const GroupSelectFilterDropdown: FC<FilterDropdownProps> = ({
  tags,
  label,
  children,
  onClear,
  selectLabel,
  disabled,
  width = 400,
  groupIds,
  invalidGroupIds,
  ...tooltipProps
}) => {
  const [hiddenTags, setHiddenTags] = useState<Set<number>>(new Set());
  const [hiddenTagsError, setHiddenTagsError] = useState(false);
  const [opened, open, close] = useFlag();

  useEffect(() => {
    let hasError = false;

    hiddenTags?.forEach((index) => {
      const groupId = groupIds?.[index];
      if (groupId && invalidGroupIds?.includes(groupId)) {
        hasError = true;
      }
    });

    setHiddenTagsError(hasError);
  }, [invalidGroupIds, groupIds, hiddenTags]);

  const setTagHidden = useCallback((index: number, isHidden: boolean) => {
    setHiddenTags((tags) => {
      if (isHidden) {
        tags.add(index);
      } else {
        tags.delete(index);
      }
      return new Set(tags);
    });
  }, []);

  const tagNodes = tags(open).map((tagNode, i) =>
    i === 0 ? (
      tagNode
    ) : (
      <CheckOverflow index={i} onHideTag={setTagHidden} key={`_${i}`}>
        {tagNode}
      </CheckOverflow>
    ),
  );

  return (
    <>
      <Backdrop open={opened} invisible sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} />
      <ClickAwayListener onClickAway={close}>
        <div>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={close}
            open={opened}
            placement="bottom-start"
            componentsProps={{
              tooltip: {
                sx: (theme) => ({
                  width,
                  maxWidth: width,
                  borderRadius: theme.spacing(1),
                  border: `1px solid ${theme.palette.common.light3}`,
                  padding: 0,
                  overflow: 'hidden',
                  margin: `${theme.spacing(0.5, 0, 0)} !important`,
                }),
              },
            }}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={children(close)}
            {...tooltipProps}
          >
            <Box
              flexDirection="row"
              sx={{
                cursor: 'pointer',
                position: 'relative',
                zIndex: (theme) => (opened ? theme.zIndex.drawer + 2 : undefined),
              }}
              alignItems="center"
              onClick={() => !disabled && open()}
            >
              {!opened ? (
                <Stack flexDirection="row" alignItems="center">
                  {tagNodes.length ? (
                    tagNodes
                  ) : (
                    <Typography variant="h3">
                      {label}: {selectLabel ?? <FormattedMessage id="filter-All" />}
                    </Typography>
                  )}{' '}
                  {!!hiddenTags.size && (
                    <TagSelect
                      onClick={!disabled ? open : undefined}
                      sx={(theme) => ({
                        backgroundColor: 'white',
                        ml: 0.5,
                        borderColor: hiddenTagsError
                          ? `${theme.palette.error.main} !important`
                          : undefined,
                        color: hiddenTagsError
                          ? `${theme.palette.error.main} !important`
                          : undefined,
                      })}
                      label={`+${hiddenTags.size}`}
                    />
                  )}
                  <Box ml={0.5}>
                    <ChevronDownSmallIcon color="black" />
                  </Box>
                </Stack>
              ) : (
                <FiltersDropdownButton
                  onClick={(e) => {
                    e.stopPropagation();
                    close();
                  }}
                  onClear={
                    onClear
                      ? () => {
                          onClear();
                          close();
                        }
                      : undefined
                  }
                >
                  {label}
                </FiltersDropdownButton>
              )}
            </Box>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </>
  );
};

import { Divider, IconButton, Stack } from '@mui/material';
import {
  DEFAULT_DATE_FORMAT_FNS,
  FilterKeys,
  GetMessagesQueryFilters,
  Message,
  MESSAGE_ARRANGE_BY_FILTER_KEYS,
  MessageArrangeBy,
  MESSAGES_FILTER_KEYS,
  useGetMessagesQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { ChartsCustomGrid, MessageCharts, useCharts } from '@schooly/components/charts';
import {
  ArrangedByCollapsableSection,
  PageHeader,
  PageHeaderSearchInput,
  StoredFilterSections,
  useArrangeByFromSearchParams,
  useFiltersStateFromSearchParams,
  useLastAppliedFiltersState,
  useSaveLastAppliedFiltersState,
  useSyncFiltersStateWithSearchParams,
} from '@schooly/components/filters';
import { MainGridNoResultsStub } from '@schooly/components/stubs';
import { usePrevious } from '@schooly/hooks/use-previous';
import {
  ChartIcon,
  GridBody,
  MainPageGrid,
  PlusIcon,
  SkeletonGridLoader,
  SkeletonRows,
} from '@schooly/style';
import { format } from 'date-fns';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import AccessDenied from '../../components/common/AccessDenied';
import useAppLocation from '../../hooks/useAppLocation';
import useSchoolYears from '../../hooks/useSchoolYears';
import {
  ArrangedByMessagesGrid,
  MessagesArrangedByList,
  useGetArrangeByLabel,
} from './MessagesArrangedByList';
import { MessageFilters, MessagesFilters } from './MessagesFilters';
import { MessageRow, MessagesHeader } from './MessagesGrid';

import './index.scss';

export const PAGE_SIZE = 30;
export const SKELETON_ROWS = 5;

type MessagesContentProps = {
  initialFilters?: GetMessagesQueryFilters;
  initialArrangeBy?: MessageArrangeBy | null;
};

const MessagesContent: FC<MessagesContentProps> = ({ initialFilters, initialArrangeBy }) => {
  const { formatMessage } = useIntl();
  const location = useAppLocation();
  const navigate = useNavigate();
  const { schoolId = '', permissions } = useAuth();
  const { defaultValidity } = useSchoolYears();
  const { onToggleChartsOpened, isChartsOpened, showZeroValues } = useCharts();
  const messageFiltersRef = useRef<MessageFilters | null>(null);
  const { lastAppliedFilter, lastAppliedArrangeBy } = useLastAppliedFiltersState({
    type: StoredFilterSections.Messages,
    filterKeys: MESSAGES_FILTER_KEYS,
    arrangeByKeys: MESSAGE_ARRANGE_BY_FILTER_KEYS,
    schoolId,
  });

  const arrangeByFromSearchParams = useArrangeByFromSearchParams(MESSAGE_ARRANGE_BY_FILTER_KEYS);
  let arrangeBy = initialArrangeBy;
  if (lastAppliedArrangeBy !== undefined) {
    arrangeBy = lastAppliedArrangeBy;
  } else if (arrangeByFromSearchParams !== undefined) {
    arrangeBy = arrangeByFromSearchParams;
  }

  const [arrangeByWithCustomGrid, setArrangeByCustomGrid] = useState<{
    arrangeBy: MessageArrangeBy;
    totalCount?: number;
    grid: ChartsCustomGrid<GetMessagesQueryFilters> | null;
  } | null>(arrangeBy ? { arrangeBy, grid: null } : null);

  const prevArrangeByWithCustomGrid = usePrevious(arrangeByWithCustomGrid);

  const defaultFilters: GetMessagesQueryFilters = useMemo(
    () => ({
      [FilterKeys.Date]: [
        defaultValidity?.start || format(new Date(), DEFAULT_DATE_FORMAT_FNS),
        defaultValidity?.end || format(new Date(), DEFAULT_DATE_FORMAT_FNS),
      ],
    }),
    [defaultValidity],
  );

  useEffect(() => {
    if (!prevArrangeByWithCustomGrid && arrangeByWithCustomGrid && !isChartsOpened) {
      onToggleChartsOpened();
    }
  }, [arrangeByWithCustomGrid, isChartsOpened, onToggleChartsOpened, prevArrangeByWithCustomGrid]);

  const handleSetCustomGrid = useCallback(
    (grid: ChartsCustomGrid<GetMessagesQueryFilters> | null) => {
      setArrangeByCustomGrid((arrangeBy) => (arrangeBy ? { ...arrangeBy, grid } : null));
    },
    [],
  );

  const handleSetArrangeByTotalCount = useCallback((totalCount?: number) => {
    setArrangeByCustomGrid((arrangeBy) => (arrangeBy ? { ...arrangeBy, totalCount } : null));
  }, []);

  const handleCreateMessageClick = useCallback(() => {
    navigate('/messages/new', { state: { backgroundLocation: location } });
  }, [navigate, location]);

  const initialFiltersState = useFiltersStateFromSearchParams({
    filterKeys: MESSAGES_FILTER_KEYS,
    defaultFilters,
    initialFilters,
  });

  const defaultUserFilters = useMemo(() => {
    return { ...defaultFilters, ...initialFilters };
  }, [defaultFilters, initialFilters]);

  const {
    data,
    isLoading,
    params,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    setParams,
    error,
  } = useGetMessagesQuery(
    {
      school_id: schoolId || '',
      query: '',
      page_size: PAGE_SIZE,
      filters: lastAppliedFilter ?? initialFiltersState,
    },
    { refetchOnMount: 'always', enabled: arrangeByWithCustomGrid === null },
  );

  useSyncFiltersStateWithSearchParams({
    pathname: '/messages',
    filters: params.filters,
    arrangeBy: arrangeByWithCustomGrid ? arrangeByWithCustomGrid.arrangeBy : null,
    charts: isChartsOpened,
    zeroes: showZeroValues,
  });

  useSaveLastAppliedFiltersState({
    type: StoredFilterSections.Messages,
    filters: params.filters,
    arrangeBy: arrangeByWithCustomGrid ? arrangeByWithCustomGrid.arrangeBy : null,
    schoolId: schoolId || '',
  });

  const handleSetFiltersQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const handleSetFilters = useCallback(
    (filters: GetMessagesQueryFilters) => {
      setParams((p) => ({ ...p, filters }));
    },
    [setParams],
  );

  const entries = useMemo(
    () => data?.pages.reduce((prev, curr) => [...prev, ...curr.results], [] as Message[]) ?? [],
    [data?.pages],
  );

  const filtersDateString = params.filters.date?.join('');
  const notActualInitialDate =
    !defaultValidity?.isActual &&
    filtersDateString === defaultFilters?.date?.join('') &&
    filtersDateString !== initialFilters?.date?.join('');

  const handleSetArrangeBy = useCallback((arrangeBy: MessageArrangeBy | null) => {
    setArrangeByCustomGrid(arrangeBy ? { arrangeBy, grid: null } : null);
  }, []);

  const { getRowName } = useGetArrangeByLabel();

  const noResults = !isLoading && !entries.length;
  const showCharts = isChartsOpened && !noResults;

  const customGridCount = arrangeByWithCustomGrid?.grid
    ? arrangeByWithCustomGrid.grid.count
    : arrangeByWithCustomGrid?.totalCount;

  const total = customGridCount || data?.pages[0]?.count;

  if (!schoolId) return null;

  if (error) {
    return <AccessDenied />;
  }

  return (
    <>
      <Stack gap={1}>
        <PageHeader
          pageTitleCounter={total}
          buttonTextId="messages-NewMessage"
          pageTitleTextId="section-Messages"
          showActionButton={permissions.includes('message_creator')}
          buttonIcon={<PlusIcon />}
          onButtonClick={handleCreateMessageClick}
        >
          <PageHeaderSearchInput
            value={params.query || ''}
            onChangeText={handleSetFiltersQuery}
            placeholder={formatMessage({ id: 'messages-Search' })}
          />
        </PageHeader>
        <MessagesFilters
          defaultFilters={defaultFilters}
          onSetFilters={handleSetFilters}
          filters={params.filters}
          defaultSchoolYear={defaultValidity}
          notActualInitialDate={notActualInitialDate}
          schoolId={schoolId || ''}
          arrangeBy={arrangeByWithCustomGrid?.arrangeBy || null}
          onSetArrangeBy={handleSetArrangeBy}
          ref={messageFiltersRef}
          defaultUserFilters={defaultUserFilters}
          defaultUserArrangeBy={initialArrangeBy ?? null}
        />
      </Stack>
      {showCharts && (
        <Stack mt={showCharts || (!showCharts && arrangeByWithCustomGrid) ? 3 : 0}>
          <MessageCharts
            arrangeBy={arrangeByWithCustomGrid?.arrangeBy || null}
            filters={params.filters}
            searchQuery={params.query}
            onOpenCustomGrid={handleSetCustomGrid}
            onOpenArrangeByDropdown={messageFiltersRef.current?.openArrangeBy}
          />
        </Stack>
      )}
      {!arrangeByWithCustomGrid ? (
        <>
          <MainPageGrid mt={2.4}>
            <MessagesHeader
              rightIcon={
                !showCharts ? (
                  <IconButton onClick={onToggleChartsOpened} disabled={noResults} inverse>
                    <ChartIcon />
                  </IconButton>
                ) : undefined
              }
            />
            <GridBody>
              {entries?.map((entry) => (
                <MessageRow message={entry} key={entry.id} />
              ))}
              {isLoading && <SkeletonRows columnsCount={8} amount={PAGE_SIZE} />}
              <SkeletonGridLoader
                isFetching={isLoading || isFetchingNextPage}
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage}
                columnsCount={8}
                amount={Math.min(
                  PAGE_SIZE,
                  total && data ? total - data.pages.length * PAGE_SIZE : PAGE_SIZE,
                )}
              />
            </GridBody>
          </MainPageGrid>
          {!isLoading && !entries.length && (
            <MainGridNoResultsStub textId="messages-NoResults-title" />
          )}
        </>
      ) : (
        <>
          {!showCharts && (
            <>
              <Stack flexDirection="row" justifyContent="flex-end" pb={1} pt={0.5} pr={1}>
                <IconButton onClick={onToggleChartsOpened} disabled={noResults} inverse>
                  <ChartIcon />
                </IconButton>
              </Stack>
              <Divider />
            </>
          )}
          {arrangeByWithCustomGrid.grid ? (
            <ArrangedByCollapsableSection
              isExpanded
              count={arrangeByWithCustomGrid.grid.count}
              title={getRowName(
                arrangeByWithCustomGrid.grid.title,
                arrangeByWithCustomGrid.arrangeBy,
              )}
            >
              <ArrangedByMessagesGrid
                count={arrangeByWithCustomGrid.grid.count}
                filters={arrangeByWithCustomGrid.grid.filters}
                schoolId={schoolId}
                query={params.query}
              />
            </ArrangedByCollapsableSection>
          ) : (
            <MessagesArrangedByList
              schoolId={schoolId}
              filters={params.filters}
              arrangeByKey={arrangeByWithCustomGrid.arrangeBy}
              query={params.query}
              onSetTotalCount={handleSetArrangeByTotalCount}
            />
          )}
        </>
      )}
    </>
  );
};

export default MessagesContent;

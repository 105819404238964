import { Icon, Stack, Tooltip, Typography } from '@mui/material';
import { ArrowLeftIcon } from '@schooly/style';
import { FC, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { GroupContextState } from '../../../context/groups/GroupContext';
import { ProfileContextState } from '../../../context/profile/ProfileContext';
import { useRouter } from '../../../context/router/useRouter';
import { getUserFullName } from '../../../helpers/users';
import { useApiSlice } from '../../../hooks/useApiSlice';
import { AddGroupModalContextState } from '../../../pages/Groups/AddGroupModal/useAddGroupModal';
import { UserRoleModalContextState } from '../../../pages/UserRoles/CreateUserRoleModal/Context/useUserRoleModal';
import { ModalHeaderBackLink } from './ModalHeader.styled';

export const ModalHeaderGoBack: FC = () => {
  const { $t } = useIntl();
  const { stack, goBack } = useRouter();
  const withGoBack = stack.length > 1;

  const route = withGoBack ? stack[stack.length - 2] : undefined;

  const { data: assessmentPreviewData } = useApiSlice((state) => state.assessments.preview);
  const { data: reportPreviewData } = useApiSlice((state) => state.reports.preview);

  const title = useMemo(() => {
    if (!route) {
      return undefined;
    }

    const state = route.state?.state ? JSON.parse(route.state.state as string) : undefined;
    const m = /^\/([a-z0-9-]+)\/[a-z0-9-]+(\/([^/]+))?/.exec(route.location.pathname);

    if (m) {
      switch (m[1]) {
        case 'students':
        case 'parents':
        case 'staff': {
          const user = (state as ProfileContextState)?.user;
          return user ? `"${getUserFullName(user)}"` : $t({ id: `userType-${m[1]}` });
        }
        case 'groups': {
          switch (m[3]) {
            case 'new':
            case 'edit': {
              const group = state as AddGroupModalContextState;
              return group?.name ? `"${group?.name}"` : $t({ id: 'groups-Group' });
            }
            case 'attendance': {
              const group = (state as GroupContextState)?.group;
              return group?.name
                ? `"${group?.name}" ${$t({ id: 'attendance-Title' })}`
                : `${$t({ id: 'groups-Group' })} ${$t({ id: 'attendance-Title' })}`;
            }
            case 'markbook': {
              const group = (state as GroupContextState)?.group;
              return group?.name
                ? `"${group?.name}" ${$t({ id: 'groups-Assessment-Markbook' })}`
                : `${$t({ id: 'groups-Group' })} ${$t({ id: 'groups-Assessment-Markbook' })}`;
            }
            default: {
              const group = (state as GroupContextState)?.group;
              return group?.name ? `"${group?.name}"` : $t({ id: 'groups-Group' });
            }
          }
        }
        case 'messages': {
          return $t({ id: 'messages-MessageTitle' });
        }
        case 'assessments': {
          return assessmentPreviewData?.name
            ? `"${assessmentPreviewData.name}"`
            : $t({ id: 'assessments-Assessment' });
        }
        case 'reports': {
          return reportPreviewData?.name
            ? `"${reportPreviewData.name}"`
            : $t({ id: 'reports-Report' });
        }
        case 'attendance': {
          // TODO: retrieve attendance title (moving to RouterStateContext required)
          return $t({ id: 'attendance-Title' });
        }
        case 'conduct': {
          // No custom title for Conduct modal. Will display just common placeholder
          return $t({ id: 'conduct-Title' });
        }
        case 'userroles': {
          const role = state as UserRoleModalContextState;
          return role
            ? `"${role.roleName}"`
            : $t({ id: m[3] === 'new' ? 'userRoles-AddRole' : 'userRoles-EditRole' });
        }
      }
    }
  }, [$t, assessmentPreviewData?.name, reportPreviewData?.name, route]);

  return route ? (
    <ModalHeaderBackLink
      to={route.location}
      onClick={(event) => {
        event.preventDefault();
        goBack();
      }}
    >
      <Tooltip
        arrow={false}
        componentsProps={{
          tooltip: {
            sx: (theme) => ({
              alignItems: 'center',
              padding: 0,
              width: '100%',
              borderRadius: theme.spacing(0.5),
              margin: `${theme.spacing(0.5, 0, 0)} !important`,
              boxShadow: '0px 2px 10px 2px #24275B1A, 0px 2px 4px 1px #24275B1A',
            }),
          },
        }}
        title={
          <Stack
            sx={{
              px: 0.5,
              py: 0.25,
            }}
          >
            <Typography variant="caption">
              {title ? (
                <FormattedMessage id="action-BackTo" values={{ title }} />
              ) : (
                <FormattedMessage id="action-Back" />
              )}
            </Typography>
          </Stack>
        }
      >
        <Icon sx={{ pt: 0.25 }}>
          <ArrowLeftIcon />
        </Icon>
      </Tooltip>
    </ModalHeaderBackLink>
  ) : null;
};

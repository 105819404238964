import { Button } from '@mui/material';
import { DoneIcon, ModalConfirm, ModalFooter, ModalHeader } from '@schooly/style';
import { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useMessage } from '../../../context/messages/useMessage';

export const MessagesCreateModalRemoveArchived: FC = () => {
  const { parentsCriteria, staffCriteria } = useMessage();
  const { $t } = useIntl();
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    const checkedParentsCriteria = parentsCriteria.filter(
      (criteria) => !criteria.subject?.archived && !criteria.school_property?.archived,
    );
    const checkedStaffCriteria = staffCriteria.filter(
      (criteria) => !criteria.subject?.archived && !criteria.school_property?.archived,
    );

    if (
      checkedParentsCriteria.length !== parentsCriteria.length ||
      checkedStaffCriteria.length !== staffCriteria.length
    ) {
      setShowNotification(true);
    }
  }, [parentsCriteria, staffCriteria]);

  return (
    <ModalConfirm open={showNotification}>
      <ModalHeader title={$t({ id: 'messages-SomeCriteriaArchived' })} multiline />
      <ModalFooter withBorderTop={false} sx={{ justifyContent: 'flex-end' }}>
        <Button startIcon={<DoneIcon />} onClick={() => setShowNotification(false)}>
          <FormattedMessage id="action-Okay" />
        </Button>
      </ModalFooter>
    </ModalConfirm>
  );
};

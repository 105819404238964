import { Box, Button, Stack } from '@mui/material';
import { DeleteIcon, ModalFooter, Spin } from '@schooly/style';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { useMessage } from '../../../context/messages/useMessage';

export const MessagesCreateModalFooter = () => {
  const {
    messageId,
    saving,
    deleting,
    actions,
    canPublish,
    canSubmit,
    isPublishInProgress,
    isSubmitInProgress,
    isCheckGroupsInProgress,
  } = useMessage();

  const handleDelete = useCallback(() => {
    actions.setDeletingConfirmation(true);
  }, [actions]);

  const disabled =
    saving || deleting || isPublishInProgress || isSubmitInProgress || isCheckGroupsInProgress;

  return (
    <ModalFooter active>
      {messageId && (
        <Box sx={{ flex: '1 1 auto' }}>
          <Button
            variant="outlined"
            startIcon={deleting ? <Spin /> : <DeleteIcon />}
            disabled={disabled}
            onClick={handleDelete}
          >
            <FormattedMessage id="action-Delete" />
          </Button>
        </Box>
      )}
      <Stack gap={1.25} direction="row">
        <Button
          type="submit"
          variant="outlined"
          startIcon={saving && !isPublishInProgress && !isSubmitInProgress && <Spin />}
          disabled={disabled}
          data-save
        >
          <FormattedMessage id={messageId ? 'action-SaveChanges' : 'action-SaveDraft'} />
        </Button>
        {canSubmit && (
          <Button
            type="submit"
            startIcon={(isCheckGroupsInProgress || isSubmitInProgress) && <Spin />}
            disabled={disabled}
            data-submit
          >
            <FormattedMessage id="action-Submit" />
          </Button>
        )}
        {canPublish && (
          <Button
            type="submit"
            startIcon={(isCheckGroupsInProgress || isPublishInProgress) && <Spin />}
            disabled={disabled}
            data-publish
          >
            <FormattedMessage id="action-Publish" />
          </Button>
        )}
      </Stack>
    </ModalFooter>
  );
};

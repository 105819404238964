import { IconButton, Stack, Typography } from '@mui/material';
import {
  DEFAULT_DATE_FORMAT_FNS,
  SchoolUserType,
  StudentSearchResult,
  useGetStudentsQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { getCombinedRowsFromSearchResults } from '@schooly/components/filters';
import { SchoolUserRole } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import { useAgeGroups } from '@schooly/hooks/use-school-properties';
import { DeleteIcon, PlusIcon, SimpleButton } from '@schooly/style';
import { format } from 'date-fns';
import { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { MessageForm } from '../../../../context/messages/MessageContext';
import { convertSchoolUserRoleToTextType } from '../../../../helpers/misc';
import {
  RecipientsByIndividualFormRef,
  RecipientsByIndividualSelectMultiple,
} from '../MessagesCreateRecipientsSelect/RecipientsByIndividualSelect';
import { RecipientsFormContainer } from './ModalCreateModalRecipients.styled';

export interface ParentsByStudentsIndividualFormProps {
  error: boolean;
  title: string;
  schoolId: string;
  isInitOpen?: boolean;
  onClose?: (close: () => void) => void;
  validate?: (v: string[]) => string | undefined;
  buttonTextId: string;
}

const type = 'parent' as SchoolUserType;

export const ParentsByStudentsIndividualForm = forwardRef<
  RecipientsByIndividualFormRef,
  ParentsByStudentsIndividualFormProps
>(({ title, error, schoolId, isInitOpen, onClose, validate, buttonTextId }, ref) => {
  const { formatMessage } = useIntl();
  const [isOpen, open, close] = useFlag(isInitOpen);
  const { control } = useFormContext<MessageForm>();

  const { permissions } = useAuth();
  const [queryEnabled, setQueryEnabled] = useState(false);
  const canRequestData = permissions.includes(`${type}_viewer`);

  const { getAgeGroupById } = useAgeGroups({ schoolId, userType: SchoolUserRole.Student });

  const { data, isLoading, hasNextPage, params, setParams, isFetchingNextPage, fetchNextPage } =
    useGetStudentsQuery(
      {
        schoolId,
        query: '',
        date: format(new Date(), DEFAULT_DATE_FORMAT_FNS),
        filters: {},
      },
      { enabled: queryEnabled && canRequestData },
    );

  const entries = useMemo(
    () =>
      getCombinedRowsFromSearchResults(
        data?.pages.reduce(
          (prev, curr) => [...prev, ...curr.results],
          [] as StudentSearchResult[],
        ) ?? [],
      ),
    [data?.pages],
  );

  const handleChangeQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const handleClose = onClose ? () => onClose(close) : close;

  useImperativeHandle(ref, () => ({
    openRecipientsForm: open,
  }));

  return (
    <>
      {isOpen ? (
        <RecipientsFormContainer error={error} gap={1.5}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h4">{title}</Typography>
            <IconButton inverse onClick={handleClose}>
              <DeleteIcon />
            </IconButton>
          </Stack>
          <Controller
            name="parents_of_students_ids"
            control={control}
            rules={{ validate }}
            render={({ field }) => (
              <RecipientsByIndividualSelectMultiple
                data={entries}
                query={params.query || ''}
                hasNextPage={!!hasNextPage}
                fetchNextPage={fetchNextPage}
                isFetchingNextPage={isFetchingNextPage || isLoading}
                type={convertSchoolUserRoleToTextType(SchoolUserRole.Student)}
                schoolId={schoolId}
                onSelectUserId={(v) => {
                  field.value.includes(v)
                    ? field.onChange(field.value.filter((e) => e !== v))
                    : field.onChange([...field.value, v]);
                  setParams((p) => ({ ...p, query: '' }));
                }}
                selectedIds={field.value}
                emptyPlaceholder={formatMessage({ id: 'messages-TypeName' })}
                name={field.name}
                onToggleDropdown={() => setQueryEnabled(true)}
                onChangeQuery={handleChangeQuery}
                renderRow={(user) => (
                  <Typography sx={{ flex: '1 1 50%' }}>
                    {getAgeGroupById(user.item.registration.age_group || '')?.name}
                  </Typography>
                )}
              ></RecipientsByIndividualSelectMultiple>
            )}
          ></Controller>
        </RecipientsFormContainer>
      ) : (
        <SimpleButton startIcon={<PlusIcon />} onClick={open} sx={{ alignSelf: 'flex-start' }}>
          {formatMessage({ id: buttonTextId })}
        </SimpleButton>
      )}
    </>
  );
});

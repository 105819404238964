import { Typography } from '@mui/material';
import { SchoolUserType, StaffSearchResult, StudentSearchResult } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  CombinedRow,
  DropdownSelect,
  DropdownSelectProps,
  renderUserTags,
  SelectContentSkeleton,
  SelectSearchInput,
} from '@schooly/components/filters';
import { FC, PropsWithChildren, useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { RecipientsSelectRow } from './RecipientsSelectRow';

type UserType = CombinedRow<StudentSearchResult | StaffSearchResult>;

export type RecipientsByIndividualFormRef = {
  openRecipientsForm: () => void;
};

type RecipientsByIndividualSelectMultipleProps = PropsWithChildren<{
  type: SchoolUserType;
  schoolId: string;
  selectedIds: string[];
  onSelectUserId: (v: string) => void;
  onToggleDropdown: () => void;
  onChangeQuery: (query: string) => void;
  data: UserType[] | undefined;
  query: string;
  hasNextPage: boolean;
  fetchNextPage: () => Promise<any>;
  isFetchingNextPage: boolean;
  renderRow?: (user: UserType) => React.ReactNode;
}> &
  Omit<DropdownSelectProps, 'children' | 'renderContent'>;

export const RecipientsByIndividualSelectMultiple: FC<
  PropsWithChildren<RecipientsByIndividualSelectMultipleProps>
> = ({
  type,
  schoolId,
  selectedIds,
  placeholder,
  onSelectUserId,
  onToggleDropdown,
  onChangeQuery,
  data,
  query,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  renderRow,
  ...dropdownProps
}) => {
  const dropdown = useRef<DropdownSelect | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { permissions } = useAuth();
  const canRequestData = permissions.includes(`${type}_viewer`);

  const renderContent = useCallback(() => {
    if (!canRequestData) {
      return (
        <>
          <Typography p={1}>
            <FormattedMessage id="error-NoPermissionsToRequest" />
          </Typography>
        </>
      );
    }

    if (!data?.length && isFetchingNextPage) return <SelectContentSkeleton />;

    if (!data?.length && !isFetchingNextPage)
      return (
        <Typography p={1}>
          <FormattedMessage id="input-NoOptionsFound" />
        </Typography>
      );

    return (
      <>
        {data?.map((user) => {
          if (!user) return null;

          return (
            <RecipientsSelectRow
              onClick={() => {
                onSelectUserId(user.id);
              }}
              key={user.id}
              user={user.item.registration.school_user_relation}
              isSelected={selectedIds.includes(user.id)}
            >
              {renderRow?.(user)}
            </RecipientsSelectRow>
          );
        })}
      </>
    );
  }, [canRequestData, data, onSelectUserId, selectedIds, renderRow, isFetchingNextPage]);

  return (
    <DropdownSelect
      {...dropdownProps}
      ref={dropdown}
      onToggle={onToggleDropdown}
      placeholder={placeholder}
      hasValues={selectedIds.length > 0}
      renderContent={renderContent}
      onClickInputArea={() => inputRef.current?.focus()}
      isFetchingNextPage={isFetchingNextPage}
      hasNextPage={canRequestData && hasNextPage}
      onFetchNextPage={fetchNextPage}
    >
      {(opened) => (
        <>
          {renderUserTags({
            ids: selectedIds,
            tagProps: {
              schoolId,
              userType: type,
              size: placeholder ? 'small' : undefined,
              sx: { maxWidth: 200 },
            },
            onDelete: opened ? onSelectUserId : undefined,
          })}
          {opened && (
            <SelectSearchInput
              ref={inputRef}
              autoFocus
              value={query}
              onChangeText={onChangeQuery}
            />
          )}
        </>
      )}
    </DropdownSelect>
  );
};

import { InputAdornment, Stack, Typography } from '@mui/material';
import { DATE_FORMAT_RANGE_FNS } from '@schooly/api';
import { FormNumberSelect, FormNumberSelectProps } from '@schooly/components/form-text-field';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { addDays, format } from 'date-fns';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

const DUE_DAYS_COUNT_MIN = 0;
const DUE_DAYS_COUNT_MAX = Number.MAX_SAFE_INTEGER;

export const InvoiceDueDayForm: FC<Partial<FormNumberSelectProps & { generationDate?: string }>> =
  ({ generationDate, ...props }) => {
    const { formatMessage } = useIntl();
    const { watch } = useFormContext();
    const dueDaysCount = watch('due_days_count');
    const isDueDaysCountSelected = Number.isInteger(dueDaysCount);
    return (
      <Stack flex={1}>
        <FormNumberSelect
          name="due_days_count"
          min={DUE_DAYS_COUNT_MIN}
          label={formatMessage({ id: 'frequencies-DueOn' })}
          max={DUE_DAYS_COUNT_MAX}
          rules={{
            required: true,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {formatMessage({ id: 'frequencies-DueDateDescriptionTitle' })}
              </InputAdornment>
            ),
          }}
          {...props}
        />
        <Typography
          variant="caption"
          sx={{
            visibility: isDueDaysCountSelected ? 'visible' : 'hidden',
            pl: 1.5,
            pt: 0.25,
            color: 'common.grey',
          }}
        >
          {formatMessage({ id: 'frequencies-DueDate' })}:{' '}
          {isDueDaysCountSelected &&
            format(
              addDays(newDateTimezoneOffset(generationDate), dueDaysCount),
              DATE_FORMAT_RANGE_FNS,
            )}
        </Typography>
      </Stack>
    );
  };

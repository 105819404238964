import { Stack, styled } from '@mui/material';

interface RecipientsFormContainerProps {
  error: boolean;
}

export const RecipientsFormContainer = styled(Stack)<RecipientsFormContainerProps>(
  ({ error, theme }) => ({
    backgroundColor: error ? theme.palette.background.paper : theme.palette.common.lightBg,
    padding: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadiusSm,
    border: '1px solid transparent',
    borderColor: error ? theme.palette.error.main : undefined,
  }),
);

import { Box, Stack } from '@mui/material';
import { SingleInvoiceAssignedProduct } from '@schooly/api';
import { FormNumberSelect } from '@schooly/components/form-text-field';
import { useFlag } from '@schooly/hooks/use-flag';
import { PlusIcon, SimpleButton } from '@schooly/style';
import { FC } from 'react';
import { Controller, ControllerProps, FieldValues, useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { DiscountInput } from '../../../ProfileModalPayers/StudentProductsModal/DiscountInput';
import { SingleInvoiceForm } from '../SingleInvoicesContent';
import { ProductDropdown } from './ProductDropdown';

type ProductDropdownFormProps = {
  assignmentIdx: number;
  productIdx: number;
  schoolId: string;
  onClose: () => void;
};

export const ProductDropdownForm: FC<ProductDropdownFormProps> = ({
  assignmentIdx,
  productIdx,
  schoolId,
  onClose,
}) => {
  const { watch, control } = useFormContext<SingleInvoiceForm>();
  const name = `assignments.${assignmentIdx}.products.${productIdx}` as const;
  const assignments = watch(`assignments.${assignmentIdx}`);
  const product = watch(name);
  const { formatMessage } = useIntl();

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: true,
      }}
      render={({ field }) => {
        return (
          <Stack flex={1} justifyContent="center" maxWidth={280}>
            <ProductDropdown
              onSelect={field.onChange}
              schoolId={schoolId}
              selectedProduct={product}
              relationId={assignments.student.relation_id}
              onClose={onClose}
              placeholder={formatMessage({ id: 'products-Product' })}
              PopperProps={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [-10, -23],
                    },
                  },
                ],
              }}
            />
          </Stack>
        );
      }}
    />
  );
};

type AddProductFormProps = {
  name: string;
  onSelect: (product: SingleInvoiceAssignedProduct) => void;
  schoolId: string;
  relationId: string;
  legalEntityIds?: string[];
};

export const AddProductForm: FC<AddProductFormProps> = ({
  name,
  onSelect,
  schoolId,
  relationId,
  legalEntityIds,
}) => {
  const { formatMessage } = useIntl();
  const [dropdownOpened, showDropdown, hideDropdown] = useFlag();
  return (
    <Controller
      name={name}
      rules={{
        required: 'singleInvoices-NoProductError',
      }}
      render={({ fieldState: { error } }) => {
        return (
          <Box
            sx={(theme) => ({
              cursor: 'pointer',
              backgroundColor: error?.root?.message ? 'error.superLight' : undefined,
              borderTop: theme.mixins.borderValue(),
              borderBottom: theme.mixins.borderValue(),
            })}
            height={46}
            position="relative"
            onClick={dropdownOpened ? hideDropdown : showDropdown}
          >
            {dropdownOpened ? (
              <ProductDropdown
                opened
                onSelect={(p) => {
                  hideDropdown();
                  onSelect(p);
                }}
                schoolId={schoolId}
                relationId={relationId}
                placeholder={formatMessage({ id: 'products-Product' })}
                onClose={hideDropdown}
                legalEntityIds={legalEntityIds}
                PopperProps={{
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -1],
                      },
                    },
                  ],
                }}
              />
            ) : (
              <SimpleButton sx={{ my: 1.5 }} startIcon={<PlusIcon />}>
                {formatMessage({ id: 'singleInvoices-AddProduct' })}
              </SimpleButton>
            )}
          </Box>
        );
      }}
    />
  );
};

const PRODUCT_QUANTITY_MIN = 1;
const PRODUCT_QUANTITY_MAX = 999;

type QuantityFormProps = {
  formName: string;
};

export const QuantityForm: FC<QuantityFormProps> = ({ formName }) => {
  return (
    <Box
      sx={(theme) => ({
        '.MuiInputBase-input': {
          padding: `${theme.spacing(1, 0.75)} !important`,
          ...theme.typography.body1,
          color: theme.palette.common.grey2,
          width: 26,
          border: 'none',
        },
        '.MuiInputBase-root': {
          border: 'none !important',
          backgroundColor: theme.palette.background.paper,
        },
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
          display: 'none',
          border: 'none',
        },
        '& input[type=number]': {
          MozAppearance: 'textfield',
        },
      })}
    >
      <FormNumberSelect name={formName} min={PRODUCT_QUANTITY_MIN} max={PRODUCT_QUANTITY_MAX} />
    </Box>
  );
};

type DiscountFormProps = {
  name: string;
} & Omit<ControllerProps<FieldValues>, 'render' | 'defaultValue'>;

export const DiscountForm: FC<DiscountFormProps> = ({ name, ...props }) => {
  const { control } = useFormContext();

  return (
    <Controller
      {...props}
      control={control}
      name={name}
      defaultValue={0}
      render={({ field }) => {
        return (
          <Box
            height={36}
            width={38}
            sx={(theme) => ({
              '.inputWrapper': {
                margin: 0,
                border: theme.mixins.borderControlValue(),
                borderRadius: theme.spacing(1),
                overflow: 'hidden',
                paddingLeft: theme.spacing(0.5),
                width: 38,
                backgroundColor: theme.palette.background.paper,
                input: {
                  fieldSizing: 'content',
                  mr: 0,
                },
              },
            })}
          >
            <DiscountInput
              {...field}
              onChange={(v) => {
                if (typeof v === 'undefined') {
                  field.onChange(0);
                } else {
                  field.onChange(v);
                }
              }}
            />
          </Box>
        );
      }}
    />
  );
};

import { Box, IconButton } from '@mui/material';
import { CrossIcon, ModalHeader } from '@schooly/style';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FORSED_POPOVER_ZINDEX } from 'apps/web/src/config';
import { forwardRef } from 'react';
import { useIntl } from 'react-intl';

import { useMessage } from '../../../context/messages/useMessage';
import { MessageModalPreviewActions } from '../MessagesPreviewModal/MessageModalPreviewActions';

interface MessagesCreateHeaderProps {
  showStickyToolbar?: boolean;
}

export const MessagesCreateHeader = forwardRef<HTMLDivElement, MessagesCreateHeaderProps>(
  ({ showStickyToolbar }, ref) => {
    const { formatMessage } = useIntl();
    const { messageId, actions } = useMessage();

    return (
      <Box sx={{ position: 'relative' }}>
        <ModalHeader
          active
          title={formatMessage({
            id: messageId ? 'messages-EditMessage' : 'messages-CreateNewMessage',
          })}
        >
          {!!messageId && <MessageModalPreviewActions disabled />}

          <IconButton onClick={actions.onCloseCreateModal}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>

        <Box
          sx={{
            position: 'absolute',
            top: 70,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: FORSED_POPOVER_ZINDEX,
            visibility: showStickyToolbar ? 'visible' : 'hidden',
          }}
        >
          <Box
            ref={ref}
            sx={{
              p: 2.5,
              borderBottom: '1px solid',
              backgroundColor: 'white',
            }}
          ></Box>
        </Box>
      </Box>
    );
  },
);

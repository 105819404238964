import { Stack, Typography } from '@mui/material';
import { SchoolProperty } from '@schooly/api';
import { SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import { Spin, TagSelectProperty } from '@schooly/style';
import { FC, PropsWithChildren, useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ExpandedSelect } from '../ExpandedSelect';
import { SelectContentSkeleton } from '../SelectContentSkeleton';
import { SelectSearchInput } from '../SelectSearchInput';
import { filterPropertiesByQuery } from '../utils';
import { DottedDivider } from './DottedDivider.styled';
import { PropertyTypeTagSelect, PropertyTypeTagSelectProps } from './PropertyTypeTagSelect';

type PropertyTypeExpandedSelectProps = PropsWithChildren<{
  propertyType: SchoolPropertyType;
  userRole: SchoolUserRole;
  schoolId: string;
  selectedIds: string[];
  onSelectId: (v: string) => void;
  onClear: () => void;
  onClose: () => void;
  noneOption?: {
    id: SchoolProperty['id'];
    name?: SchoolProperty['name'];
    type?: SchoolProperty['type'];
  };
  isNoneOptionSelected?: boolean;
  onNoneOptionSelect?: () => void;
  showArchived?: boolean;
}>;
export const PropertyTypeExpandedSelect: FC<PropertyTypeExpandedSelectProps> = ({
  userRole,
  propertyType,
  schoolId,
  selectedIds,
  onSelectId,
  onClose,
  onClear,
  noneOption,
  isNoneOptionSelected,
  onNoneOptionSelect,
  showArchived = true,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { activePropertiesMap, archivedPropertiesMap, isLoading } = useSchoolProperties({
    schoolId,
    userType: userRole,
    showReEnrollmentProperties: true,
  });

  const [query, setQuery] = useState('');

  const renderContent = useCallback(() => {
    if (isLoading) return <SelectContentSkeleton />;

    const activeProperties = filterPropertiesByQuery(query, activePropertiesMap[propertyType]);
    const archivedProperties = filterPropertiesByQuery(query, archivedPropertiesMap[propertyType]);

    const showNoneOption =
      noneOption &&
      onNoneOptionSelect &&
      noneOption.name?.toLowerCase().includes(query.toLowerCase());

    if (!activeProperties.length && !archivedProperties.length && !showNoneOption)
      return (
        <Typography p={1}>
          <FormattedMessage id="input-NoOptionsFound" />
        </Typography>
      );

    return (
      <>
        <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
          {activeProperties.map((property) => (
            <TagSelectProperty
              key={property.id}
              userRole={userRole}
              property={property}
              variant={selectedIds?.includes(property.id) ? 'filled' : undefined}
              onClick={() => {
                onSelectId(property.id);
                setQuery('');
              }}
            />
          ))}
        </Stack>
        {showArchived && Boolean(archivedProperties.length || showNoneOption) && (
          <>
            <DottedDivider m={1} />
            <Stack direction="row" flexWrap="wrap" gap={1} m={1}>
              {showNoneOption && (
                <TagSelectProperty
                  userRole={userRole}
                  property={noneOption}
                  variant={isNoneOptionSelected ? 'filled' : undefined}
                  onClick={() => {
                    onNoneOptionSelect();
                    setQuery('');
                  }}
                />
              )}
              {archivedProperties.map((property) => (
                <TagSelectProperty
                  key={property.id}
                  userRole={userRole}
                  property={property}
                  variant={selectedIds?.includes(property.id) ? 'filled' : undefined}
                  onClick={() => {
                    onSelectId(property.id);
                    setQuery('');
                  }}
                />
              ))}
            </Stack>
          </>
        )}
      </>
    );
  }, [
    isLoading,
    activePropertiesMap,
    propertyType,
    archivedPropertiesMap,
    noneOption,
    onNoneOptionSelect,
    userRole,
    isNoneOptionSelected,
    query,
    selectedIds,
    onSelectId,
    showArchived,
  ]);

  return (
    <ExpandedSelect
      hasSelectedValue={selectedIds.length > 0 || Boolean(noneOption && isNoneOptionSelected)}
      onClose={onClose}
      onClear={onClear}
      onClickInputArea={() => inputRef.current?.focus()}
      renderContent={renderContent}
    >
      {noneOption && isNoneOptionSelected ? (
        <TagSelectProperty
          userRole={userRole}
          property={noneOption}
          defaultValue={<Spin />}
          sx={{ maxWidth: 200 }}
          id={noneOption.id}
          onDelete={onNoneOptionSelect}
          size="small"
        />
      ) : (
        renderPropertyTypeTags({
          ids: selectedIds,
          onDelete: onSelectId,
          tagProps: {
            schoolId,
            userRole,
            size: 'small',
          },
        })
      )}
      <SelectSearchInput ref={inputRef} autoFocus value={query} onChangeText={setQuery} />
    </ExpandedSelect>
  );
};

export type RenderPropertyTypeTagsParams = {
  ids: string[];
  onDelete?: (v: string) => void;
  onClick?: (v: string) => void;
  tagProps:
    | Omit<PropertyTypeTagSelectProps, 'id'>
    | ((v: string) => Omit<PropertyTypeTagSelectProps, 'id'>);
};

export const renderPropertyTypeTags = ({
  ids,
  onDelete,
  onClick,
  tagProps,
}: RenderPropertyTypeTagsParams) =>
  ids.map((v) => (
    <PropertyTypeTagSelect
      key={v}
      id={v}
      onClick={onClick ? () => onClick(v) : undefined}
      onDelete={onDelete ? () => onDelete(v) : undefined}
      {...(typeof tagProps === 'function' ? tagProps(v) : tagProps)}
    />
  ));

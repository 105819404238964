import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { SHORT_FORMATTED_DATE_FORMAT_FNS } from '@schooly/api';
import { InformationIcon, TagSelect } from '@schooly/style';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import PersonCardBasic from '../../../../components/common/PersonCard/PersonCardBasic';
import {
  GridRowItem,
  GridRowName,
  GridRowStyled,
} from '../../../../components/uikit-components/Grid/Grid';
import { SelectedCriteriaList } from '../../../../components/uikit-components/SelectedCriteriaList/SelectedCriteriaList.styled';
import { useMessage } from '../../../../context/messages/useMessage';
import { getDisplayedCriteria } from '../../helpers';

export const MessagesModalPreviewSendToStaff: FC = () => {
  const { staff, staffCriteria, staffByIndividualStudent, criteriaDate } = useMessage();
  const { formatMessage } = useIntl();

  return (
    <>
      {Boolean(staffCriteria.length || staff.length) && (
        <SelectedCriteriaList mb={2.5}>
          <Typography variant="h4" sx={{ mb: 1 }}>
            <FormattedMessage id="messages-StaffByCriteria" />
          </Typography>

          <Stack gap={1}>
            {!!criteriaDate && (
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <TagSelect
                  label={
                    <Typography variant="h3" color={(theme) => theme.palette.common.grey2}>
                      {format(new Date(criteriaDate), SHORT_FORMATTED_DATE_FORMAT_FNS)}
                    </Typography>
                  }
                  sx={(theme) => ({
                    '&.MuiChip-root': {
                      backgroundColor: theme.palette.background.paper,
                    },
                  })}
                />

                <Tooltip
                  title={formatMessage({ id: 'messages-ParentsOfStudentsPreviewTooltip' })}
                  placement="top"
                >
                  <IconButton>
                    <InformationIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            )}

            {
              /* Staff criteria */
              getDisplayedCriteria({ selectedCriteria: staffCriteria })
            }

            {/* Individual staff */}
            {Boolean(staff.length) && (
              <Box>
                {staff.map((item) => (
                  <GridRowStyled key={item.relation_id}>
                    <GridRowItem noVerticalPadding>
                      <GridRowName>
                        <PersonCardBasic
                          user={item}
                          userType="staff"
                          isListItem
                          isUsernameClickable
                        />
                      </GridRowName>
                    </GridRowItem>
                  </GridRowStyled>
                ))}
              </Box>
            )}
          </Stack>
        </SelectedCriteriaList>
      )}

      {/* Staff by individual students */}
      {Boolean(staffByIndividualStudent.length) && (
        <Box>
          <Typography variant="h4" sx={{ mb: 1 }}>
            <FormattedMessage id="messages-StaffOfStudents" />
          </Typography>
          <Box>
            {staffByIndividualStudent.map((item) => (
              <GridRowStyled key={item.relation_id}>
                <GridRowItem noVerticalPadding>
                  <GridRowName>
                    <PersonCardBasic user={item} userType="staff" isListItem isUsernameClickable />
                  </GridRowName>
                </GridRowItem>
              </GridRowStyled>
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};

import { Button, IconButton, Stack } from '@mui/material';
import { DEFAULT_DATE_FORMAT_FNS, Product } from '@schooly/api';
import { DateSelect, FarDateWarning } from '@schooly/components/filters';
import { SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import { DeleteIcon, PlusIcon } from '@schooly/style';
import { format } from 'date-fns';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

import { RegistrationStatusWarning } from '../../../components/common/StudentRegistration/RegistrationStatusWarning';
import { useRegistrationMissingStatuses } from '../../../components/common/StudentRegistration/useRegistrationMissingStatuses';
import { getPreviousStatusIndex } from '../../../components/common/StudentRegistration/utils';
import FormSelect2 from '../../../components/ui/Input/FormSelect2';
import { ApplyStudentsForm } from '../../../context/applications/ApplicationsContext';
import { getPropertiesByType } from '../../../helpers/school';
import { useSchool } from '../../../hooks/useSchool';

interface ApplicationAddStudentStatusProps {
  index: number;
  errors: Record<string, any>[];
  products: Product[];
}

const getEmptyStatus = () => ({
  formId: uuidv4(),
  school_property_id: '',
  applies_from: '',
  applies_to: '',
});

export const ApplicationAddStudentStatus: FC<ApplicationAddStudentStatusProps> = ({
  index,
  errors,
  products,
}) => {
  const { control, watch } = useFormContext<ApplyStudentsForm>();
  const {
    fields: statusFields,
    append,
    remove,
    insert,
  } = useFieldArray({
    control,
    name: `children.${index}.statuses`,
    rules: {
      validate: (arr) => {
        return !arr.some((s, statusIndex) => !!errors?.length && errors?.[statusIndex]);
      },
    },
  });

  const statuses = watch(`children.${index}.statuses`);
  const selectedStatuses = statuses?.filter((status) => status.school_property_id);

  const { $t } = useIntl();

  const { schoolId = '' } = useSchool();

  const { activePropertiesMap } = useSchoolProperties({
    schoolId: schoolId!,
    userType: SchoolUserRole.Student,
  });

  const {
    hasLastUnsuccessfulStatus,
    obligatoryProductStatuses,
    originalStatuses,
    removeStatusWarning,
    findMissingStatuses,
  } = useRegistrationMissingStatuses({
    schoolId,
    selectedStatuses,
    products,
  });

  const options = useMemo(
    () =>
      getPropertiesByType(activePropertiesMap.status, SchoolPropertyType.Status).map((p) => ({
        value: p.id,
        label: p.name,
      })),
    [activePropertiesMap.status],
  );

  useEffect(() => {
    if (!statusFields.length) {
      append(getEmptyStatus());
    }
  }, [append, statusFields.length]);

  useEffect(() => {
    const { unsubscribe } = watch((value, type) => {
      if (!type.name) {
        return;
      }

      findMissingStatuses({
        ageGroupPropertyId: value.children?.[index]?.age_group_property_id || '',
        halfDay: !!value.children?.[index]?.half_day,
        schoolYearId: value.children?.[index]?.school_year_id || '',
      });
    });

    return () => unsubscribe();
  }, [index, watch, findMissingStatuses]);

  const insertField = useCallback(
    (index: number, statusId: string) => {
      return () => {
        insert(index, {
          ...getEmptyStatus(),
          school_property_id: statusId,
        });
      };
    },
    [insert],
  );

  return (
    <Stack key={index} alignItems="flex-start" gap={2} sx={{ height: '100%' }}>
      {statuses?.map((statusField, statusIndex) => {
        const required = Boolean(
          statusIndex === 0 || statusField.school_property_id || statusField.applies_from,
        );

        const error = !!errors?.length && errors?.[statusIndex]?.['applies_from'];

        const currentStatusIndex = originalStatuses.findIndex(
          (status) => status.id === statusField.school_property_id,
        );
        const nextStatusIndex = originalStatuses.findIndex(
          (status) => status.id === statuses[statusIndex + 1]?.school_property_id,
        );

        return (
          <>
            {!!selectedStatuses.length &&
              !hasLastUnsuccessfulStatus &&
              obligatoryProductStatuses.map((status) => {
                const statusExists = statuses.find((s) => s.school_property_id === status.statusId);

                if (
                  !statusExists &&
                  !status.shown &&
                  status.statusIndex < currentStatusIndex &&
                  statusIndex === 0
                ) {
                  return (
                    <RegistrationStatusWarning
                      key={status.statusId}
                      status={status}
                      onClick={insertField(0, status.statusId)}
                      onRemove={() => removeStatusWarning(status.statusId)}
                    />
                  );
                }

                return null;
              })}

            <Stack
              key={statusField.formId}
              direction="row"
              alignItems="flex-start"
              gap={1}
              sx={{ width: '100%' }}
            >
              <FormSelect2
                name={`children.${index}.statuses.${statusIndex}.school_property_id`}
                labelTextId="schoolProperty-Status"
                options={options ?? []}
                rules={{ required }}
              />
              <Stack width="100%">
                <Controller
                  control={control}
                  name={`children.${index}.statuses.${statusIndex}.applies_from`}
                  rules={{ required }}
                  render={({ field, fieldState }) => {
                    return (
                      <DateSelect
                        ref={field.ref}
                        onSetDate={(date) => {
                          field.onChange(format(date, DEFAULT_DATE_FORMAT_FNS));
                        }}
                        date={field.value}
                        placeholder={$t({ id: 'schoolProperty-Status-AppliesForm' })}
                        requiredLabel={required ? 'required' : 'optional'}
                        error={fieldState.error || error}
                        onClear={() => field.onChange('')}
                        renderRightIcon={() => <FarDateWarning date={field.value} />}
                      />
                    );
                  }}
                />
              </Stack>

              {statusFields.length > 1 && (
                <IconButton
                  sx={{ paddingTop: (theme) => theme.spacing(1.5) }}
                  inverse
                  onClick={() => {
                    remove(statusIndex);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Stack>

            {!!selectedStatuses.length &&
              !hasLastUnsuccessfulStatus &&
              obligatoryProductStatuses.map((status) => {
                const statusExists = statuses.find((s) => s.school_property_id === status.statusId);

                if (
                  !statusExists &&
                  !status.shown &&
                  status.statusIndex > currentStatusIndex &&
                  status.statusIndex < nextStatusIndex
                ) {
                  const prevStatusIndex = getPreviousStatusIndex({
                    schoolStatuses: originalStatuses,
                    currentStatuses: statuses,
                    index: status.statusIndex,
                  });

                  return (
                    <RegistrationStatusWarning
                      key={status.statusId}
                      status={status}
                      onClick={insertField(prevStatusIndex + 1, status.statusId)}
                      onRemove={() => removeStatusWarning(status.statusId)}
                    />
                  );
                }

                return null;
              })}
          </>
        );
      })}

      <Button
        variant="outlined"
        startIcon={<PlusIcon />}
        onClick={() => {
          append(getEmptyStatus(), {
            shouldFocus: false,
          });
        }}
      >
        <FormattedMessage id="schoolProperty-AddStatus" />
      </Button>
    </Stack>
  );
};

import { DEFAULT_DATE_FORMAT_FNS } from '@schooly/api';
import { DateSelect, DateSelectProps } from '@schooly/components/filters';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format, isWithinInterval } from 'date-fns';
import { FC, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import useSchoolYears from '../../../hooks/useSchoolYears';

export const InvoiceDateForm: FC<Partial<DateSelectProps>> = (props) => {
  const { formatMessage } = useIntl();
  const { control } = useFormContext();
  const { schoolYears } = useSchoolYears();

  const isDateNotWithinSchoolYear = useCallback(
    (date: Date) => {
      return !schoolYears.some((y) =>
        isWithinInterval(date, {
          start: newDateTimezoneOffset(y.start),
          end: newDateTimezoneOffset(y.end),
        }),
      );
    },
    [schoolYears],
  );

  return (
    <Controller
      control={control}
      name="invoice_date"
      rules={{ required: true }}
      render={({ field, fieldState }) => (
        <DateSelect
          ref={field.ref}
          onSetDate={(date) => {
            if (isDateNotWithinSchoolYear(date)) return;
            field.onChange(format(date, DEFAULT_DATE_FORMAT_FNS));
          }}
          CalendarProps={{
            shouldDisableDate: isDateNotWithinSchoolYear,
          }}
          date={field.value}
          placeholder={formatMessage({ id: 'frequencies-InvoiceDate' })}
          requiredLabel="required"
          error={fieldState.error}
          onClear={() => field.onChange('')}
          {...props}
        />
      )}
    />
  );
};

import { HeaderActions } from '@schooly/components/header-actions';
import { DATE_FORMAT } from '@schooly/constants';
import { EditIcon, PublishIcon, SubmitIcon, UnpublishIcon } from '@schooly/style';
import { isNotFalse } from '@schooly/utils/predicates';
import { format } from 'date-fns';
import React, { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { useMessage } from '../../../context/messages/useMessage';
import { MESSAGE_STATUSES } from './StatusLabel';

interface MessageModalPreviewActionsProps {
  disabled?: boolean;
}

export const MessageModalPreviewActions: FC<MessageModalPreviewActionsProps> = ({ disabled }) => {
  const {
    canPublish,
    canSubmit,
    canUnsubmit,
    isPublishInProgress,
    isSubmitInProgress,
    isUnsubmitInProgress,
    isPublished,
    isSubmitted,
    actions,
    status,
    publishDate,
    messageId,
    messageData,
    showDeprecatedRecipientsWarning,
    form,
    messageFormFields,
  } = useMessage();

  const navigate = useNavigate();

  const { $t } = useIntl();
  const isLoading = isPublishInProgress || isSubmitInProgress || isUnsubmitInProgress;

  const canSubmitOrPublish =
    !!messageData?.message_criteria.length || !!messageData?.message_individuals.length;

  const title = useMemo(() => {
    if (typeof status === 'undefined') {
      return '';
    }

    if (isPublished && publishDate) {
      return `${$t({ id: 'status-Published' })}${' '} ${format(publishDate, DATE_FORMAT)}`;
    }

    return $t({ id: `options-message-status-${MESSAGE_STATUSES[status]}` });
  }, [$t, isPublished, publishDate, status]);

  const selectActions = useMemo(() => {
    return [
      showDeprecatedRecipientsWarning && {
        titleTextId: 'messages-EditToProceed',
        handler: async () => {
          form.reset(messageFormFields);
          navigate(`/messages/${messageId}/edit`);
          return true;
        },
        icon: <EditIcon />,
        pendingTitle: $t({ id: 'submitting' }),
      },
      !canSubmitOrPublish && {
        titleTextId: 'messages-CompleteRequiredFields',
        handler: async () => {
          navigate(`/messages/${messageId}/edit`);
          return true;
        },
        icon: <EditIcon />,
        pendingTitle: $t({ id: 'submitting' }),
      },
      canSubmit && {
        titleTextId: 'messages-Submit',
        handler: async () => {
          await actions.submitMessage();
          return true;
        },
        icon: <SubmitIcon />,
        pendingTitle: $t({ id: 'submitting' }),
        disabled: !canSubmitOrPublish || showDeprecatedRecipientsWarning,
      },
      canUnsubmit && {
        titleTextId: 'messages-Unsubmit',
        handler: async () => {
          await actions.unsubmitMessage();
          return true;
        },
        icon: <UnpublishIcon />,
        pendingTitle: $t({ id: 'unSubmitting' }),
      },
      canPublish && {
        titleTextId: 'messages-Publish',
        handler: async () => {
          await actions.publishMessage();
          return true;
        },
        icon: <PublishIcon className="reset-svg-currentColor" />,
        pendingTitle: $t({ id: 'publishing' }),
        disabled: !canSubmitOrPublish || showDeprecatedRecipientsWarning,
      },
    ].filter(isNotFalse);
  }, [
    $t,
    actions,
    canPublish,
    canSubmit,
    canUnsubmit,
    messageId,
    navigate,
    canSubmitOrPublish,
    showDeprecatedRecipientsWarning,
    form,
    messageFormFields,
  ]);

  const styleType = useMemo(() => {
    if (isPublished) return 'success';
    if (isSubmitted) return 'warning';
    return undefined;
  }, [isPublished, isSubmitted]);

  return (
    <HeaderActions
      title={title}
      actions={selectActions}
      styleType={styleType}
      pending={isLoading}
      disabled={disabled || isPublished || !selectActions.length}
      sx={{
        '& .MuiList-root': {
          width: 215,
        },
      }}
    />
  );
};

import { Box, Link as MuiLink, Stack, Tooltip as MuiTooltip, Typography } from '@mui/material';
import { UserType } from '@schooly/api';
import { BooleanOrNull } from '@schooly/api';
import { WithTelephone } from '@schooly/api';
import { SchoolRelation } from '@schooly/api';
import { Guardian, User } from '@schooly/api';
import { AvatarAuth } from '@schooly/components/avatar-auth';
import { DELAY_BEFORE_HIDE_ICON_DONE } from '@schooly/constants';
import { CopyIcon, DoneIcon, StarIcon } from '@schooly/style';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { Tag } from '../../components/uikit/Tag/Tag';
import { LongNameWithTooltip } from '../../components/uikit-components/LongNameWithTooltip/LongNameWithTooltip';
import { LongNameWithVerticalTooltip } from '../../components/uikit-components/LongNameWithVerticalTooltip/LongNameWithVerticalTooltip';
import { SchoolInviteStatus } from '../../constants/people';
import { SchoolUserRole } from '../../constants/school';
import { getRouteModalPathname } from '../../helpers/misc';
import renderFullUserType from '../../helpers/renderFullUserType';
import { getUserFullName } from '../../helpers/users';
import useAppLocation from '../../hooks/useAppLocation';
import buildClassName from '../../utils/buildClassName';
import { ManageGroupModalTooltip } from '../Groups/ManageGroupModal/ManageGroupModalTooltip';

import './ProfileCard.scss';

// TODO: remake with MUI
interface ProfileCardProps {
  user: User | SchoolRelation;
  userType: UserType;
  primary?: BooleanOrNull;
  relationType?: string;
  showInviteStatus?: boolean;
  inviteStatus?: Guardian['invite_status'];
  profileSchoolContext?: SchoolUserRole;
  onClick?: (e: React.SyntheticEvent) => void;
  onUserNameClick?: () => void;
}

const ProfileCard = ({
  user,
  userType,
  primary,
  relationType,
  inviteStatus,
  showInviteStatus,
  profileSchoolContext,
  onClick,
  onUserNameClick,
}: ProfileCardProps) => {
  const location = useAppLocation();
  const [showDoneIcon, setShowDoneIcon] = useState(false);
  const { $t } = useIntl();
  const isAdultUserWithEmail = useCallback(
    (user: User | SchoolRelation): user is User => {
      if (userType === 'staff' || userType === 'parent' || userType === 'adult') {
        return (user as User).email !== undefined;
      }
      return false;
    },
    [userType],
  );

  const onCopy = useCallback((email: string) => {
    navigator.clipboard.writeText(email);
    setShowDoneIcon(true);
    setTimeout(() => setShowDoneIcon(false), DELAY_BEFORE_HIDE_ICON_DONE);
  }, []);

  const cardClassName = buildClassName(
    'person d-flex align-items-center',
    userType === 'parent' && 'parent',
    primary ? 'primary' : undefined,
  );

  const inviteStatusClassName = useMemo(() => {
    if (!showInviteStatus) return '';

    return (
      {
        [SchoolInviteStatus.Invited]: 'invited',
        [SchoolInviteStatus.Active]: 'active',
      }[inviteStatus!] || 'not-invited'
    );
  }, [inviteStatus, showInviteStatus]);

  const cardLabel = ['student', 'child'].includes(userType)
    ? renderFullUserType(user as User, userType)
    : null;

  const clickableProps =
    onClick && !primary
      ? {
          onClick,
          role: 'button',
          tabIndex: 0,
        }
      : {};

  const isAdult = userType !== 'student' && userType !== 'child';

  const icon = useMemo(() => {
    if (showDoneIcon) {
      return <DoneIcon />;
    }
    return <CopyIcon />;
  }, [showDoneIcon]);

  return (
    <div
      className={cardClassName}
      // eslint-disable-next- react/jsx-props-no-spreading
      {...clickableProps}
    >
      <Stack
        direction="row"
        alignItems="center"
        gap={0.5}
        width={isAdult ? '22%' : 260}
        minWidth={isAdult ? 180 : 'auto'}
      >
        <Box width={30}>
          <AvatarAuth user={user} withAvatarPreview />
        </Box>
        <Box
          sx={(theme) => ({
            '& .UIKit-LongStringWithTooltip__content': {
              whiteSpace: 'normal',
              '& .UIKit-LongStringWithTooltip__name': {
                display: '-webkit-box',
                '-webkit-box-orient': 'vertical',
                '-webkit-line-clamp': '2',
                '& .person-username': {
                  lineHeight: theme.spacing(2.5),
                },
              },
            },
          })}
        >
          <LongNameWithTooltip paragraphClassName="username-container">
            <Link
              to={{
                pathname: getRouteModalPathname(userType, user),
                ...(profileSchoolContext !== undefined && {
                  search: `?type=${profileSchoolContext}`,
                }),
              }}
              state={
                location.state?.backgroundLocation
                  ? location.state
                  : { backgroundLocation: location }
              }
              className="h3 person-username mb-0 text-primary text-underline"
              onClick={onUserNameClick}
            >
              {getUserFullName(user)}
            </Link>
          </LongNameWithTooltip>
        </Box>
      </Stack>

      {isAdult && (
        <Stack width={128} marginX={2}>
          <Box
            sx={(theme) => ({
              '.UIKit-Tag': {
                backgroundColor: theme.palette.background.paper,
                borderColor: theme.palette.common.light2,
              },
            })}
          >
            {relationType && (
              <Tag>
                <Typography>{relationType}</Typography>
              </Tag>
            )}
          </Box>
        </Stack>
      )}

      <Stack
        direction="row"
        alignItems="center"
        sx={{
          flex: 2,
        }}
      >
        <Stack flex={2} flexDirection={'row'}>
          <div className="d-flex align-items-center flex-2">
            {primary && (
              <span className="label-icon">
                {/* TODO move tooltip to ui-kit */}
                <ManageGroupModalTooltip
                  width="unset"
                  sx={{
                    py: 0.25,
                    px: 0.5,
                    borderRadius: 0.5,
                    boxShadow:
                      '0px 2px 10px 2px rgba(36, 39, 91, 0.10), 0px 2px 4px 1px rgba(36, 39, 91, 0.10)',
                  }}
                  offset={[0, -10]}
                  text={
                    <Typography color="primary.main">
                      <FormattedMessage id="applications-PrimaryContact" />
                    </Typography>
                  }
                  arrow={false}
                >
                  <StarIcon fontSize="medium" style={{ marginRight: 0 }} />
                </ManageGroupModalTooltip>
              </span>
            )}
            <span className="label-content">
              {userType !== 'student' &&
              userType !== 'child' &&
              (user as WithTelephone).telephone ? (
                <span className="ml-1">{(user as WithTelephone).telephone}</span>
              ) : (
                cardLabel
              )}
            </span>
          </div>
          <Stack flex={3}>
            {isAdultUserWithEmail(user) && (
              <Stack flexDirection="row" alignItems="center">
                {user.email ? (
                  <>
                    <LongNameWithVerticalTooltip>
                      <MuiLink
                        color="text.primary"
                        underline="hover"
                        sx={{ ':hover': { color: 'text.primary' } }}
                        href={`mailto:${user.email}`}
                        target="_blank"
                      >
                        {user.email}
                      </MuiLink>
                    </LongNameWithVerticalTooltip>
                    <MuiTooltip title="Copy email address">
                      <Stack
                        sx={{
                          cursor: 'pointer',
                          justifyContent: 'center',
                          alignItems: 'center',
                          ' .svg-icon': {
                            width: 20,
                            height: 20,
                            visibility: showDoneIcon ? 'visible' : undefined,
                          },
                        }}
                        className="copy-button"
                        onClick={() => {
                          if (isAdultUserWithEmail(user) && user.email) {
                            onCopy(user.email);
                          }
                        }}
                      >
                        {isAdultUserWithEmail(user) && user.email && icon}
                      </Stack>
                    </MuiTooltip>
                  </>
                ) : (
                  <Typography color="text.primary">-</Typography>
                )}
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>

      {showInviteStatus && inviteStatus !== SchoolInviteStatus.Active ? (
        <MuiTooltip
          title={$t({
            id:
              inviteStatus === SchoolInviteStatus.Invited
                ? 'inviteStatus-Invited'
                : 'inviteStatus-NotInvited',
          })}
        >
          <span className={buildClassName('invite-indicator', `${inviteStatusClassName}`)} />
        </MuiTooltip>
      ) : (
        <div className="placeholder"></div>
      )}
    </div>
  );
};

export default ProfileCard;

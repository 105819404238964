import { Box, Icon, SxProps, Typography } from '@mui/material';
import { DATE_FORMAT } from '@schooly/constants';
import { CalendarIcon } from '@schooly/style';
import { getDateWithoutTime } from '@schooly/utils/date';
import { format } from 'date-fns';
import { FC, forwardRef, useImperativeHandle, useRef } from 'react';
import { useIntl } from 'react-intl';

import { DropdownSelect, DropdownSelectProps } from '../DropdownSelect';
import { TodayButton } from './DatePicker/DatePickerCommonComponents';
import {
  DateSelectContent,
  DateSelectContentProps,
  DateSelectContentRef,
} from './DatePicker/DateSelectContent';
import { DateSelectInput } from './DatePicker/DateSelectInput';

export type DateSelectProps = {
  name?: string;
  date?: string;
  onSetDate: (v: Date) => void;
  onSetToday?: () => void;
  emptyValue?: string;
  hideTodayButton?: boolean;
  getDayStyleProps?: (d: Date) => Omit<SxProps, 'children'>;
  testId?: string;
  presetDate?: DateSelectContentProps['presetDate'];
  CalendarProps?: DateSelectContentProps['DatePickerProps'];
} & Omit<DropdownSelectProps, 'children' | 'renderContent'>;

export const DateSelect = forwardRef<DropdownSelect, DateSelectProps>(
  (
    {
      name,
      date: dateString,
      onSetDate,
      onSetToday,
      placeholder,
      emptyValue,
      hideTodayButton,
      getDayStyleProps,
      testId,
      CalendarProps: PickerProps,
      presetDate,
      ...dropdownProps
    },
    ref,
  ) => {
    const { $t } = useIntl();
    const dropdown = useRef<DropdownSelect | null>(null);
    const dateContainer = useRef<DateSelectContentRef | null>(null);

    const date = dateString ? getDateWithoutTime(dateString) : null;

    useImperativeHandle(
      ref,
      () => ({
        close: () => dropdown.current?.close(),
        open: () => dropdown.current?.open(),
        focus: () => dropdown.current?.open(),
        blur: () => dropdown.current?.close(),
      }),
      [],
    );

    const onSelectDateAndClose = (d: Date) => {
      onSetDate(d);
      dropdown.current?.close();
    };

    const onSelectDate = (d: Date) => {
      onSetDate(d);
      dateContainer.current?.setVisibleMonthDate(d);
    };

    const renderContent = () => {
      return (
        <DateSelectContent
          ref={dateContainer}
          date={date}
          onSetDate={onSelectDateAndClose}
          getDayStyleProps={getDayStyleProps}
          DatePickerProps={PickerProps}
          presetDate={presetDate}
        >
          {!hideTodayButton && (
            <TodayButton
              onClick={() => {
                const now = new Date();
                dateContainer.current?.setVisibleMonthDate(now);
                onSetToday ? onSetToday() : onSelectDateAndClose(now);
                dropdown.current?.close();
              }}
            />
          )}
        </DateSelectContent>
      );
    };

    const value = date ? format(date, DATE_FORMAT) : emptyValue;

    const valueColor = value ? 'primary.main' : 'common.grey';

    return (
      <DropdownSelect
        ref={dropdown}
        hasValues={!!value}
        placeholder={placeholder || $t({ id: 'schoolProperty-Date' })}
        maxExpandedContainerWidth={250}
        startIcon={
          <>
            <HiddenInputWithName name={name} />
            <Icon sx={{ color: 'common.grey' }}>
              <CalendarIcon />
            </Icon>
          </>
        }
        expandedContainerStyleProps={{ maxHeight: 400 }}
        renderContent={renderContent}
        testId={testId}
        layoutStyleProps={{ flexWrap: 'nowrap' }}
        {...dropdownProps}
      >
        {(opened) => {
          if (opened) {
            return <DateSelectInput value={date} onSetDate={onSelectDate} />;
          }

          if (value) {
            return (
              <Typography
                noWrap
                variant="h3"
                color={dropdownProps.disabled ? 'common.grey2' : valueColor}
              >
                {value}
              </Typography>
            );
          }
          return null;
        }}
      </DropdownSelect>
    );
  },
);

const HiddenInputWithName: FC<{ name?: string }> = ({ name }) => {
  if (!name) return null;

  return (
    <Box
      sx={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: 1,
        overflow: 'hidden',
        pointerEvents: 'none',
        opacity: 0,
      }}
    >
      <input name={name} />
    </Box>
  );
};

import { Box, Icon, Stack } from '@mui/material';
import { TypographyWithOverflowHint } from '@schooly/style';
import { FC, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { MenuItem } from './ContextMenuItem.styled';

export interface ContextMenuItemProps {
  titleTextId: string;
  onMenuClick?: () => void;
  icon?: ReactNode;
  testId?: string;
  disabled?: boolean;
}

export const ContextMenuItem: FC<ContextMenuItemProps> = ({
  titleTextId,
  onMenuClick,
  icon,
  testId,
  disabled,
}) => {
  return (
    <MenuItem onClick={onMenuClick} data-test-id={testId} disabled={disabled}>
      <Stack direction="row" alignItems="center" width="100%">
        {icon && <Icon>{icon}</Icon>}
        <Box pl={1} width="100%">
          <TypographyWithOverflowHint variant="h3">
            <FormattedMessage id={titleTextId} />
          </TypographyWithOverflowHint>
        </Box>
      </Stack>
    </MenuItem>
  );
};

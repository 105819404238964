import { Icon, Stack, Typography } from '@mui/material';
import { WithAvatar, WithName } from '@schooly/api';
import { AvatarAuth } from '@schooly/components/avatar-auth';
import { DoneIcon } from '@schooly/style';
import { getUserFullName } from '@schooly/utils/get-user-full-name';
import { FC, PropsWithChildren } from 'react';

type RecipientsSelectRowProps = {
  overrideName?: string;
  isSelected: boolean;
  onClick: () => void;
  user: WithName & WithAvatar;
};

export const RecipientsSelectRow: FC<PropsWithChildren<RecipientsSelectRowProps>> = ({
  isSelected,
  user,
  overrideName,
  onClick,
  children,
}) => {
  const name = getUserFullName(user);

  return (
    <Stack
      onClick={onClick}
      flexDirection="row"
      alignItems="center"
      px={1}
      gap={2}
      py={0.5}
      sx={(theme) => ({
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: theme.palette.background.default,
        },
      })}
    >
      <Stack flexDirection="row" alignItems="center" gap={1} flex="1 1 50%">
        <AvatarAuth user={user} />
        <Typography color={!isSelected ? 'common.grey2' : undefined} variant="h3">
          {overrideName || name}
        </Typography>
      </Stack>

      {children}

      <Icon
        sx={{
          visibility: isSelected ? 'visible' : 'hidden',
        }}
      >
        <DoneIcon />
      </Icon>
    </Stack>
  );
};

import { FC, HTMLAttributes } from 'react';

export const ReactComponent: FC<HTMLAttributes<SVGElement>> = (props) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.24995 6.00016C7.24995 5.58594 7.58573 5.25015 7.99994 5.25015L17.9999 5.25002C18.4142 5.25002 18.7499 5.5858 18.75 6.00001C18.75 6.41423 18.4142 6.75002 18 6.75002L7.99996 6.75015C7.58575 6.75015 7.24996 6.41437 7.24995 6.00016ZM7.25 10.0002C7.25 9.58594 7.58578 9.25015 7.99999 9.25015L18 9.25002C18.4142 9.25002 18.75 9.5858 18.75 10C18.75 10.4142 18.4142 10.75 18 10.75L8.00001 10.7501C7.5858 10.7502 7.25001 10.4144 7.25 10.0002ZM7.25005 14.0002C7.25004 13.5859 7.58583 13.2502 8.00004 13.2501L18 13.25C18.4143 13.25 18.75 13.5858 18.75 14C18.7501 14.4142 18.4143 14.75 18.0001 14.75L8.00006 14.7501C7.58585 14.7502 7.25006 14.4144 7.25005 14.0002Z"
      fill="#24275B"
    />
    <path
      d="M5.25305 16.0002H1.8385V15.3201L3.44836 13.6042C3.61015 13.4242 3.73775 13.2681 3.83118 13.136C3.92688 13.0015 3.9941 12.8808 4.03284 12.7737C4.07385 12.6643 4.09436 12.5606 4.09436 12.4626C4.09436 12.2439 4.03853 12.0707 3.92688 11.9431C3.8175 11.8155 3.66028 11.7517 3.4552 11.7517C3.30025 11.7517 3.16923 11.7882 3.06213 11.8611C2.95504 11.934 2.87415 12.0331 2.81946 12.1584C2.76477 12.2838 2.73743 12.4273 2.73743 12.5891H1.73938C1.73938 12.2906 1.80888 12.0172 1.94788 11.7688C2.08915 11.5204 2.28967 11.3222 2.54944 11.1741C2.8092 11.026 3.11682 10.9519 3.47229 10.9519C3.99638 10.9519 4.39742 11.0784 4.67542 11.3313C4.95341 11.5819 5.09241 11.926 5.09241 12.3635C5.09241 12.5868 5.04683 12.801 4.95569 13.0061C4.86454 13.2089 4.73694 13.414 4.57288 13.6213C4.40881 13.8287 4.21513 14.0474 3.99182 14.2776L3.11682 15.2004H5.25305V16.0002Z"
      fill="#24275B"
    />
    <path
      d="M4.24475 9.00024H3.2467V5.19604L2.07092 5.56177V4.75513L4.13538 4.01343H4.24475V9.00024Z"
      fill="#24275B"
    />
  </svg>
);

import { Box, ClickAwayListener, Icon, Stack, SxProps, Tooltip, Typography } from '@mui/material';
import { ArrowDownV2Icon, CheckIcon, Spin, theme } from '@schooly/style';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { Menu } from 'apps/web/src/components/common/ContextMenu/ContextMenu.styled';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ContextMenuItem } from 'apps/web/src/components/common/ContextMenu/ContextMenuItem';
import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react';

const DELAY_BEFORE_CHANGE_REQUEST_STATUS = 2000;

type RequestStatus = 'default' | 'success';

export interface HeaderAction {
  titleTextId: string;
  handler?: () => Promise<boolean>;
  icon?: ReactNode;
  pendingTitle?: string;
  disabled?: boolean;
}
export interface HeaderActionsProps {
  title: string;
  styleType?: 'error' | 'warning' | 'success';
  pending: boolean;
  actions: Array<HeaderAction>;
  disabled?: boolean;
  tooltipTitle?: ReactNode;
  sx?: SxProps;
}
export const HeaderActions: FC<HeaderActionsProps> = ({
  title,
  styleType,
  pending,
  actions,
  disabled,
  tooltipTitle,
  sx,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const [pendingTitle, setPendingTitle] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>('default');

  const currentStyles = useMemo(() => {
    switch (styleType) {
      case 'error':
        return {
          borderColor: theme.palette.error.light,
          backgroundColor: theme.palette.error.superLight,
          color: theme.palette.error.main,
        };
      case 'success':
        return {
          borderColor: theme.palette.common.green4,
          backgroundColor: theme.palette.success.superLight,
          color: theme.palette.success.main,
        };
      case 'warning':
        return {
          borderColor: theme.palette.common.orange4,
          backgroundColor: theme.palette.common.orange5,
          color: theme.palette.warning.main,
        };

      default:
        return {
          backgroundColor: open && !styleType ? theme.palette.common.lightBg : undefined,
          color: open && !styleType ? theme.palette.primary.main : theme.palette.common.grey2,
        };
    }
  }, [open, styleType]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onMenuClick = useCallback(
    async (handler: () => Promise<Boolean>, pendingTitle?: string) => {
      handleClose();
      if (pendingTitle) {
        setPendingTitle(pendingTitle);
      }

      const res = await handler();
      if (res) {
        setRequestStatus('success');
        setPendingTitle('');
        setTimeout(() => setRequestStatus('default'), DELAY_BEFORE_CHANGE_REQUEST_STATUS);
      }
    },
    [],
  );

  const iconColor = currentStyles.color ? currentStyles.color : theme.palette.common.grey2;
  const isDisabled = disabled && !tooltipTitle;

  const actionIcon = useMemo(() => {
    if (pending) {
      return (
        <Icon>
          <Spin />
        </Icon>
      );
    }
    if (requestStatus === 'success') {
      return (
        <Icon
          sx={{
            color: iconColor,
            display: 'inline',
          }}
        >
          <CheckIcon />
        </Icon>
      );
    }
    return null;
  }, [iconColor, pending, requestStatus]);

  const text = useMemo(
    () => (pending && pendingTitle ? pendingTitle : title),
    [pending, pendingTitle, title],
  );

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!tooltipTitle) {
        setAnchorEl(e.currentTarget);
        return;
      }

      if (!showTooltip) {
        setShowTooltip(true);
      } else {
        setShowTooltip(false);
      }
    },
    [showTooltip, tooltipTitle],
  );

  const onClickAway = useCallback(() => {
    if (showTooltip) {
      setShowTooltip(false);
    }
  }, [showTooltip]);

  return (
    <>
      <ClickAwayListener onClickAway={onClickAway}>
        <Tooltip
          open={showTooltip}
          placement="bottom"
          componentsProps={{
            tooltip: {
              sx: {
                padding: (theme) => theme.spacing(1.25, 1),
              },
            },
          }}
          title={tooltipTitle}
        >
          <Stack
            className="HeaderActions"
            sx={(theme) => ({
              border: theme.mixins.borderValue(),
              borderRadius: '100px',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              px: theme.spacing(1.25),
              height: 30,
              cursor: 'pointer',
              justifyContent: 'center',
              pointerEvents: pending || isDisabled ? 'none' : undefined,
              ...currentStyles,
            })}
            onClick={onClick}
          >
            <Stack direction="row" alignItems="center" gap={1}>
              {actionIcon}
              <Typography color={currentStyles.color}>{text}</Typography>

              {!pending && !isDisabled && (
                <Box
                  sx={{
                    '.svg-icon': {
                      width: 8,
                      transform: Boolean(open) || showTooltip ? 'rotate(180deg)' : undefined,
                    },
                    '.arrowDropdown': {
                      path: {
                        stroke: currentStyles.color,
                      },
                    },
                  }}
                >
                  <ArrowDownV2Icon className="arrowDropdown" />
                </Box>
              )}
            </Stack>
          </Stack>
        </Tooltip>
      </ClickAwayListener>

      <Menu
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{ vertical: 20, horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}
        sx={{
          '& .MuiList-root': {
            padding: 0,
            width: 180,
          },
          '& .MuiMenuItem-root': {
            paddingTop: 0,
            '&:hover': {
              '& .MuiTypography-root, .svg-icon': {
                color: 'common.main2',
              },
            },
            color: 'primary.main',
          },
          ...sx,
        }}
      >
        {actions.map(({ titleTextId, handler, icon, pendingTitle, disabled: actionDisabled }) => (
          <ContextMenuItem
            onMenuClick={handler ? () => onMenuClick(handler, pendingTitle) : undefined}
            key={titleTextId}
            titleTextId={titleTextId}
            icon={icon}
            disabled={actionDisabled}
          />
        ))}
      </Menu>
    </>
  );
};

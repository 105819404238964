import { Checkbox, FormHelperText, Stack, Typography } from '@mui/material';
import { FormNumberSelect } from '@schooly/components/form-text-field';
import { RadioOnIcon } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FormCheckboxStyled } from 'apps/web/src/components/uikit-components/FormCheckbox/FormCheckbox.styled';
import { isBefore } from 'date-fns';
import { FC, PropsWithChildren, useCallback, useMemo } from 'react';
import { Controller, get, useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';
import { $enum } from 'ts-enum-util';

import { EndDateDropdown, Preset } from './RecurringEndDateDropdown';
import { RecurringForm } from './RecurringModal';
import { RepeatOnMonth } from './RecurringMonthSelect';
import { getFollowingDatesByMonth, getWeekDatesByEndDate } from './utils';

const OCCURRENCE_MIN = 2;
const OCCURRENCE_MAX = 99;

export enum RecurringEndsSection {
  ByDate = 1,
  ByOccurrence = 2,
}

export const RECURRING_OPTIONS = $enum(RecurringEndsSection).map((value, key) => ({
  labelTextId: `recurring-${key}`,
  value,
}));

export const RecurringEndsLabels = {
  [RecurringEndsSection.ByDate]: 'recurring-EndsOn',
  [RecurringEndsSection.ByOccurrence]: 'recurring-EndsAfter',
};

type RecurringEndDateProps = {
  presets: Preset[];
  required: boolean;
  endDateHelperText?: string;
  occurrenceHelperText?: string;
  startDate: Date;
  entity: { name: string; namePlural: string };
  repeatCount: number;
};

export const RecurringEndDate: FC<RecurringEndDateProps> = ({
  presets,
  required,
  endDateHelperText,
  occurrenceHelperText,
  startDate,
  entity,
  repeatCount,
}) => {
  const { setValue, watch, control, formState, trigger } = useFormContext<RecurringForm>();
  const { isSubmitted } = formState;

  const endsByDate = watch('ends.byDate');
  const weekDays = watch('repeat_on_day_of_week');
  const endsSection = watch('ends.section');
  const repeatOnMonth = watch('repeat_on_month');
  const period = watch('period');
  const allSectionErrorMessage = get(formState.errors, 'ends')?.message;
  const dateSelected = endsSection === RecurringEndsSection.ByDate;
  const occurrenceSelected = endsSection === RecurringEndsSection.ByOccurrence;
  const { $t } = useIntl();

  const highlightedDays = useMemo(() => {
    if (endsSection !== RecurringEndsSection.ByDate || !endsByDate || !repeatCount) return;
    if (period === 'week' && weekDays.length) {
      return getWeekDatesByEndDate({
        endDate: newDateTimezoneOffset(endsByDate),
        repeatCount,
        startDate,
        weekDays,
      });
    }
    if (period === 'month') {
      return getFollowingDatesByMonth({
        ends: newDateTimezoneOffset(endsByDate),
        repeatCount,
        startDate,
        dayOfMonth: repeatOnMonth === RepeatOnMonth.ByDayOfMonth ? startDate.getDate() : undefined,
      });
    }
  }, [endsByDate, endsSection, period, repeatCount, repeatOnMonth, startDate, weekDays]);

  const handleDateValidation = useCallback(
    (v?: string, state?: RecurringForm) => {
      if (state?.ends?.section !== RecurringEndsSection.ByDate) return true;

      if (!v) return $t({ id: 'input-ErrorRequired' });

      const isBeforeStartDate = isBefore(newDateTimezoneOffset(v), startDate);
      if (isBeforeStartDate) {
        return $t(
          { id: 'recurring-EndDateError' },
          {
            entityPlural: entity.namePlural.toLowerCase(),
          },
        );
      }

      return true;
    },
    [$t, entity.namePlural, startDate],
  );

  const handleSectionValidate = useCallback(
    (v?: RecurringForm['ends']) => {
      if (!v?.section) {
        return $t({ id: 'recurring-EndsSectionError' }, { entity: entity.name });
      }
      return true;
    },
    [$t, entity.name],
  );

  const dateHelperText = dateSelected ? endDateHelperText : undefined;

  return (
    <Stack width="100%">
      <Controller
        name={'ends'}
        control={control}
        rules={{
          required,
          validate: handleSectionValidate,
        }}
        render={() => {
          return (
            <Stack gap={1.25}>
              <Stack direction="row" alignItems="flex-start">
                <CheckBoxFormWrapper selected={dateSelected}>
                  <FormCheckboxStyled
                    control={
                      <Checkbox
                        name="ends.section"
                        sx={(theme) => ({
                          ...(Boolean(allSectionErrorMessage) && {
                            svg: {
                              circle: {
                                stroke: theme.palette.error.main,
                              },
                            },
                          }),
                        })}
                        checked={dateSelected}
                        onChange={() => {
                          setValue('ends.section', RecurringEndsSection.ByDate);
                          if (isSubmitted) {
                            trigger();
                          }
                        }}
                        checkedIcon={<RadioOnIcon className="reset-svg-currentColor" />}
                      />
                    }
                    sx={{
                      minWidth: 114,
                    }}
                    label={$t({ id: RecurringEndsLabels[RecurringEndsSection.ByDate] })}
                  />

                  <EndDateDropdown
                    presets={presets}
                    onClick={() => {
                      if (!dateSelected) {
                        setValue('ends.section', RecurringEndsSection.ByDate);
                      }
                    }}
                    error={allSectionErrorMessage}
                    helperText={dateSelected ? dateHelperText || occurrenceHelperText : undefined}
                    onValidate={handleDateValidation}
                    highlightedDays={highlightedDays}
                    selected={dateSelected}
                  />
                </CheckBoxFormWrapper>
              </Stack>

              <Stack direction="row" alignItems="flex-start">
                <CheckBoxFormWrapper selected={occurrenceSelected}>
                  <FormCheckboxStyled
                    control={
                      <Checkbox
                        name="ends.section"
                        checked={occurrenceSelected}
                        sx={(theme) => ({
                          ...(Boolean(allSectionErrorMessage) && {
                            svg: {
                              circle: {
                                stroke: theme.palette.error.main,
                              },
                            },
                          }),
                        })}
                        onChange={() => {
                          setValue('ends.section', RecurringEndsSection.ByOccurrence);
                          if (isSubmitted) {
                            trigger();
                          }
                        }}
                        checkedIcon={<RadioOnIcon className="reset-svg-currentColor" />}
                      />
                    }
                    label={$t({ id: RecurringEndsLabels[RecurringEndsSection.ByOccurrence] })}
                    sx={{
                      minWidth: 114,
                    }}
                  />
                </CheckBoxFormWrapper>

                <Stack width="100%">
                  <FormNumberSelect
                    onClick={() => {
                      if (!occurrenceSelected) {
                        setValue('ends.section', RecurringEndsSection.ByOccurrence);
                      }
                    }}
                    name="ends.byOccurrence"
                    placeholder={$t({ id: 'recurring-OccurrencesNumber' })}
                    min={OCCURRENCE_MIN}
                    max={OCCURRENCE_MAX}
                    helperText={occurrenceSelected ? occurrenceHelperText : undefined}
                    error={!!allSectionErrorMessage}
                    InputProps={{
                      sx: (theme) => ({
                        input: {
                          color: !occurrenceSelected ? theme.palette.common.grey : undefined,
                        },
                      }),
                    }}
                    rules={{
                      validate: (value) => {
                        if (occurrenceSelected && !value) return $t({ id: 'input-ErrorRequired' });
                        return true;
                      },
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
          );
        }}
      />
      {allSectionErrorMessage && (
        <FormHelperText>
          <Typography color="error.main" variant="caption" ml={1.5}>
            {allSectionErrorMessage}
          </Typography>
        </FormHelperText>
      )}
    </Stack>
  );
};

type CheckBoxFormWrapperProps = PropsWithChildren<{ selected: boolean }>;

export const CheckBoxFormWrapper: FC<CheckBoxFormWrapperProps> = ({ selected, children }) => (
  <Stack
    direction="row"
    alignItems="flex-start"
    sx={(theme) => {
      const color = selected ? theme.palette.primary.light : theme.palette.primary.main;
      return {
        '& :hover': {
          '.MuiFormControlLabel-label': {
            color,
          },
          '.MuiCheckbox-root svg :first-child': {
            stroke: color,
          },
        },
      };
    }}
  >
    {children}
  </Stack>
);

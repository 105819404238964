import { Button, DialogActions, DialogContent } from '@mui/material';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { ModalConfirmation } from '../../../../components/uikit-components/Modal/ModalConfirmation.styled';
import { useMessage } from '../../../../context/messages/useMessage';

export const MessagesCloseModalConfirmation: FC = () => {
  const { isCloseModalDialogOpen, actions } = useMessage();

  return (
    <ModalConfirmation
      open={isCloseModalDialogOpen}
      onClose={actions.onClose}
      PaperProps={{
        sx: {
          maxWidth: 'unset',
          width: 600,
        },
      }}
    >
      <DialogContent
        sx={{
          width: '100% !important',
        }}
      >
        <FormattedMessage id="messages-CloseConfirmation" />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" autoFocus onClick={actions.hideCloseModalDialog}>
          <FormattedMessage id="action-Cancel" />
        </Button>
        <Button onClick={actions.onClose}>
          <FormattedMessage id="action-Confirm" />
        </Button>
      </DialogActions>
    </ModalConfirmation>
  );
};

import { Stack, Typography } from '@mui/material';
import { GroupTagSelect } from '@schooly/components/filters';
import { CheckIcon, EditIcon } from '@schooly/style';
import { FormattedMessage } from 'react-intl';

import Button from '../../../../components/ui/Button';
import {
  ModalConfirm,
  ModalContent,
  ModalFooter,
} from '../../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeaderStyled } from '../../../../components/uikit-components/Modal/ModalHeader.styled';

export interface DeleteHistoryDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
  onEdit: () => void;
  invalidGroups?: string[];
  allInvalid?: boolean;
  allInvalidHideCancel?: boolean;
}

export const MessagesDeleteGroupModal: React.FC<DeleteHistoryDialogProps> = ({
  isOpen,
  onClose,
  onConfirm,
  onEdit,
  invalidGroups,
  allInvalid,
  allInvalidHideCancel,
}) => {
  const invalidOptions = [
    'messages-InvalidGroupsDescriptionOption1',
    'messages-InvalidGroupsDescriptionOption2',
    'messages-InvalidGroupsDescriptionOption3',
  ];

  return (
    <ModalConfirm open={isOpen}>
      <ModalHeaderStyled active>
        <Typography variant="h1">
          <FormattedMessage
            id={!allInvalid ? 'messages-InvalidGroups' : 'messages-InvalidGroupsAll'}
          />
        </Typography>
      </ModalHeaderStyled>

      <ModalContent active>
        <Stack>
          <Typography variant="body1">
            <FormattedMessage id="messages-InvalidGroupsDescription" />
          </Typography>

          <ul>
            {invalidOptions.map((id) => (
              <li>
                <Typography variant="body1">
                  <FormattedMessage id={id} />
                </Typography>
              </li>
            ))}
          </ul>
        </Stack>

        <Stack gap={2}>
          <Stack pr={1} direction="row" flexWrap="wrap" rowGap={1.5} columnGap={0.5}>
            {invalidGroups?.map((id) => (
              <GroupTagSelect id={id} />
            ))}
          </Stack>
        </Stack>
      </ModalContent>
      <ModalFooter active>
        {!allInvalidHideCancel && (
          <Button type="button" color="secondary" onClick={onClose}>
            <FormattedMessage id="action-Cancel" />
          </Button>
        )}

        {allInvalid && (
          <Button color="primary" icon={<EditIcon />} type="submit" onClick={onEdit}>
            <FormattedMessage id="action-Edit" />
          </Button>
        )}

        {!allInvalid && (
          <Button color="primary" rightIcon={<CheckIcon />} type="submit" onClick={onConfirm}>
            <FormattedMessage id="action-Confirm" />
          </Button>
        )}
      </ModalFooter>
    </ModalConfirm>
  );
};

import { Box, Card, Stack, Typography } from '@mui/material';
import { GroupMember, GroupUserType } from '@schooly/api';
import { AvatarAuth } from '@schooly/components/avatar-auth';
import { DATE_FORMAT, Genders, getLabelIdForOption } from '@schooly/constants';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { getUserFullName } from '@schooly/utils/get-user-full-name';
import { format } from 'date-fns';
import React, { PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { LongTitleVerticalTooltip } from '../../../../components/uikit/LongTitleVerticalTooltip/LongTitleVerticalTooltip';
import { getRouteModalPathname } from '../../../../helpers/misc';

interface GroupMembersCardListProps extends PropsWithChildren {
  groupMembers?: GroupMember[];
  userType: GroupUserType;
  cardsInRow?: number;
  cardWidth?: number;
  title: string;
}

export const GroupMembersCardList: React.FC<GroupMembersCardListProps> = ({
  groupMembers,
  userType,
  cardsInRow = 3,
  cardWidth = 300,
  title,
}) => {
  const { formatMessage } = useIntl();

  if (!groupMembers?.length) {
    return null;
  }

  return (
    <Stack gap={1}>
      <Typography color="common.grey3">{title}</Typography>
      <Stack direction="row" flexWrap="wrap" gap={2}>
        {!!groupMembers?.length &&
          groupMembers.map(({ user }) => {
            const dateOfBirth = user.date_of_birth
              ? format(newDateTimezoneOffset(user.date_of_birth), DATE_FORMAT)
              : '';

            const fullName = getUserFullName(user);

            const gender = Number.isInteger(user.gender)
              ? formatMessage({
                  id: getLabelIdForOption({
                    key: Genders[user.gender!],
                    optionsKey: 'gender',
                  }),
                })
              : '';

            return (
              <Card
                sx={(theme) => ({
                  padding: theme.spacing(1.25, 1.5),
                  whiteSpace: 'nowrap',
                  width: `calc(100% / ${cardsInRow} - ${theme.spacing(
                    2,
                  )} * (${cardsInRow} - 1) / ${cardsInRow})`,
                  maxWidth: cardWidth,
                  border: 'none',
                  '&:hover': {
                    backgroundColor: theme.palette.common.lightBg,
                    '.MuiTypography-root': { color: theme.palette.primary.main },
                  },
                })}
              >
                <Stack direction="row" gap={1} alignItems="center">
                  <Box>
                    <AvatarAuth user={user} withAvatarPreview />
                  </Box>
                  <Stack minWidth={0} width="100%" overflow="hidden">
                    <Box
                      sx={{
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      <LongTitleVerticalTooltip
                        withoutArrow
                        renderTooltipContent={(longName) => (
                          <Link to={getRouteModalPathname(userType, user)}>
                            <Typography color="text.primary">{longName}</Typography>
                          </Link>
                        )}
                        getOffset={(height) => [-8, -(height + 12)]}
                      >
                        <Link to={getRouteModalPathname(userType, user)}>
                          <Typography component="span" variant="h3" color="text.primary">
                            {fullName}
                          </Typography>
                        </Link>
                      </LongTitleVerticalTooltip>
                    </Box>
                    <Stack
                      direction="row"
                      width="100%"
                      justifyContent={dateOfBirth ? 'space-between' : 'flex-end'}
                    >
                      <Typography component="div">{dateOfBirth}</Typography>

                      <Typography component="div">{gender}</Typography>
                    </Stack>
                  </Stack>

                  {/* The second part of the card, expects BE support */}
                  {/* <Stack
              direction="row"
              sx={(theme) => ({
                borderWidth: '0 0 0 1px',
                borderStyle: 'dotted',
                borderColor: theme.palette.common.light3,
                borderImageSlice: '33% 33%',
                borderImageRepeat: 'round',
              })}
            >
              <Box
                sx={(theme) => ({
                  padding: theme.spacing(1.5, 1.25),
                })}
              >
                <Typography color="common.grey2">
                  Today’s <br /> attendance
                </Typography>
              </Box>
            </Stack> */}
                </Stack>
              </Card>
            );
          })}
      </Stack>
    </Stack>
  );
};

import { Box, Stack, Typography } from '@mui/material';
import { HTML_TAGS_REGEXP, RichText, RichTextRef } from '@schooly/components/form-rich-text';
import { ButtonWithDescription, PlusIcon, PreviewModalCard, ShowHtml } from '@schooly/style';
import React, { forwardRef, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { ConsentForm } from '../../../../components/common/ConsentForms/ConsentForm';
import { MessageForm } from '../../../../context/messages/MessageContext';
import { useMessage } from '../../../../context/messages/useMessage';
import { MESSAGE_MAX_CHARS_LENGTH } from '../../../../context/messages/WithMessage';
import { RichTextWrapper } from '../../../Events/EventCreateModal/EventCreateContent.styled';
interface MessagesCreateModalMessageProps {
  shouldValidate: boolean;
  toolbarReplaced?: boolean;
}
export const MessagesCreateModalMessage = forwardRef<RichTextRef, MessagesCreateModalMessageProps>(
  ({ shouldValidate, toolbarReplaced }, toolbarRef) => {
    const { actions, consentForm, messageId, isPublished, consentFormShowed, linkedConsentForm } =
      useMessage();

    const { formatMessage } = useIntl();
    const { control, formState, watch, setValue } = useFormContext<MessageForm>();
    const scrollContainer = useRef<HTMLDivElement | null>(null);

    const body = watch('body');
    const attachments = watch('attachments');

    const bodyText = body ? body.replaceAll(HTML_TAGS_REGEXP, '') : '';
    const bodyError = formState.errors.body?.message;

    const canCreateConsentForm = (!messageId || !isPublished) && !linkedConsentForm;

    return (
      <Stack gap={5} pt={2.5} sx={{ '& .ql-editor': { minHeight: '120px !important' } }}>
        <Controller
          name="body"
          control={control}
          rules={{
            validate: (value) => {
              // the "Save" action can skip validation, only on Submit/Publish it's mandatory
              if (!shouldValidate) {
                return undefined;
              }

              const text = value ? value.replaceAll(HTML_TAGS_REGEXP, '') : '';
              return !text ? formatMessage({ id: 'input-ErrorRequired' }) : undefined;
            },
          }}
          render={({ field }) => {
            return (
              <Stack>
                <RichTextWrapper error={!!bodyError}>
                  {!bodyText && (
                    <Box position="absolute" sx={{ top: 60, right: 40 }}>
                      <Typography color="text.secondary" variant="h3" sx={{ fontStyle: 'italic' }}>
                        <FormattedMessage id="input-required" />
                      </Typography>
                    </Box>
                  )}
                  <RichText
                    ref={toolbarRef}
                    value={field.value}
                    onChange={field.onChange}
                    files={attachments}
                    setFiles={(v) => setValue('attachments', v)}
                    maxBodyLength={MESSAGE_MAX_CHARS_LENGTH}
                    scrollingContainer={scrollContainer.current}
                  />
                </RichTextWrapper>
                {bodyError && (
                  <Typography
                    color="error.main"
                    variant="body2"
                    sx={{ pl: 2, pt: 0.25, mt: -2.25 }}
                  >
                    {bodyError}
                  </Typography>
                )}
              </Stack>
            );
          }}
        />

        {canCreateConsentForm && consentForm && (
          <ConsentForm
            visible={consentFormShowed}
            onDelete={actions.hideConsentForm}
            consentForm={consentForm}
          />
        )}
        {canCreateConsentForm && !consentFormShowed && (
          <ButtonWithDescription
            onClick={actions.showConsentForm}
            startIcon={<PlusIcon />}
            title={formatMessage({ id: 'consentForms-title' })}
            description={formatMessage({ id: 'consentForms-titleDescription' })}
            oneLine
          />
        )}

        {linkedConsentForm && (
          <PreviewModalCard
            path={`/consent-form/${linkedConsentForm.id}`}
            title={formatMessage({ id: 'consentForms-title' })}
          >
            <ShowHtml
              html={linkedConsentForm.description}
              sx={{
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
              }}
            />
          </PreviewModalCard>
        )}
      </Stack>
    );
  },
);

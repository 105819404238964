import { InboxMessage, Message } from '@schooly/api';
import { format, isThisYear, isToday } from 'date-fns';

import { MessageFormCriteria } from '../context/messages/MessageContext';
import { retrieveApiDate } from './misc';

export function formatMessageDate(date?: Date) {
  if (!date) return null;

  if (isToday(date)) {
    return format(date, 'H:mm');
  }

  if (isThisYear(date)) {
    return format(date, 'd MMM');
  }

  return format(date, 'd MMM yy');
}

export function getMessagePublishDate(message?: Message | InboxMessage) {
  if (!message || !message.publish_date) return undefined;

  return retrieveApiDate(message.publish_date);
}

export function getFormattedPublishDate(message?: Message | InboxMessage) {
  const publishDate = getMessagePublishDate(message);
  return formatMessageDate(publishDate);
}

export const isOnlyGroupAndDateFilled = (criteria: MessageFormCriteria) => {
  const { date, age_group, nationality, gender, subject, house, department, status } = criteria;
  return (
    !age_group?.length &&
    !nationality?.length &&
    !gender?.length &&
    !subject?.length &&
    !house?.length &&
    !department?.length &&
    !status?.length &&
    (date?.length || 0) <= 1
  );
};

import { SchoolUserRole } from '@schooly/constants';
import { CancelToken } from 'axios';

import { ConsentFormShort } from '../consentForms';
import { Group } from './groups';
import { CriteriaUpdate } from './misc';
import { BaseUserRelation } from './school_users';
import { School } from './schools';
import { UserFilter } from './users';

export interface ListMessagesForGroup {
  groupId: string;
  pageSize?: number;
  pageNumber?: number;
  searchQuery?: string;
}

export interface Message {
  title: string;
  id: string;
  status: MessageStatus;
  creator?: MessageInteractor;
  publisher: MessageInteractor;
  criteria_date: number;
  publish_date: number | null;
  created_at: number;
  updated_at: number;
  school_id: string;
  body: string;
  recipient_id?: string;
  recipient_read?: boolean;
  school?: School;
  staff_count?: number;
  parent_count?: number;
  percentage_read?: number;
  attachments: IncomingAttachment[] | null;
  consent_form?: ConsentFormShort;
}

export interface MessageForGroup extends Message {}
export interface InboxMessage extends Omit<Message, 'publisher' | 'creator'> {
  sender: MessageInteractor;
}

export interface MessageInteractor extends BaseUserRelation {
  user_id?: string;
}

export enum MessageStatus {
  'Published',
  'Draft',
  'Submitted',
}

export enum MessageAction {
  Publish = 'publish',
  Submit = 'submit',
  Unsubmit = 'unsubmit',
}

export interface MessageUpdate {
  title: string;
  body: string;
  attachments: OutcomingAttachment[];
  criteria_date?: string | null;
  parents_of_students_ids?: string[];
  parents_of_students_criteria?: CriteriaUpdate;
  staff_ids?: string[];
  staff_criteria?: CriteriaUpdate;
  school_id: string;
  attachment_ids_to_save?: string[];

  // Individual parent and staff by individual student recipients are deprecated
  //This logic should be removed as soon as there are no messages with such recipients
  parents_ids?: string[];
  staff_of_students_ids?: string[];
}

export interface IncomingAttachment {
  id: string;
  name: string;
  size: number;
  type: string;
}

export interface OutcomingAttachment {
  data: string;
  name: string;
}

export interface IncomingAttachmentFile extends IncomingAttachment {
  data: string;
}

export interface Recipient {
  id: string;
  profile: BaseUserRelation;
  read: boolean;
  school_user_relation: MessageInteractor;
  school_user_relation_role: SchoolUserRole;
}

export interface Individual {
  id: string;
  individual_type: number;
  recipient_type: SchoolUserRole;
  school_user_relation: MessageInteractor;
}

export interface MessageCriteria {
  id: string;
  criterion_type: MessageCriterion;
  recipient_type: SchoolUserRole;
  filter_element: FilterElement;
}

export interface FilterElement {
  type: MessageCriteriaType;
  element_id?: string[];
  element_enum_index?: number[] | null;
  groups?: Pick<Group, 'name' | 'id'>[] | null;
}

export interface ListMessagesForRelation {
  relationId: string;
  pageSize?: number;
  pageNumber?: number;
  token?: CancelToken;
}

export enum MessageCriteriaType {
  Status,
  Gender,
  Nationality,
  Subject,
  Group,
  Age_group,
  House,
  Campus,
  Department,
}

export enum MessageCriterion {
  Staff,
  Parents,
}

export interface IListMessagesForSchool {
  filters?: Partial<UserFilter>;
  page_number?: number;
  page_size?: number;
  school_id?: string;
  query?: string;
}

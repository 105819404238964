import { Box, Button, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import { AssignedProduct, PaymentFrequency, WithAvatar, WithName } from '@schooly/api';
import { AvatarAuth } from '@schooly/components/avatar-auth';
import { EditIcon, PlusIcon, TypographyWithOverflowHint } from '@schooly/style';
import { getUserFullName } from '@schooly/utils/user-helpers';
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { ProfileModalMode } from '../../../../context/profile/helpers';
import { AssignedProductsList, AssignedProductsListSkeleton } from './AssignedProductsList';

type StudentWithProductsProps<S extends { id: string } & WithName & WithAvatar> = {
  student: S;
  products: AssignedProduct[];
  onEdit?: (id: string) => void;
  frequencies?: PaymentFrequency[];
  schoolId: string;
};

export const StudentWithProducts = <S extends { id: string } & WithName & WithAvatar>({
  student,
  products,
  onEdit,
  frequencies,
  schoolId,
}: StudentWithProductsProps<S>) => {
  const { formatMessage } = useIntl();

  const headerTitles: [string, string] = [
    `${formatMessage({ id: 'profile-Active' })} / ${formatMessage({
      id: 'profile-Upcoming',
    })}`,
    `${formatMessage({ id: 'profile-NotActive' })} / ${formatMessage({
      id: 'profile-Issued',
    })}`,
  ];

  const hasProducts = Boolean(products.length);

  return (
    <Box>
      <Stack direction="row" mb={1} p={1} justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap={0.5} sx={{ overflowX: 'hidden' }}>
          <AvatarAuth user={student} withAvatarPreview />
          <TypographyWithOverflowHint
            variant="h3"
            color="text.primary"
            component={Link}
            to={{
              pathname: `/students/${student.id}`,
              hash: `#${ProfileModalMode.PayersAndProducts}`,
            }}
            sx={{ ':hover': { textDecoration: 'underline', color: 'primary.main' } }}
          >
            {getUserFullName(student)}
          </TypographyWithOverflowHint>
        </Stack>
        {onEdit && hasProducts && (
          <IconButton sx={{ color: 'common.grey2' }} onClick={() => onEdit(student.id)}>
            <EditIcon />
          </IconButton>
        )}
      </Stack>

      {hasProducts ? (
        <AssignedProductsList
          products={products}
          frequencies={frequencies}
          headerTitles={headerTitles}
          schoolId={schoolId}
        />
      ) : (
        <StudentWithProductsListEmpty
          buttonTextId="profile-AddProduct"
          onClick={onEdit ? () => onEdit(student.id) : undefined}
          textId="profile-StudentHasNoProductAssignments"
        />
      )}
    </Box>
  );
};

export const StudentWithProductsSkeleton = () => {
  return (
    <>
      <Stack direction="row" mb={1} p={1.5} pl={1}>
        <Typography variant="h3">
          <Skeleton width={150} />
        </Typography>
      </Stack>
      <AssignedProductsListSkeleton />
    </>
  );
};

type StudentWithProductsListEmptyProps = {
  onClick?: () => void;
  buttonTextId: string;
  textId: string;
};

export const StudentWithProductsListEmpty: FC<StudentWithProductsListEmptyProps> = ({
  onClick,
  textId,
  buttonTextId,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      gap={1}
      p={onClick ? 1.25 : 1.75}
      bgcolor="background.paper"
      sx={(theme) => ({ borderRadius: theme.spacing(1) })}
    >
      <Typography variant="h3" color={onClick ? 'primary.main' : 'text.secondary'}>
        {formatMessage({ id: textId })}
      </Typography>

      {onClick && (
        <Button startIcon={<PlusIcon />} onClick={onClick} size="small">
          {formatMessage({ id: buttonTextId })}
        </Button>
      )}
    </Stack>
  );
};

import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { SHORT_FORMATTED_DATE_FORMAT_FNS } from '@schooly/api';
import { InformationIcon, TagSelect } from '@schooly/style';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import PersonCardBasic from '../../../../components/common/PersonCard/PersonCardBasic';
import {
  GridRowItem,
  GridRowName,
  GridRowStyled,
} from '../../../../components/uikit-components/Grid/Grid';
import { SelectedCriteriaList } from '../../../../components/uikit-components/SelectedCriteriaList/SelectedCriteriaList.styled';
import { useMessage } from '../../../../context/messages/useMessage';
import { getDisplayedCriteria } from '../../helpers';

export const MessagesModalPreviewSendToParents: FC = () => {
  const { parents, parentsCriteria, parentsByIndividualStudent, criteriaDate } = useMessage();
  const { formatMessage } = useIntl();

  return (
    <>
      {Boolean(parentsCriteria?.length || parentsByIndividualStudent?.length) && (
        <SelectedCriteriaList mb={2.5}>
          <Typography variant="h4" sx={{ mb: 1 }}>
            <FormattedMessage id="messages-ParentsOfStudents" />
          </Typography>

          {!!criteriaDate && (
            <Stack direction="row" alignItems="center" spacing={0.5} mb={1}>
              <TagSelect
                label={
                  <Typography variant="h3" color={(theme) => theme.palette.common.grey2}>
                    {format(new Date(criteriaDate), SHORT_FORMATTED_DATE_FORMAT_FNS)}
                  </Typography>
                }
                sx={(theme) => ({
                  '&.MuiChip-root': {
                    backgroundColor: theme.palette.background.paper,
                  },
                })}
              />

              <Tooltip
                title={formatMessage({ id: 'messages-ParentsOfStudentsPreviewTooltip' })}
                placement="top"
              >
                <IconButton>
                  <InformationIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          )}

          {
            /* Parents criteria */
            getDisplayedCriteria({ selectedCriteria: parentsCriteria })
          }

          {
            /* Parents by individual students */
            Boolean(parentsByIndividualStudent?.length) && (
              <Box>
                {parentsByIndividualStudent.map((student) => (
                  <GridRowStyled key={student.relation_id}>
                    <GridRowItem noVerticalPadding>
                      <GridRowName>
                        <PersonCardBasic
                          user={student}
                          userType="student"
                          isListItem
                          isUsernameClickable
                        />
                      </GridRowName>
                    </GridRowItem>
                  </GridRowStyled>
                ))}
              </Box>
            )
          }
        </SelectedCriteriaList>
      )}

      {
        /* Individual parents */
        Boolean(parents?.length) && (
          <Box>
            <Typography variant="h4" sx={{ mb: 1 }}>
              <FormattedMessage id="messages-Parents" />
            </Typography>
            <Box>
              {parents.map((parent) => (
                <GridRowStyled key={parent.relation_id}>
                  <GridRowItem noVerticalPadding>
                    <GridRowName>
                      <PersonCardBasic
                        user={parent}
                        userType="parent"
                        isListItem
                        isUsernameClickable
                      />
                    </GridRowName>
                  </GridRowItem>
                </GridRowStyled>
              ))}
            </Box>
          </Box>
        )
      }
    </>
  );
};

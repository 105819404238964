import { Loading, ModalLarge } from '@schooly/style';
import React, { FC } from 'react';

import { useMessage } from '../../../context/messages/useMessage';
import { MessagesCreateContent } from './MessagesCreateContent';

export const MessagesCreateModal: FC = () => {
  const { fetching, actions } = useMessage();

  return (
    <ModalLarge open onClose={actions.onCloseCreateModal}>
      {fetching ? <Loading /> : <MessagesCreateContent />}
    </ModalLarge>
  );
};

import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { PayableFeeWithInvoice, useGetInvoiceQuery } from '@schooly/api';
import {
  CrossIcon,
  Grid,
  GridBody,
  GridContainer,
  Loading,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalLarge,
  ModalMain,
  ModalSmall,
  Spin,
} from '@schooly/style';
import { getUserFullName } from '@schooly/utils/user-helpers';
import { FC, PropsWithChildren } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { getCurrencySymbol } from '../../../pages/School/SchoolProducts/helpers';
import { PayableFeesRelationType } from '../PayableFees/PayableFees';
import { LineItemRow, PayableFeeHeader } from '../PayableFees/PayableFeeTable';

type VoidInvoiceModalProps = {
  payableFee: PayableFeeWithInvoice;
  relationType: PayableFeesRelationType;
  schoolId: string;
  opened: boolean;
  onClose: () => void;
  onSubmit: () => void;
  isLoading: boolean;
};

export const VoidInvoiceModal: FC<VoidInvoiceModalProps> = (props) => {
  const {
    relationType,
    onSubmit,
    onClose,
    payableFee: { total_payment, currency },
  } = props;

  if (!props.opened) return null;

  if (relationType === 'student') return <VoidInvoiceContent {...props} />;

  const currencyStr = getCurrencySymbol(currency);

  return (
    <VoidInvoiceConfirmationModal>
      <VoidInvoiceConfirmationContent
        currency={currencyStr}
        total={total_payment}
        onClose={onClose}
        onSubmit={onSubmit}
        isLoading={props.isLoading}
      />
    </VoidInvoiceConfirmationModal>
  );
};

const VoidInvoiceContent: FC<VoidInvoiceModalProps> = ({
  payableFee,
  onSubmit,
  opened,
  onClose,
  ...props
}) => {
  const { formatMessage } = useIntl();

  const { data, isFetching } = useGetInvoiceQuery(
    { id: payableFee.invoice_id },
    { refetchOnMount: 'always', enabled: opened },
  );

  if (!data || isFetching) {
    return (
      <VoidInvoiceConfirmationModal>
        <Loading />
      </VoidInvoiceConfirmationModal>
    );
  }

  const { invoice } = data;

  const hasMultipleStudents = invoice.students.length > 1;
  const student = payableFee.students[0];

  const studentFullName = student ? getUserFullName(student) : '';

  if (hasMultipleStudents) {
    return (
      <ModalLarge
        open={opened}
        onClose={onClose}
        sx={{
          '& .MuiDialog-paperFullWidth': {
            height: 'auto',
          },
        }}
      >
        <ModalHeader
          title={
            <Box>
              <Typography variant="h1">
                {formatMessage({ id: 'payableFees-InvoiceVoidMultipleLineItems' })}
              </Typography>

              <Typography variant="h1">
                {formatMessage(
                  { id: 'payableFees-InvoiceEditMultipleLineItems' },
                  { studentFullName: studentFullName },
                )}
              </Typography>
            </Box>
          }
          active
        >
          <IconButton onClick={onClose}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>
        <ModalMain>
          <ModalContent active>
            <Box
              sx={(theme) => ({
                border: `1px solid ${theme.palette.common.light2}`,
                borderRadius: theme.spacing(1),
              })}
            >
              <GridContainer>
                <Grid>
                  <PayableFeeHeader
                    {...props}
                    isMultipleItemsView
                    payableFee={{ ...payableFee, students: data.invoice.students }}
                    hasInvoice={false}
                    hasProcessingIssue={false}
                  />
                  <GridBody>
                    {data.invoice.items.map((item, i) => {
                      const isLastItemRow = i === data.invoice.items.length - 1;
                      const key = `${item.product_id}-${item.variant_id}`;
                      const student = data.invoice.students.find(
                        (student) => student.relation_id === item.student_relation_id,
                      );

                      return (
                        <LineItemRow
                          key={key}
                          item={item}
                          student={student}
                          currency={getCurrencySymbol(
                            item.billing_connection.legal_entity_currency,
                          )}
                          isLastItemRow={isLastItemRow}
                          isMultipleItemsView
                        />
                      );
                    })}
                  </GridBody>
                </Grid>
              </GridContainer>
            </Box>
          </ModalContent>
        </ModalMain>

        <ModalFooter active>
          <Button
            onClick={onSubmit}
            disabled={props.isLoading}
            startIcon={props.isLoading ? <Spin /> : undefined}
          >
            <FormattedMessage id="payableFees-VoidInvoice" />
          </Button>
        </ModalFooter>
      </ModalLarge>
    );
  }

  return (
    <VoidInvoiceConfirmationModal>
      <VoidInvoiceConfirmationContent
        currency={getCurrencySymbol(invoice.legal_entity.currency)}
        total={invoice.total_payment}
        onClose={onClose}
        onSubmit={onSubmit}
        isLoading={props.isLoading}
      />
    </VoidInvoiceConfirmationModal>
  );
};

type VoidInvoiceConfirmationContentProps = {
  total: number;
  currency: string;
  onClose: VoidInvoiceModalProps['onClose'];
  onSubmit: VoidInvoiceModalProps['onSubmit'];
  isLoading: boolean;
};

const VoidInvoiceConfirmationContent: FC<VoidInvoiceConfirmationContentProps> = ({
  currency,
  total,
  onClose,
  onSubmit,
  isLoading,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Stack gap={2.5} padding={2.5}>
      <Typography variant="h1">
        {formatMessage(
          { id: 'payableFees-VoidInvoiceWithItemConfirm' },
          { total: `${currency} ${total}` },
        )}
      </Typography>

      <Stack direction="row" justifyContent="space-between" gap={2}>
        <Button disabled={isLoading} onClick={onClose} fullWidth variant="outlined">
          <FormattedMessage id="action-Cancel" />
        </Button>
        <Button
          disabled={isLoading}
          onClick={onSubmit}
          fullWidth
          startIcon={isLoading ? <Spin /> : undefined}
        >
          <FormattedMessage id="action-Confirm" />
        </Button>
      </Stack>
    </Stack>
  );
};

const VoidInvoiceConfirmationModal: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ModalSmall
      sx={{
        '& .MuiDialog-paperFullWidth': {
          width: 600,
          height: 184,
        },
      }}
      open
    >
      {children}
    </ModalSmall>
  );
};

import { Box, Button, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import {
  DEFAULT_DATE_FORMAT_FNS,
  Invoice,
  InvoiceLineItem,
  InvoiceStudent,
  Payer,
  PayerType,
  ProductBillingType,
  useInvoiceActionMutation,
  useUpdateInvoiceMutation,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { AvatarAuth } from '@schooly/components/avatar-auth';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { getPayerId, PayerContent, PayerDropdown, SelectRow } from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import { Currencies, SchoolInviteStatus } from '@schooly/constants';
import {
  ArrowDownV2Icon,
  CrossIcon,
  DeleteIcon,
  GridBody,
  GridCell,
  GridRow,
  InvoiceIcon,
  LockIcon,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalLarge,
  ModalMain,
  NewTabIcon,
  Price,
  SimpleButton,
  Spin,
  TypographyWithOverflowHint,
  UnpublishIcon,
} from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { getUserFullName } from '@schooly/utils/user-helpers';
import { addDays, differenceInDays, format } from 'date-fns';
import { FC, PropsWithChildren, useCallback, useMemo } from 'react';
import React from 'react';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { InvoiceDateForm } from '../../components/common/Invoices/InvoiceDateForm';
import { InvoiceDueDayForm } from '../../components/common/Invoices/InvoiceDueDayForm';
import { useRouter } from '../../context/router/useRouter';
import { ProductTableHeader } from '../ProfileModal/tabs/ProfileModalAssignedProductsList/SingleInvoices/Products/ProductContent';
import { ProductDropdown } from '../ProfileModal/tabs/ProfileModalAssignedProductsList/SingleInvoices/Products/ProductDropdown';
import {
  AddProductForm,
  DiscountForm,
} from '../ProfileModal/tabs/ProfileModalAssignedProductsList/SingleInvoices/Products/ProductForms';
import { ParentInviteNotification } from '../ProfileModal/tabs/ProfileModalAssignedProductsList/SingleInvoices/SingleInvoicesContent';
import { getProductTotal } from '../ProfileModal/tabs/ProfileModalAssignedProductsList/SingleInvoices/utils';
import { getCurrencySymbol } from '../School/SchoolProducts/helpers';

type InvoiceEditContentProps = {
  invoice: Invoice;
};

const newLineItemId = 'lineItemId' as const;

type InvoiceEditForm = {
  generation_date: string;
  students: Array<InvoiceStudent & { items: Array<InvoiceLineItem & { fieldId?: string }> }>;
  invoice_date: string;
  due_days_count: number;
  payer: Invoice['payer'];
};

export const InvoiceEditContent: FC<InvoiceEditContentProps> = ({ invoice }) => {
  const form = useForm<InvoiceEditForm>({
    defaultValues: {
      generation_date: format(newDateTimezoneOffset(invoice.issue_date), DEFAULT_DATE_FORMAT_FNS),
      students: invoice.students.map((s) => ({
        ...s,
        items: invoice.items.filter((i) => i.student_relation_id === s.relation_id),
      })),
      invoice_date: format(newDateTimezoneOffset(invoice.invoice_date), DEFAULT_DATE_FORMAT_FNS),
      due_days_count: differenceInDays(
        newDateTimezoneOffset(invoice.due_date),
        newDateTimezoneOffset(invoice.issue_date),
      ),

      payer: invoice.payer,
    },
  });

  const { formatMessage } = useIntl();
  const { goBack } = useRouter();
  const { invoice_id: invoiceId } = invoice;
  const { getConfirmation } = useConfirmationDialog();
  const { showError, showNotification } = useNotifications();
  const { schoolId = '' } = useAuth();
  const generationDate = form.watch('generation_date');
  const currentPayer = form.watch('payer');

  const invoiceAction = useInvoiceActionMutation();
  const updateInvoice = useUpdateInvoiceMutation();
  // From https://schooly-org.slack.com/archives/C076FPU73NU/p1738105493059999?thread_ts=1738070068.453869&cid=C076FPU73NU
  // With Anton we decided that
  // For 1.13 we disable payer editing
  const payerDisabled = true;

  const isInvoiceHasPayment = !!invoice.total_paid;
  const currentStudents = form.watch('students');

  const studentPayersMap = useMemo(() => {
    const studentMap = new Map<string, Payer[]>();
    currentStudents.forEach((student) => {
      const commonParents = currentStudents[0].parents
        .filter((parent) =>
          currentStudents.every((s) => s.parents.some((p) => p.relation_id === parent.relation_id)),
        )
        .map((p) => ({ type: PayerType.Default as const, data: p }));

      const company = currentStudents[0].company_payer;
      const isCommonCompany = currentStudents[0].company_payer
        ? currentStudents.every((s) => s.company_payer?.id === currentStudents[0].company_payer?.id)
        : null;

      studentMap.set(student.relation_id, [
        ...commonParents,
        ...(isCommonCompany && company
          ? [{ type: PayerType.Company as const, data: company }]
          : []),
      ]);
    });
    return studentMap;
  }, [currentStudents]);

  const { fields: students, remove } = useFieldArray({
    control: form.control,
    name: 'students',
  });

  const handleClose = useCallback(async () => {
    if (
      form.formState.isDirty &&
      !(await getConfirmation({
        textId: 'school-edit-CloseUnsavedConfirmation',
      }))
    )
      return;

    goBack();
  }, [form.formState.isDirty, getConfirmation, goBack]);

  const handleVoidInvoice = useCallback(async () => {
    await invoiceAction.mutateAsync(
      { invoice_ids: [invoice.invoice_id], action: 'void' },
      {
        onSuccess: () => {
          goBack();
          showNotification({
            textId: 'payableFees-InvoiceUpdated',
            type: 'success',
          });
        },
        onError: showError,
      },
    );
  }, [goBack, invoice.invoice_id, invoiceAction, showError, showNotification]);

  const handleSubmit = useCallback<SubmitHandler<InvoiceEditForm>>(
    async ({ due_days_count, generation_date, ...invoiceForm }) => {
      updateInvoice.mutate(
        {
          id: invoiceId,
          invoice: {
            ...invoice,
            due_date: format(
              addDays(newDateTimezoneOffset(invoice.issue_date), due_days_count),
              DEFAULT_DATE_FORMAT_FNS,
            ),
            issue_date: generation_date,
            ...invoiceForm,
            students: invoiceForm.students.map(({ items, ...s }) => s),
            items: invoiceForm.students.flatMap((s) =>
              s.items.map(({ id, fieldId, ...item }) =>
                id === newLineItemId ? item : { ...item, id },
              ),
            ),
          },
        },
        {
          onError: showError,
          onSuccess: () => {
            showNotification({
              textId: 'payableFees-InvoiceUpdated',
              type: 'success',
            });
            goBack();
          },
        },
      );
    },
    [goBack, invoice, invoiceId, showError, showNotification, updateInvoice],
  );

  return (
    <ModalLarge open onClose={handleClose}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <ModalHeader
            title={
              <TypographyWithOverflowHint variant="h1">
                {formatMessage({ id: 'payableFees-EditInvoice' })} {invoice.invoice_number}
              </TypographyWithOverflowHint>
            }
            active
          >
            <IconButton onClick={handleClose}>
              <CrossIcon />
            </IconButton>
          </ModalHeader>
          <ModalMain>
            <ModalContent active sx={{ pb: 0 }}>
              <Stack direction="row" gap={2}>
                <Stack flex={1}>
                  <InvoiceDateForm
                    disabled
                    renderRightIcon={() => (
                      <InvoiceTooltip titleId="payableFees-DisabledInvoiceDateTitle">
                        <IconButton inverse>
                          <LockIcon />
                        </IconButton>
                      </InvoiceTooltip>
                    )}
                  />
                </Stack>
                <InvoiceDueDayForm generationDate={generationDate} />
              </Stack>

              <Stack gap={2}>
                {students.map((s, i) => {
                  const payers = studentPayersMap.get(s.relation_id);

                  const selectedPayer =
                    currentPayer.type === PayerType.Default
                      ? { type: 'guardian' as const, ...currentPayer.data }
                      : { type: 'company' as const, ...currentPayer.data };

                  return (
                    <Box key={s.relation_id}>
                      <Stack direction="row" alignItems="center" pt={3} pb={1.75}>
                        <Stack direction="row" gap={1} flex={'0 0 52%'}>
                          <AvatarAuth user={s} />
                          <Typography variant="h2">{getUserFullName(s)}</Typography>
                        </Stack>
                        <Stack flex={1}>
                          <PayerDropdown
                            selectedPayer={selectedPayer}
                            disabled={payerDisabled}
                            renderMainContent={() => (
                              <PayerContent
                                labelText={formatMessage({ id: 'profile-Payer' })}
                                payer={selectedPayer}
                                isSelected
                                withAvatarPreview
                                avatarTooltipTitle={formatMessage({
                                  id: 'payableFees-ChangeSuitablePayer',
                                })}
                                sx={
                                  payerDisabled
                                    ? {
                                        '.MuiTypography-root.MuiTypography-h3': {
                                          color: 'common.grey2',
                                        },
                                      }
                                    : undefined
                                }
                              >
                                {payerDisabled ? (
                                  <InvoiceTooltip titleId="payableFees-DisabledPayerTitle">
                                    <IconButton
                                      sx={{
                                        pointerEvents: 'auto',
                                      }}
                                      onClick={(e) => e.stopPropagation()}
                                      inverse
                                    >
                                      <LockIcon />
                                    </IconButton>
                                  </InvoiceTooltip>
                                ) : (
                                  <IconButton
                                    sx={(theme) => ({
                                      width: theme.spacing(1),
                                      path: {
                                        stroke: theme.palette.common.grey,
                                      },
                                    })}
                                  >
                                    <ArrowDownV2Icon className="arrowDropdown" />
                                  </IconButton>
                                )}
                              </PayerContent>
                            )}
                            renderDropdownContent={(close) => (
                              <Box px={1} py={0.5}>
                                {payers?.map((p) => {
                                  const payer =
                                    p.type === PayerType.Default
                                      ? { type: 'guardian' as const, ...p.data }
                                      : { type: 'company' as const, ...p.data };
                                  const payerId = getPayerId(payer);
                                  const selectedPayerId = getPayerId(selectedPayer);
                                  return (
                                    <SelectRow
                                      key={payerId}
                                      onSelect={() => {
                                        form.setValue('payer', p);
                                        close();
                                      }}
                                      isSelected={selectedPayerId === payerId}
                                    >
                                      <PayerContent payer={payer} withAvatarPreview />
                                    </SelectRow>
                                  );
                                })}
                              </Box>
                            )}
                          />
                        </Stack>

                        {!isInvoiceHasPayment && students.length > 1 && (
                          <IconButton
                            inverse
                            onClick={() => {
                              remove(i);
                            }}
                            sx={{
                              mr: 1.75,
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Stack>
                      {currentPayer.type === PayerType.Default &&
                        currentPayer.data.invite_status !== SchoolInviteStatus.Active && (
                          <ParentInviteNotification
                            schoolId={schoolId}
                            parent={currentPayer.data}
                          />
                        )}

                      <InvoiceLineItemsForm
                        studentIndex={i}
                        schoolId={schoolId}
                        isInvoiceHasPayment={isInvoiceHasPayment}
                        invoiceLink={invoice.invoice_link}
                        legalEntityId={invoice.legal_entity.id}
                      />
                    </Box>
                  );
                })}
              </Stack>
              <InvoiceFormTotal
                students={currentStudents}
                currency={invoice.legal_entity.currency}
              />
            </ModalContent>
          </ModalMain>
          <ModalFooter active sx={{ justifyContent: 'space-between' }}>
            <InvoiceTooltip
              titleId={isInvoiceHasPayment ? 'payableFees-InvoiceHasPaymentWarning' : ''}
              invoiceLink={invoice.invoice_link}
            >
              <span>
                <Button
                  variant="outlined"
                  disabled={
                    isInvoiceHasPayment || invoiceAction.isLoading || updateInvoice.isLoading
                  }
                  startIcon={invoiceAction.isLoading ? <Spin /> : <UnpublishIcon />}
                  onClick={handleVoidInvoice}
                >
                  <FormattedMessage id="payableFees-VoidInvoice" />
                </Button>
              </span>
            </InvoiceTooltip>

            <Button
              type="submit"
              disabled={updateInvoice.isLoading || invoiceAction.isLoading}
              startIcon={updateInvoice.isLoading ? <Spin /> : <InvoiceIcon />}
            >
              <FormattedMessage id="payableFees-UpdateInvoice" />
            </Button>
          </ModalFooter>
        </form>
      </FormProvider>
    </ModalLarge>
  );
};

type InvoiceFormTotalProps = {
  currency: Currencies;
  students: InvoiceEditForm['students'];
};

const InvoiceFormTotal: FC<InvoiceFormTotalProps> = ({ currency, students }) => {
  const { formatMessage } = useIntl();

  const productsTotal = students
    .flatMap((s) => s.items)
    .reduce(
      (acc, p) =>
        acc +
        getProductTotal({
          price: p.price_full,
          quantity: 1,
          discountPercent: p.discount_percent,
        }),
      0,
    );

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      sx={(theme) => ({
        py: 2,
        mt: 0.25,
        pr: 10,
        gap: 0,
        borderTop: theme.mixins.borderValue(),
        borderWidth: '3px',
      })}
    >
      <Typography variant="h3" pr={2.25}>
        {formatMessage({ id: 'people-TotalCount' })}
        {':'}
      </Typography>
      <Price currency={getCurrencySymbol(currency)} price={productsTotal} color="primary.main" />
    </Stack>
  );
};

type InvoiceLineItemsFormProps = {
  studentIndex: number;
  schoolId: string;
  legalEntityId: string;
  isInvoiceHasPayment: boolean;
  invoiceLink?: string;
};

const InvoiceLineItemsForm: FC<InvoiceLineItemsFormProps> = ({
  studentIndex,
  schoolId,
  legalEntityId,
  isInvoiceHasPayment,
  invoiceLink,
}) => {
  const { control, watch, setValue } = useFormContext<InvoiceEditForm>();

  const { remove, append, fields, update } = useFieldArray({
    control: control,
    name: `students.${studentIndex}.items`,
    keyName: 'fieldId',
  });

  const student = watch(`students.${studentIndex}`);

  const studentLineItems = useMemo(
    () => fields.filter((formLineItem) => formLineItem.student_relation_id === student.relation_id),
    [fields, student.relation_id],
  );

  return (
    <Box>
      <Grid>
        <ProductTableHeader />
        <GridBody
          sx={{
            '& .MuiTableCell-root': {
              height: '44px !important',
            },
          }}
        >
          {fields.map((item, lineItemIndex) => {
            return (
              <LineItemRow
                key={item.fieldId}
                studentIndex={studentIndex}
                lineItemIndex={lineItemIndex}
                isInvoiceHasPayment={isInvoiceHasPayment}
                schoolId={schoolId}
                relationId={student.relation_id}
                invoiceLink={invoiceLink}
                onDelete={() => {
                  if (item.product_type === ProductBillingType.Recurring) {
                    setValue(
                      `students.${studentIndex}.items.${lineItemIndex}.discount_percent`,
                      100,
                    );
                    update(lineItemIndex, {
                      ...item,
                      discount_percent: 100,
                    });
                  } else {
                    remove(lineItemIndex);
                  }
                }}
              />
            );
          })}
        </GridBody>
      </Grid>
      {!isInvoiceHasPayment && (
        <Box mt={!studentLineItems.length ? 4 : 0}>
          <AddProductForm
            name={`students.${studentIndex}.items`}
            onSelect={(p) => {
              append({
                id: newLineItemId,
                discount_amount: 0,
                student_name: student.given_name ?? '',
                student_relation_id: student.relation_id,
                discount_percent: p.discount_percent ?? 0,
                label: p.name,
                name: p.name,
                price: p.price,
                price_full: p.price,
                product_id: p.id,
                product_type: ProductBillingType.OneOff,
                variant_id: p.variant_id,
                variant_name: p.type_name,
                billing_connection: p.billing_connection,
              });
            }}
            schoolId={schoolId}
            relationId={student.relation_id}
            legalEntityIds={[legalEntityId]}
          />
        </Box>
      )}
    </Box>
  );
};

type LineItemRowProps = {
  lineItemIndex: number;
  studentIndex: number;
  isInvoiceHasPayment: boolean;
  invoiceLink?: string;
  schoolId: string;
  onDelete?: () => void;
  relationId: string;
};

const LineItemRow: FC<LineItemRowProps> = ({
  lineItemIndex,
  studentIndex,
  isInvoiceHasPayment,
  schoolId,
  invoiceLink,
  relationId,
  onDelete,
}) => {
  const { control, watch } = useFormContext<InvoiceEditForm>();
  const { formatMessage } = useIntl();
  const name = `students.${studentIndex}.items.${lineItemIndex}` as const;
  const item = watch(name);
  const discountPercent = item.discount_percent;
  const studentLineItems = watch(`students.${studentIndex}.items`);

  const deletedRecurring =
    item.product_type === ProductBillingType.Recurring && item.discount_percent === 100;

  const canDeleteLineItem =
    !isInvoiceHasPayment && !deletedRecurring && studentLineItems.length > 1;

  return (
    <InvoiceTooltip
      titleId={isInvoiceHasPayment ? 'payableFees-InvoiceHasPaymentWarning' : ''}
      invoiceLink={invoiceLink}
    >
      <GridRow
        sx={{
          ':hover': {
            '.MuiTypography-root': {
              color: 'primary.main',
            },
            '.hoverText': {
              color: 'common.grey2',
            },
          },
        }}
      >
        <GridCell noVerticalPadding>
          <Controller
            control={control}
            name={name}
            rules={{
              required: true,
            }}
            render={({ field }) => {
              return (
                <Stack flex={1} justifyContent="center" maxWidth={280}>
                  <InvoiceTooltip
                    titleId={
                      !isInvoiceHasPayment && item.product_type === ProductBillingType.Recurring
                        ? 'payableFees-InvoiceRecurringProductWarning'
                        : ''
                    }
                  >
                    <span>
                      <ProductDropdown
                        onSelect={field.onChange}
                        schoolId={schoolId}
                        selectedProduct={{
                          ...item,
                          quantity: 1,
                          id: item.product_id,
                          type_id: item.product_id,
                          type_name: item.variant_name,
                        }}
                        relationId={relationId}
                        legalEntityIds={[item.billing_connection.legal_entity_id]}
                        placeholder={formatMessage({ id: 'products-Product' })}
                        disabled={item.id !== newLineItemId}
                        PopperProps={{
                          modifiers: [
                            {
                              name: 'offset',
                              options: {
                                offset: [-10, -23],
                              },
                            },
                          ],
                        }}
                      />
                    </span>
                  </InvoiceTooltip>
                </Stack>
              );
            }}
          />
        </GridCell>
        <GridCell noVerticalPadding>
          <TypographyWithOverflowHint color="common.grey2" maxWidth={120}>
            {item.billing_connection.legal_entity_display_name}
          </TypographyWithOverflowHint>
        </GridCell>
        <GridCell noVerticalPadding>
          <TypographyWithOverflowHint color="common.grey2" maxWidth={120}>
            {item.billing_connection.legal_entity_account.type === 'xero'
              ? item.billing_connection.legal_entity_account.name
              : '-'}
          </TypographyWithOverflowHint>
        </GridCell>
        <GridCell noVerticalPadding>
          <Typography color="common.grey2">1</Typography>
        </GridCell>
        <GridCell noVerticalPadding>
          <Price
            color="common.grey2"
            variant="body1"
            price={item.price_full}
            currency={getCurrencySymbol(item.billing_connection.legal_entity_currency)}
          />
        </GridCell>
        <GridCell noVerticalPadding>
          {isInvoiceHasPayment ? (
            <Typography color="common.grey2" minWidth={40}>
              {item.discount_percent ? item.discount_percent : '-'}
            </Typography>
          ) : (
            <DiscountForm name={`${name}.discount_percent`} />
          )}
        </GridCell>
        <GridCell noVerticalPadding>
          <Price
            color="common.grey2"
            variant="body1"
            price={getProductTotal({
              price: item.price_full,
              quantity: 1,
              discountPercent,
            })}
            currency={getCurrencySymbol(item.billing_connection.legal_entity_currency)}
          />
        </GridCell>
        <GridCell noVerticalPadding width={40}>
          {canDeleteLineItem ? (
            <IconButton inverse onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
          ) : null}
        </GridCell>
      </GridRow>
    </InvoiceTooltip>
  );
};

const InvoiceTooltip: FC<PropsWithChildren<{ titleId: string; invoiceLink?: string }>> = ({
  children,
  titleId = '',
  invoiceLink,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: (theme) => ({
            padding: theme.spacing(1.5),
            width: 200,
          }),
        },
      }}
      title={
        !!titleId ? (
          <Stack gap={1.25}>
            <Typography>{formatMessage({ id: titleId })}</Typography>
            {invoiceLink && (
              <Link to={invoiceLink} target="_blank">
                <SimpleButton
                  sx={(theme) => ({
                    marginTop: theme.spacing(1),
                    '& .MuiButton-startIcon .svg-icon': {
                      fontSize: theme.spacing(2),
                    },
                  })}
                  startIcon={<NewTabIcon />}
                >
                  <Typography>{formatMessage({ id: 'payableFees-ViewInvoiceInXero' })}</Typography>
                </SimpleButton>
              </Link>
            )}
          </Stack>
        ) : (
          ''
        )
      }
    >
      {React.isValidElement(children) ? children : <></>}
    </Tooltip>
  );
};

import { AdultAssociation, SyncUser } from '@schooly/api';
import { AvatarAuth } from '@schooly/components/avatar-auth';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { UserContexts } from '../../../constants/userContexts';
import { getRouteModalPathname } from '../../../helpers/misc';
import { getAdultUserTypes, getUserFullName } from '../../../helpers/users';
import useAppLocation from '../../../hooks/useAppLocation';
import buildClassName from '../../../utils/buildClassName';

import './user-nav.scss';

interface UserNavProps {
  user: SyncUser;
  onClick: VoidFunction;
  onProfileOpen: VoidFunction;
  logOut: VoidFunction;
}

const UserNav: React.FC<UserNavProps> = ({ user, onClick, onProfileOpen, logOut }) => {
  const navigate = useNavigate();
  const location = useAppLocation();

  const userTypes = getAdultUserTypes(user);

  const isParent = userTypes?.includes('parent');

  const handleMyProfileClick = useCallback(() => {
    onProfileOpen();
    onClick();
  }, [onClick, onProfileOpen]);

  const handleChildProfileClick = useCallback(
    (child: AdultAssociation) => {
      navigate(
        {
          pathname: getRouteModalPathname('child', child),
          search: `?context=${UserContexts.Individual}`,
        },
        { state: { backgroundLocation: location } },
      );
      onClick();
    },
    [onClick, navigate, location],
  );

  return (
    <div className="UserNav">
      <div
        className={buildClassName(
          'UserNav__my-profile',
          !isParent ? 'UserNav__my-profile--pb' : '',
        )}
      >
        <h3 className="h3 UserNav__title">
          <FormattedMessage id="peopleDetail-MyProfile" />
        </h3>
        <div
          role="button"
          tabIndex={0}
          onClick={handleMyProfileClick}
          className={buildClassName(
            'UserNav__my-profile-item',
            'UserNav__item',
            'UserNav__children-list__item',
          )}
        >
          <AvatarAuth user={user} />
          <div className="UserNav__my-profile__text">
            <p className="m-0">{getUserFullName(user)}</p>
            <p className="m-0 text-small text-muted UserNav__user-email">{user.account_email}</p>
          </div>
        </div>
      </div>

      {isParent && (
        <div className="UserNav__children-list">
          <h3 className="h3 UserNav__title">
            <FormattedMessage id="peopleDetail-MyChildren" />
          </h3>
          {user.adult_associations.map((u) => (
            <div
              onClick={() => handleChildProfileClick(u)}
              role="button"
              tabIndex={0}
              className={buildClassName('UserNav__item', 'UserNav__children-list__item')}
              key={u.user_id}
            >
              <AvatarAuth user={u} className="UserNav__children-list__item__avatar" />
              <div>
                <p className="UserNav__children-list__item__name">{getUserFullName(u)}</p>
                {u.date_of_birth && (
                  <span className="UserNav__children-list__item__date-of-birth">
                    {format(newDateTimezoneOffset(u.date_of_birth), 'dd MMM yyy')}
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
      )}

      <div
        role="button"
        tabIndex={0}
        className="UserNav__logout"
        onClick={logOut}
        data-test-id="logout-nav-button"
      >
        <p className="UserNav__logout-text h3">
          <FormattedMessage id="login-Logout" />
        </p>
      </div>
    </div>
  );
};

export default UserNav;

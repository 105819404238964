import { Box, Icon, IconButton, Stack, styled, Typography } from '@mui/material';
import {
  ConductConnotation,
  ConductEntry,
  ConductTypeRepresentation,
  ConductVisibility,
  getConductCertificate,
  GetConductEntriesQuerySort,
  SORT_DIRECTION,
  useGetConductTypesForSchoolQuery,
} from '@schooly/api';
import { AvatarAuth } from '@schooly/components/avatar-auth';
import { DATE_FORMAT_SHORT } from '@schooly/constants';
import {
  DropdownIcon,
  EditIcon,
  GridCell,
  GridHead,
  GridRow,
  GridRowProps,
  SortableCell,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { getUserFullName } from '@schooly/utils/get-user-full-name';
import { format } from 'date-fns';
import { FC, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ConductVisibilityButton } from './ConductVisibilityButton';

type ConductEntriesHeaderProps = {
  sort?: GetConductEntriesQuerySort;
  onChangeSort: (v: GetConductEntriesQuerySort) => void;
  rightIcon?: ReactNode;
};

export const ConductEntriesHeader: FC<ConductEntriesHeaderProps> = ({
  onChangeSort,
  sort,
  rightIcon,
}) => {
  const { formatMessage } = useIntl();
  const handleSort = (columnTextId: GetConductEntriesQuerySort['columnTextId']) => () => {
    onChangeSort({
      columnTextId,
      direction:
        columnTextId === sort?.columnTextId
          ? sort.direction === SORT_DIRECTION.ASC
            ? SORT_DIRECTION.DESC
            : SORT_DIRECTION.ASC
          : SORT_DIRECTION.ASC,
    });
  };

  return (
    <GridHead borderBottom>
      <SortableCell
        label={formatMessage({ id: 'assessments-Name' })}
        sort={sort}
        width="50%"
        columnTextId="student"
        onChangeSort={handleSort}
        pl={2}
      />
      <SortableCell
        width="70px"
        label={formatMessage({ id: 'conduct-Date' })}
        columnTextId="date"
        sort={sort}
        onChangeSort={handleSort}
      />
      <GridCell width="70px">{formatMessage({ id: 'conduct-visibility' })}</GridCell>
      <SortableCell
        width="110px"
        label={formatMessage({ id: 'conduct-Type' })}
        columnTextId="conduct_type"
        sort={sort}
        onChangeSort={handleSort}
        sx={(theme) => ({
          [theme.breakpoints.only('md')]: {
            width: 90,
          },
        })}
      />
      <GridCell
        width="110px"
        sx={(theme) => ({
          [theme.breakpoints.only('lg')]: {
            width: 90,
          },
          [theme.breakpoints.only('md')]: {
            width: 70,
          },
          [theme.breakpoints.down('md')]: {
            width: 90,
          },
        })}
      >
        {formatMessage({ id: 'conduct-TitleValue' })}
      </GridCell>
      <GridCell width="50%">{formatMessage({ id: 'conduct-Detail' })}</GridCell>
      <GridCell width="40px" />
      <GridCell
        width="120px"
        sx={(theme) => ({
          [theme.breakpoints.only('lg')]: {
            width: 110,
          },
          [theme.breakpoints.only('md')]: {
            width: 90,
          },
          [theme.breakpoints.down('md')]: {
            width: 110,
          },
        })}
      >
        {formatMessage({ id: 'conduct-Teacher' })}
      </GridCell>
      <GridCell width="40px" />
      <GridCell width="40px" pr={1} right>
        {rightIcon}
      </GridCell>
    </GridHead>
  );
};

export type ConductEntryRowProps = {
  entry: ConductEntry;
  subEntries?: ConductEntry[];
  commentsCellContent?: ReactNode;
  isLast?: boolean;
  isExpanded?: boolean;
  isEditable?: boolean;
  schoolId: string;
  hoverIdStudentId: string | null;
  onExpand?: () => void;
  onClick?: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
} & GridRowProps;
export const ConductEntryRow: FC<ConductEntryRowProps> = ({
  schoolId,
  entry,
  subEntries,
  isExpanded,
  hoverIdStudentId,
  isEditable,
  isLast,
  commentsCellContent,
  onExpand,
  onClick,
  ...rowProps
}) => {
  const isSubRow = !subEntries;
  const hasSubRows = Boolean(subEntries?.length);
  const canShowCrossFields = !hasSubRows || isExpanded;
  const studentHoverId = entry.student.relation_id;
  const { data: types } = useGetConductTypesForSchoolQuery(schoolId);

  const canShowVisibilityIcon = entry.visibility === ConductVisibility.PUBLISHED;

  // first type we already know (the type of the current entry)
  let conductTypesTotal = 1;
  const conductTypes: Record<ConductConnotation, number> = {
    [ConductConnotation.POSITIVE]: 0,
    [ConductConnotation.NEGATIVE]: 0,
    [entry.conduct_type.connotation]: 1,
  };

  // other types need to accumulate
  subEntries?.forEach((item) => {
    conductTypesTotal++;
    conductTypes[item.conduct_type.connotation]++;
  });

  let titleOrValue: ReactNode;

  const conductType = types?.find((type) => type.id === entry.conduct_type.id);

  const studentName = getUserFullName(entry.student);
  const staffName = getUserFullName(entry.staff);

  if (conductType?.type === ConductTypeRepresentation.NUMBER) {
    titleOrValue = entry.value;
  } else {
    titleOrValue =
      entry.title && conductType?.name === 'Certificate'
        ? getConductCertificate(entry.title)?.label
        : entry.title;
  }

  return (
    <GridRow
      {...rowProps}
      onClick={onClick}
      sx={{
        cursor: onClick ? 'pointer' : 'default',
        '&:hover': {
          '.edit-icon': {
            opacity: 1,
          },
        },
      }}
    >
      {/* Student name */}
      <GridCell
        sx={{
          position: 'relative',
          '&&': {
            backgroundColor: hoverIdStudentId === studentHoverId ? 'background.default' : undefined,
          },
          '&&&&&': { borderBottomColor: isExpanded && !isLast ? 'transparent' : undefined },
        }}
      >
        {!isSubRow && (
          <>
            <AvatarAuth
              withAvatarPreview
              user={entry.student}
              sx={(theme) => ({
                position: 'absolute',
                left: theme.spacing(1),
                marginTop: theme.spacing(-0.5),
              })}
            />
            <TypographyWithOverflowHint ml={5} variant="h3" color="common.grey2" noWrap>
              {studentName}
            </TypographyWithOverflowHint>
          </>
        )}
      </GridCell>

      {/* Date */}
      <GridCell>
        {canShowCrossFields && (
          <Typography>{format(newDateTimezoneOffset(entry.date), DATE_FORMAT_SHORT)}</Typography>
        )}
      </GridCell>

      {/* Visibility */}
      <GridCell>
        {canShowVisibilityIcon && canShowCrossFields && (
          <ConductVisibilityButton
            tooltipTitle={<FormattedMessage id="conduct-parents-visible" />}
            selected
            nowrap
          />
        )}
      </GridCell>

      {/* Conduct type */}
      <GridCell>
        {/*
            Display types summary (count of each type) if there are several ones,
            or a certain type if only one presented.
            In expanded mode each row displays its own type, so no need to collapse in this case.
            */}
        {!isExpanded && conductTypesTotal > 1 ? (
          <Stack direction="row" gap={2}>
            {conductTypes[ConductConnotation.POSITIVE] > 0 && (
              <ConnotationCellContent
                type={ConductConnotation.POSITIVE}
                label={conductTypes[ConductConnotation.POSITIVE]}
              />
            )}

            {conductTypes[ConductConnotation.NEGATIVE] > 0 && (
              <ConnotationCellContent
                type={ConductConnotation.NEGATIVE}
                label={conductTypes[ConductConnotation.NEGATIVE]}
              />
            )}
          </Stack>
        ) : (
          <ConnotationCellContent
            type={entry.conduct_type.connotation}
            label={entry.conduct_type.name}
          />
        )}
      </GridCell>

      {/* Title / Value */}
      <GridCell>{canShowCrossFields && <Typography>{titleOrValue ?? '-'}</Typography>}</GridCell>

      {/* Detail */}
      <GridCell>
        {canShowCrossFields && (
          <TypographyWithOverflowHint>{entry.details || '—'}</TypographyWithOverflowHint>
        )}
      </GridCell>

      {/* Comment */}
      <GridCell>{canShowCrossFields && commentsCellContent}</GridCell>

      {/* Teacher */}
      <GridCell>
        {canShowCrossFields && <TypographyWithOverflowHint>{staffName}</TypographyWithOverflowHint>}
      </GridCell>

      {/* Edit icon */}
      <GridCell>
        {isEditable && canShowCrossFields && (
          <Icon
            className="edit-icon"
            sx={(theme) => ({
              opacity: 0,

              transition: theme.transitions.create('opacity', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.shortest,
              }),
            })}
          >
            <EditIcon />
          </Icon>
        )}
      </GridCell>

      {/* Dropdown icon */}
      <GridCell
        right
        sx={{
          pr: 1,
          ...(isExpanded && !isLast
            ? { '&&&&&': { borderBottomColor: 'transparent' } }
            : undefined),
        }}
      >
        {onExpand && (
          <IconButton
            inverse
            sx={{ transform: isExpanded ? `rotate(180deg)` : undefined }}
            onClick={(event) => {
              event.stopPropagation();
              onExpand();
            }}
          >
            <DropdownIcon />
          </IconButton>
        )}
      </GridCell>
    </GridRow>
  );
};

type ConnotationCellContentProps = {
  type: ConductConnotation;
  label: ReactNode;
};
export const ConnotationCellContent: FC<ConnotationCellContentProps> = ({ type, label }) => {
  return (
    <Stack direction="row" alignItems="baseline" gap={1}>
      <ConductConnotationIndicator connotation={type} />
      <TypographyWithOverflowHint>{label}</TypographyWithOverflowHint>
    </Stack>
  );
};

export interface ConductConnotationIndicatorProps {
  connotation: ConductConnotation;
}

export const ConductConnotationIndicator = styled(Box)<ConductConnotationIndicatorProps>(
  ({ theme, connotation }) => ({
    flex: '0 0 auto',
    width: theme.spacing(),
    height: theme.spacing(),
    borderRadius: '50%',
    background:
      connotation === ConductConnotation.POSITIVE
        ? theme.palette.success.main
        : theme.palette.error.main,
  }),
);

import { Stack, TableRow, Typography } from '@mui/material';
import { ConsentFormItemShort, SHORT_FORMATTED_DATE_FORMAT_FNS } from '@schooly/api';
import { AvatarAuth } from '@schooly/components/avatar-auth';
import { GridCell, TypographyWithOverflowHint } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { getUserFullName } from '@schooly/utils/user-helpers';
import { format } from 'date-fns';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { ConsentFormSignedInfo } from '../../components/common/ConsentForms/ConsentFormPreview';
import { getConsentFormItemStatusLabelId } from '../../components/common/ConsentForms/helpers';
import { getRouteModalPathname } from '../../helpers/misc';

type ConsentItemRowProps = {
  consentItem: ConsentFormItemShort;
};

export const ConsentItemRow: FC<ConsentItemRowProps> = ({ consentItem }) => {
  const { status } = consentItem;
  const agreedColor = status === 'agreed' ? 'success.main' : undefined;
  const declinedColor = status === 'declined' ? 'error.main' : undefined;
  const noStatusColor = status === 'no_status' ? 'common.grey' : undefined;
  const { $t } = useIntl();

  const statusLabel = $t({ id: getConsentFormItemStatusLabelId(status) });
  return (
    <TableRow
      sx={(theme) => ({
        cursor: 'pointer',
        '&:hover': {
          'td.MuiTableCell-root': {
            backgroundColor: theme.palette.background.default,
          },
          '.MuiTypography-root:not(.status)': {
            color: theme.palette.primary.main,
          },
          '.removeIcon': {
            visibility: 'visible',
          },
        },
      })}
    >
      <GridCell noVerticalPadding width="43%">
        <Stack direction="row" alignItems="center" gap={1}>
          <AvatarAuth user={consentItem.signed_for} withAvatarPreview />
          <TypographyWithOverflowHint
            variant="h3"
            color="text.primary"
            noWrap
            component={Link}
            maxWidth={358}
            to={getRouteModalPathname('student', consentItem.signed_for)}
            sx={{ '&:hover': { textDecoration: 'underline' } }}
          >
            {getUserFullName(consentItem.signed_for)}
          </TypographyWithOverflowHint>
        </Stack>
      </GridCell>

      <GridCell noVerticalPadding width={status === 'no_status' ? '57%' : '17%'}>
        <Typography className="status" color={agreedColor || declinedColor || noStatusColor}>
          {statusLabel}
        </Typography>
      </GridCell>
      {status !== 'no_status' && (
        <>
          <GridCell noVerticalPadding width="18%">
            {consentItem.sign_date && (
              <Typography color="text.primary" noWrap>
                {format(
                  newDateTimezoneOffset(consentItem.sign_date),
                  SHORT_FORMATTED_DATE_FORMAT_FNS,
                )}
              </Typography>
            )}
          </GridCell>
          <GridCell noVerticalPadding>
            {consentItem.signed_by && (
              <ConsentFormSignedInfo
                userName={getUserFullName(consentItem.signed_by)}
                path={getRouteModalPathname('parent', consentItem.signed_by)}
                label={$t({
                  id: 'consentForms-by',
                })}
              />
            )}
          </GridCell>
        </>
      )}
    </TableRow>
  );
};

import {
  Backdrop,
  Box,
  ClickAwayListener,
  Icon,
  Stack,
  Tooltip,
  TooltipProps,
} from '@mui/material';
import { useFlag } from '@schooly/hooks/use-flag';
import { CalendarIcon, ChevronDownSmallIcon, ChevronUpIcon } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { FC, useEffect, useRef, useState } from 'react';

import { FiltersDropdownButton } from '../FiltersDropdownButton';
import { TodayButton } from './DatePicker/DatePickerCommonComponents';
import { DateSelectCalendarProps } from './DatePicker/DateSelectCalendar';
import { DateSelectContent, DateSelectContentRef } from './DatePicker/DateSelectContent';
import { DateSelectInput } from './DatePicker/DateSelectInput';
import { DateTagSelect } from './DateTagSelect';

type DateDropdownProps = {
  label: string;
  date: string;
  hideTodayButton?: boolean;
  disabled?: boolean;
  onSetDate: (v: Date) => void;
  onClear?: () => void;
} & Partial<TooltipProps> &
  Pick<DateSelectCalendarProps, 'DatePickerProps' | 'getDayStyleProps'>;

export const DateDropdown: FC<DateDropdownProps> = ({
  date: dateString,
  label,
  onSetDate,
  children,
  hideTodayButton,
  getDayStyleProps,
  DatePickerProps,
  onClear,
  disabled,
  ...tooltipProps
}) => {
  const dateContainer = useRef<DateSelectContentRef | null>(null);
  const [date, setDate] = useState(newDateTimezoneOffset(dateString));
  const [isOpened, setOpened, setClosed] = useFlag();

  useEffect(() => {
    setDate(newDateTimezoneOffset(dateString));
  }, [dateString]);

  const onSelectDateAndClose = (d: Date) => {
    setDate(d);
    onSetDate(d);
    setClosed();
  };

  const onSelectDate = (d: Date) => {
    setDate(d);
    dateContainer.current?.setVisibleMonthDate(d);
  };

  const onClose = () => {
    if (date) onSetDate(date);
    setClosed();
  };

  return (
    <>
      <Backdrop open={isOpened} invisible sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} />
      <ClickAwayListener onClickAway={onClose}>
        <div>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={onClose}
            open={isOpened}
            placement="bottom-start"
            componentsProps={{
              tooltip: {
                sx: (theme) => ({
                  width: 300,
                  maxWidth: 300,
                  borderRadius: theme.spacing(1),
                  border: `1px solid ${theme.palette.common.light3}`,
                  padding: 0,
                  overflow: 'hidden',
                  margin: `${theme.spacing(0.5, 0, 0)} !important`,
                }),
              },
            }}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <>
                <Stack
                  sx={(theme) => ({
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 1,
                    position: 'relative',
                    minHeight: theme.spacing(5),
                    padding: theme.spacing(0.5, 5, 0.5, 1.5),
                    borderBottom: `1px solid ${theme.palette.common.light3}`,
                    flexWrap: 'wrap',
                  })}
                >
                  <Icon sx={{ color: 'common.grey' }}>
                    <CalendarIcon />
                  </Icon>
                  <DateSelectInput value={date} onSetDate={onSelectDate} />
                  <Icon
                    sx={(theme) => ({
                      fontSize: theme.spacing(2),
                      cursor: 'pointer',
                      position: 'absolute',
                      right: theme.spacing(1.5),
                      top: theme.spacing(1.25),
                      color: theme.palette.common.grey,
                      '&:hover': {
                        color: theme.palette.text.primary,
                      },
                    })}
                    onClick={onClose}
                  >
                    <ChevronUpIcon />
                  </Icon>
                </Stack>
                <DateSelectContent
                  ref={dateContainer}
                  date={date}
                  onSetDate={onSelectDateAndClose}
                  getDayStyleProps={getDayStyleProps}
                  DatePickerProps={DatePickerProps}
                >
                  {children}
                  {!hideTodayButton && (
                    <TodayButton
                      onClick={() => {
                        const now = new Date();
                        dateContainer.current?.setVisibleMonthDate(now);
                        onSelectDateAndClose(now);
                      }}
                    />
                  )}
                </DateSelectContent>
              </>
            }
            {...tooltipProps}
          >
            <Box
              flexDirection="row"
              sx={{
                cursor: !disabled ? 'pointer' : undefined,
                position: 'relative',
                zIndex: (theme) => (isOpened ? theme.zIndex.drawer + 2 : undefined),
              }}
              alignItems="center"
              onClick={!disabled ? setOpened : undefined}
            >
              {!isOpened ? (
                <Stack flexDirection="row" gap={0.5} alignItems="center">
                  <DateTagSelect
                    key="date"
                    date={dateString}
                    onClick={setOpened}
                    disabled={disabled}
                  />
                  <ChevronDownSmallIcon color="black" />
                </Stack>
              ) : (
                <>
                  <FiltersDropdownButton onClear={onClear}>{label}</FiltersDropdownButton>
                </>
              )}
            </Box>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </>
  );
};

import { Avatar, AvatarProps, Tooltip } from '@mui/material';
import { useGetProfilePicture, WithAvatar, WithName } from '@schooly/api';
import { getUserInitials } from '@schooly/utils/user-helpers';
import React, { useMemo } from 'react';

export type AvatarAuthProps = {
  user: WithName & WithAvatar;
  withAvatarPreview?: boolean;
} & AvatarProps;

export const AvatarAuth: React.FC<AvatarAuthProps> = ({
  user,
  withAvatarPreview,
  ...avatarProps
}) => {
  const { isLoading, data } = useGetProfilePicture(user.profile_picture ?? '', {
    enabled: !!user.profile_picture,
  });

  const loading = isLoading && !!user.profile_picture;

  const displayedPicture = useMemo(() => {
    if (!loading && data !== undefined) {
      return data;
    }

    return user.profile_picture;
  }, [loading, data, user.profile_picture]);

  const initials = getUserInitials(user);

  const avatar = (
    <Avatar src={displayedPicture || undefined} {...avatarProps}>
      {initials}
    </Avatar>
  );

  if (withAvatarPreview) {
    const hasPicture = !loading && !!displayedPicture;

    return (
      <Tooltip
        disableInteractive
        componentsProps={{ tooltip: { sx: { p: 1.25 } } }}
        title={hasPicture && <Avatar sx={{ width: 180, height: 180 }} src={displayedPicture} />}
      >
        {avatar}
      </Tooltip>
    );
  }

  return avatar;
};

import { Box, Button, Dialog, IconButton, Stack, Typography } from '@mui/material';
import { RecurringRepeatBy } from '@schooly/api';
import {
  FormDayOfWeekSelect,
  FormNumberSelect,
  WeekDays,
} from '@schooly/components/form-text-field';
import { CrossIcon, ModalContent, ModalFooter, ModalHeader } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format, isAfter } from 'date-fns';
import { FC, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { useRecurringFormLabel } from './hooks';
import { RecurringEndDate, RecurringEndsSection } from './RecurringEndDate';
import { Preset } from './RecurringEndDateDropdown';
import { RecurringMonthSelect, RepeatOnMonth } from './RecurringMonthSelect';
import { RepeatBySelect } from './RepeatBySelect';

export type RecurringEnd = {
  section?: RecurringEndsSection;
  byOccurrence?: number;
  byDate?: string;
};

export type RecurringForm = {
  repeat_count: number;
  repeat_on_day_of_week: WeekDays[];
  period: RecurringRepeatBy;
  repeat_on_month?: RepeatOnMonth;
  ends?: RecurringEnd;
};

const MIN_COUNT = 1;
const MAX_COUNT = 9;

const POSSIBLE_NON_EXISTENT_DAYS = new Set([29, 30, 31]);

export type RecurringModalProps = {
  startDate: Date;
  endDate?: Date | null;
  entity: { name: string; namePlural: string };
  onClose: () => void;
  presets: Preset[];
  onSubmit: (f: RecurringForm) => void;
  title: string;
  recurringForm?: RecurringForm;
  loading?: boolean;
};

export const RecurringModal: FC<RecurringModalProps> = ({
  startDate,
  endDate,
  entity,
  onClose,
  presets,
  onSubmit,
  title,
  recurringForm,
  loading,
}) => {
  const { $t } = useIntl();

  const form = useForm<RecurringForm>({
    defaultValues: recurringForm
      ? recurringForm
      : {
          repeat_count: 1,
          repeat_on_day_of_week: [],
          period: RecurringRepeatBy.Week,
        },
  });

  const period = form.watch('period');
  const repeatCountValue = form.watch('repeat_count');
  const weekDays = form.watch('repeat_on_day_of_week');
  const repeatMonthly = form.watch('repeat_on_month');
  const endsByDate = form.watch('ends.byDate');
  const ends = form.watch('ends');
  const { isSubmitted } = form.formState;
  const repeatCount = isNaN(repeatCountValue) ? MIN_COUNT : repeatCountValue;

  const {
    dayOfMonthLabel,
    dayOfWeekLabel,
    dayOfWeekMenuLabel,
    dayOfMonthMenuLabel,
    daysOfWeekLabel,
  } = useRecurringFormLabel({
    startDate,
    repeat_count: repeatCount,
    repeat_on_day_of_week: weekDays,
    repeat_on_month: repeatMonthly,
    period,
    ends,
    title,
  });

  useEffect(() => {
    const subscription = form.watch((value, d) => {
      if (!isSubmitted) return;

      if (form.formState.errors['ends'] && !!value.ends?.section) {
        form.clearErrors('ends');
      }
    });
    return () => subscription.unsubscribe();
  }, [form, isSubmitted, startDate]);

  const isOverTheEndDate =
    endDate && endsByDate ? isAfter(newDateTimezoneOffset(endsByDate), endDate) : null;

  const resultString = useMemo(() => {
    const nameStr = Boolean(title) ? $t({ id: 'recurring-Name' }, { name: title }) : '';
    const repeatNameStr = `${$t({ id: 'recurring-Repeat' }).toLowerCase()} ${nameStr}`;

    if (period === RecurringRepeatBy.Week && !!weekDays.length) {
      const label = daysOfWeekLabel.charAt(0).toLowerCase() + daysOfWeekLabel.slice(1);

      return `${repeatNameStr} ${label}`;
    }

    if (period === RecurringRepeatBy.Month) {
      return repeatMonthly === RepeatOnMonth.ByDayOfMonth
        ? `${repeatNameStr} ${dayOfMonthLabel.charAt(0).toLowerCase() + dayOfMonthLabel.slice(1)}`
        : `${repeatNameStr}  ${dayOfWeekLabel.charAt(0).toLowerCase() + dayOfWeekLabel.slice(1)}`;
    }

    return '';
  }, [
    period,
    title,
    $t,
    weekDays.length,
    daysOfWeekLabel,
    dayOfMonthLabel,
    repeatMonthly,
    dayOfWeekLabel,
  ]);

  return (
    <Dialog
      fullWidth
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        zIndex: theme.zIndex.modal + 1,
        '& .MuiDialog-paperFullWidth': {
          width: 600,
          height: 'unset',
          overflow: 'visible',
        },
      })}
      open
    >
      <FormProvider {...form}>
        <form
          onSubmit={(e) => {
            e.stopPropagation();
            form.handleSubmit(onSubmit)(e);
          }}
        >
          <ModalHeader
            active
            title={$t({ id: 'recurring-CustomTitle' })}
            sx={{
              borderRadius: '8px 8px 0 0',
            }}
          >
            <IconButton onClick={onClose}>
              <CrossIcon />
            </IconButton>
          </ModalHeader>

          <ModalContent active overflow="inherit">
            <Stack gap={3}>
              <Stack flexDirection="row" alignItems="center" gap={2} pt={0.25}>
                <RecurringTitle title={$t({ id: 'recurring-RepeatEverySection' })} />
                <Stack direction="row" gap={1.25} flex={1}>
                  <Box
                    sx={{
                      '.MuiFormControl-root': {
                        minWidth: 44,
                      },
                    }}
                  >
                    <FormNumberSelect name="repeat_count" min={MIN_COUNT} max={MAX_COUNT} />
                  </Box>

                  <Box
                    flex={1}
                    sx={{
                      '.form-select-search': {
                        maxHeight: 44,
                      },
                    }}
                  >
                    <RepeatBySelect name="period" />
                  </Box>
                </Stack>
              </Stack>
              <Stack flexDirection="row" alignItems="center" gap={2}>
                <RecurringTitle title={$t({ id: 'recurring-RepeatOnSection' })} />
                {period === RecurringRepeatBy.Week ? (
                  <FormDayOfWeekSelect
                    name="repeat_on_day_of_week"
                    required={period === RecurringRepeatBy.Week}
                  />
                ) : (
                  <RecurringMonthSelect
                    dayOfMonthLabel={dayOfMonthMenuLabel}
                    dayOfWeekLabel={dayOfWeekMenuLabel}
                    name="repeat_on_month"
                  />
                )}
              </Stack>

              <Stack flexDirection="row">
                <RecurringTitle title={$t({ id: 'recurring-EndsSection' })} />
                <RecurringEndDate
                  presets={presets}
                  required
                  endDateHelperText={
                    isOverTheEndDate ? $t({ id: 'recurring-BeyondSchoolYear' }) : undefined
                  }
                  repeatCount={repeatCount}
                  startDate={startDate}
                  entity={entity}
                  occurrenceHelperText={
                    POSSIBLE_NON_EXISTENT_DAYS.has(startDate.getDate())
                      ? $t(
                          { id: 'recurring-OccurrenceRepeatHelperText' },
                          { day: format(startDate, 'do') },
                        )
                      : undefined
                  }
                />
              </Stack>
            </Stack>
            {resultString && (
              <Stack mt={3}>
                <Typography
                  color="text.primary"
                  variant="h3"
                >{`Result: ${resultString}`}</Typography>
              </Stack>
            )}
          </ModalContent>
          <ModalFooter active sx={{ borderRadius: '0 0 8px 8px' }}>
            <Button onClick={onClose} variant="outlined" disabled={Boolean(loading)}>
              <FormattedMessage id="action-Cancel" />
            </Button>
            <Button type="submit" disabled={Boolean(loading)}>
              <FormattedMessage id="action-Save" />
            </Button>
          </ModalFooter>
        </form>
      </FormProvider>
    </Dialog>
  );
};

type RecurringTitleProps = {
  title: string;
};

export const RecurringTitle: FC<RecurringTitleProps> = ({ title }) => {
  return (
    <Stack flex={'0 0 125px'}>
      <Typography variant="h2" color="common.grey2" pr={1}>
        {title}
      </Typography>
    </Stack>
  );
};

import { Checkbox, Stack, Typography } from '@mui/material';
import { ParentSearchResult } from '@schooly/api';
import { CreateParentForm } from '@schooly/components/applications';
import { AvatarAuth } from '@schooly/components/avatar-auth';
import { CombinedRow } from '@schooly/components/filters';
import { GridCell, GridCellContent, TypographyWithOverflowHint } from '@schooly/style';
import { getUserFullName } from '@schooly/utils/get-user-full-name';
import React, { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { Table } from '../../../../components/uikit-components/Table/Table';
import { TableCell } from '../../../../components/uikit-components/Table/Table';
import { LEFT_COL_WIDTH, RIGHT_COL_WIDTH } from './AddParentModal';

interface ParentsListProps {
  entries: CombinedRow<ParentSearchResult>[];
  addAdultToSelected: (adult: ParentSearchResult) => void;
  selectedAdultsIds: string[];
  setSelectedAdults: (value: React.SetStateAction<CreateParentForm[]>) => void;
}

export const ParentsList: FC<ParentsListProps> = ({
  entries,
  addAdultToSelected,
  selectedAdultsIds,
  setSelectedAdults,
}) => {
  const columns: TableCell[] = useMemo(
    () => [
      {
        id: 'name',
        renderContent: () => (
          <GridCell
            sticky
            noHorizontalPadding
            sx={{
              width: LEFT_COL_WIDTH,
              position: 'relative',
            }}
            pt={0}
            pb={1}
          >
            <GridCellContent>
              <FormattedMessage id="people-Name" />
            </GridCellContent>
          </GridCell>
        ),
      },
      {
        id: 'students',
        renderContent: () => (
          <GridCell noHorizontalPadding sticky pt={0} pb={1} sx={{ position: 'relative' }}>
            <GridCellContent>
              <FormattedMessage id="userType-student-plural" />
            </GridCellContent>
          </GridCell>
        ),
      },
      {
        id: 'checkbox',
        renderContent: () => (
          <GridCell
            sticky
            noHorizontalPadding
            pt={0}
            pb={1}
            sx={{ width: RIGHT_COL_WIDTH, position: 'relative' }}
          />
        ),
      },
    ],
    [],
  );

  const rows = useMemo(() => {
    return entries.map((parent) => {
      const cellArray: TableCell[] = [
        {
          id: 'name',
          renderContent: () => {
            const name = getUserFullName(parent.item);
            return (
              <GridCell
                sx={{
                  position: 'relative',
                  maxWidth: LEFT_COL_WIDTH,
                }}
                pl={6}
              >
                <AvatarAuth
                  withAvatarPreview
                  user={parent.item}
                  sx={(theme) => ({
                    position: 'absolute',
                    left: theme.spacing(1),
                    marginTop: theme.spacing(-0.5),
                  })}
                />

                <TypographyWithOverflowHint variant="h3" color="common.grey2" noWrap>
                  {name}
                </TypographyWithOverflowHint>
              </GridCell>
            );
          },
        },
        {
          id: 'students',
          renderContent: () => (
            <GridCell noPadding sx={{ maxWidth: 250 }}>
              <Typography
                noWrap
                sx={{
                  a: {
                    color: 'common.grey2',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  },
                }}
              >
                {[parent.item.student, ...parent.additionalRows.map((r) => r.item.student)].map(
                  (s, i) => (
                    <>
                      <Link key={s.relation_id + i} to={`/students/${s.relation_id}`}>
                        {getUserFullName(s)}
                      </Link>
                      {i !== parent.additionalRows.length ? ', ' : ''}
                    </>
                  ),
                )}
              </Typography>
            </GridCell>
          ),
        },
        {
          id: 'checkbox',
          renderContent: () => (
            <GridCell sx={{ justifyContent: 'center', position: 'relative' }} noVerticalPadding>
              <Stack alignItems={'center'}>
                <Checkbox
                  checked={selectedAdultsIds.includes(parent.item.user_id)}
                  sx={{
                    visibility: 'hidden',
                    '&.Mui-checked': {
                      visibility: 'visible',
                    },
                  }}
                  onChange={() => {
                    if (!selectedAdultsIds.includes(parent.item.user_id)) {
                      addAdultToSelected(parent.item);
                    } else {
                      setSelectedAdults((prev) =>
                        prev.filter((p) => p.profile_id !== parent.item.user_id),
                      );
                    }
                  }}
                />
              </Stack>
            </GridCell>
          ),
        },
      ];
      return {
        id: parent.id,
        cells: cellArray,
      };
    });
  }, [addAdultToSelected, entries, selectedAdultsIds, setSelectedAdults]);

  return <Table rows={rows} columns={columns} withDefaultHover />;
};

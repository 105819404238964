import { IconButton, Stack, Typography } from '@mui/material';
import { MAX_PAGE_SIZE } from '@schooly/api';
import { CrossIcon, EditIcon, Loading, ModalHeader, ModalLarge } from '@schooly/style';
import { getUserFullName } from '@schooly/utils/get-user-full-name';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';

import AccessDenied from '../../../components/common/AccessDenied';
import { CollapsiblePanel } from '../../../components/common/CollapsiblePanel';
import { ModalContent, ModalMain } from '../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeaderV2 } from '../../../components/uikit-components/Modal/ModalHeaderV2';
import { useMessage } from '../../../context/messages/useMessage';
import { isOnlyGroupAndDateFilled } from '../../../helpers/messages';
import useAppLocation from '../../../hooks/useAppLocation';
import useQueryStringParams from '../../../hooks/useQueryStringParams';
import { useSchool } from '../../../hooks/useSchool';
import { MessagesDeleteGroupModal } from '../MessagesCreateModal/confirmations/MessagesDeleteGroupModal';
import { MessageModalPreviewActions } from './MessageModalPreviewActions';
import { MessageModalPreviewMessage } from './MessageModalPreviewMessage';
import { MessageModalPreviewMode, MessagePreviewModalSideBar } from './MessagePreviewModalSideBar';
import { MessagesModalPreviewConsentForm } from './MessagesModalPreviewConsentForm';
import { MessagesModalPreviewRecipients } from './MessagesModalPreviewRecipients/MessagesModalPreviewRecipients';
import { MessagesModalPreviewSendTo } from './MessagesModalPreviewSendTo/MessagesModalPreviewSendTo';

export const MessagesPreviewModal: React.FC = () => {
  const {
    messageId,
    canView,
    title,
    fetching,
    canEdit,
    actions,
    creator,
    publisher,
    messageData,
    form,
    messageFormFields,
    criteriaDate,
    isRemoveGroupDialogOpen,
    notActualGroups,
    submitAction,
  } = useMessage();

  const { schoolId } = useSchool();
  const navigate = useNavigate();
  const location = useAppLocation();
  const { tab } = useQueryStringParams();

  const allGroupsInvalid = useMemo(
    () =>
      notActualGroups.length ===
      new Set([
        ...(messageFormFields?.parents_of_students_criteria.group || []),
        ...(messageFormFields?.staff_criteria.group || []),
      ]).size,
    [notActualGroups, messageFormFields],
  );

  const onlyGroupsSelected = useMemo(() => {
    const parentsValid =
      messageFormFields?.parents_of_students_criteria &&
      isOnlyGroupAndDateFilled(messageFormFields?.parents_of_students_criteria);

    const staffValid =
      messageFormFields?.staff_criteria &&
      isOnlyGroupAndDateFilled(messageFormFields?.staff_criteria);

    return parentsValid && staffValid;
  }, [messageFormFields]);

  const handleEditButtonClick = useCallback(() => {
    form.reset(messageFormFields);
    navigate(`/messages/${messageId}/edit`);
  }, [navigate, messageId, form, messageFormFields]);

  useEffect(() => {
    if (!tab) {
      navigate(
        {
          search: `?tab=${MessageModalPreviewMode.MESSAGE}`,
        },
        { replace: true, state: location.state },
      );
    }
  });

  const groupsChecked = useRef(false);

  useEffect(() => {
    if (!groupsChecked.current && !fetching) {
      groupsChecked.current = true;

      actions.checkInvalidGroups(
        {
          schoolId: schoolId || '',
          singleDate: criteriaDate,
          pageSize: MAX_PAGE_SIZE,
          min: 1,
        },
        true,
      );
    }
  }, [actions, schoolId, criteriaDate, fetching]);

  const showEditButton = !fetching && canEdit;

  const renderContent = () => {
    if (fetching) {
      return <Loading />;
    }

    if (!canView || !messageData) {
      return (
        <>
          <ModalHeader withBorderBottom={false} active>
            <IconButton onClick={actions.handleClose}>
              <CrossIcon />
            </IconButton>
          </ModalHeader>
          <AccessDenied />
        </>
      );
    }

    return (
      <>
        <ModalHeaderV2
          withGoBack
          active
          title={title}
          subHeader={
            <Stack direction="row" gap={5}>
              {creator && (
                <Stack direction="row" gap={0.5}>
                  <Typography>
                    <FormattedMessage id="messages-From" />
                  </Typography>
                  <Typography>{getUserFullName(creator)}</Typography>
                </Stack>
              )}
              {publisher && (
                <Stack direction="row" gap={0.5}>
                  <Typography>
                    <FormattedMessage id="messages-Publisher" />
                  </Typography>
                  <Typography>{getUserFullName(publisher)}</Typography>
                </Stack>
              )}
            </Stack>
          }
        >
          <MessageModalPreviewActions />
          {showEditButton && (
            <IconButton onClick={handleEditButtonClick}>
              <EditIcon />
            </IconButton>
          )}

          <IconButton onClick={actions.handleClose}>
            <CrossIcon />
          </IconButton>
        </ModalHeaderV2>

        <ModalMain>
          <CollapsiblePanel>
            <MessagePreviewModalSideBar />
          </CollapsiblePanel>

          <ModalContent
            sx={(theme) => ({
              pt: theme.spacing(2.5),
            })}
          >
            {tab === MessageModalPreviewMode.MESSAGE && <MessageModalPreviewMessage />}
            {tab === MessageModalPreviewMode.SEND_TO && <MessagesModalPreviewSendTo />}
            {tab === MessageModalPreviewMode.RECIPIENTS && <MessagesModalPreviewRecipients />}
            {tab === MessageModalPreviewMode.CONSENT_FORM && <MessagesModalPreviewConsentForm />}

            <MessagesDeleteGroupModal
              isOpen={isRemoveGroupDialogOpen}
              invalidGroups={notActualGroups}
              allInvalid={allGroupsInvalid && onlyGroupsSelected}
              onClose={actions.handleCloseRemoveGroupDialog}
              onConfirm={() => actions.handleConfirmRemoveGroupDialog(submitAction)}
              onEdit={actions.handleEditRemoveGroupDialog}
            />
          </ModalContent>
        </ModalMain>
      </>
    );
  };

  return (
    <ModalLarge open onClose={actions.handleClose}>
      {renderContent()}
    </ModalLarge>
  );
};

import { IconButton, Link, Stack, SxProps } from '@mui/material';
import { DuplicateUser, UserType } from '@schooly/api';
import { AvatarAuth } from '@schooly/components/avatar-auth';
import { NewTabIcon, TypographyWithOverflowHint } from '@schooly/style';
import { getUserFullName } from '@schooly/utils/get-user-full-name';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { getRouteModalPathname } from 'apps/web/src/helpers/misc';
import { FC, ReactNode } from 'react';

interface DuplicatesRowProps {
  duplicate: DuplicateUser;
  endAction: ReactNode;
  userType: UserType;
  sxProps?: Omit<SxProps, 'children'>;
}

export const DuplicatesRow: FC<DuplicatesRowProps> = ({
  duplicate,
  endAction,
  userType,
  sxProps,
}) => {
  const name = getUserFullName(duplicate);
  const path = getRouteModalPathname(userType, duplicate);

  return (
    <Stack direction="row" gap={1} justifyContent="space-between">
      <Link
        href={path}
        target="_blank"
        sx={{ flex: 1, overflowY: 'hidden', '&:hover': { textDecoration: 'none' } }}
      >
        <Stack
          gap={1}
          p={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={(theme) => ({
            backgroundColor: theme.palette.background.paper,
            borderRadius: theme.spacing(1),
            height: 44,
            ...(sxProps ?? {}),
          })}
        >
          <Stack direction="row" gap={1} alignItems="center" sx={{ overflowY: 'hidden' }}>
            <AvatarAuth user={duplicate} withAvatarPreview />
            <TypographyWithOverflowHint variant="h3" color="text.primary" noWrap>
              {name}
            </TypographyWithOverflowHint>
          </Stack>
          <IconButton size="small" inverse>
            <NewTabIcon />
          </IconButton>
        </Stack>
      </Link>
      {endAction}
    </Stack>
  );
};

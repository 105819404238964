import {
  Button,
  Icon,
  IconButton,
  Stack,
  SxProps,
  TableHeadProps,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import {
  Company,
  GetCompaniesSort,
  SORT_DIRECTION,
  StudentForCompany,
  useGetStudentsForCompanyListQuery,
} from '@schooly/api';
import { AvatarAuth } from '@schooly/components/avatar-auth';
import { useFlag } from '@schooly/hooks/use-flag';
import {
  ArrowAngleIcon,
  DropdownIcon,
  EyeIcon,
  GridCell,
  GridHead,
  SkeletonRows,
  SortableCell,
  theme,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { getUserAddress, getUserFullName } from '@schooly/utils/user-helpers';
import { FC, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import useAppLocation from '../../hooks/useAppLocation';
import { PAGE_SIZE, SKELETON_COLUMNS } from './CompaniesContent';
import { CompanyContactsTooltip } from './CompanyContactsTooltip';
import { CompanyNote } from './CompanyNote';

interface CompaniesHeaderProps extends TableHeadProps {
  sort?: GetCompaniesSort;
  onChangeSort: (v: GetCompaniesSort) => void;
}

interface IHoverProps {
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  sx: SxProps<Theme>;
}

export const CompaniesHeader: FC<CompaniesHeaderProps> = ({ sort, onChangeSort, ...rest }) => {
  const { $t } = useIntl();

  const handleSort = (columnTextId: GetCompaniesSort['columnTextId']) => () => {
    onChangeSort({
      columnTextId,
      direction:
        columnTextId === sort?.columnTextId
          ? sort.direction === SORT_DIRECTION.ASC
            ? SORT_DIRECTION.DESC
            : SORT_DIRECTION.ASC
          : SORT_DIRECTION.ASC,
    });
  };

  return (
    <GridHead borderBottom {...rest}>
      <SortableCell
        label={$t({ id: 'companies-Name' })}
        sort={sort}
        columnTextId="name"
        onChangeSort={handleSort}
        sx={(theme) => ({ '.MuiStack-root': { paddingX: theme.spacing(1.5) } })}
      />
      <GridCell
        width="175px"
        sx={(theme) => ({
          [theme.breakpoints.only('lg')]: {
            width: 150,
          },
          [theme.breakpoints.down('lg')]: {
            width: 100,
          },
        })}
      >
        {$t({ id: 'companies-TaxNumber' })}
      </GridCell>
      <GridCell
        width="175px"
        sx={(theme) => ({
          [theme.breakpoints.only('lg')]: {
            width: 150,
          },
          [theme.breakpoints.down('lg')]: {
            width: 100,
          },
        })}
      >
        {$t({ id: 'companies-Address' })}
      </GridCell>
      <GridCell
        width="175px"
        sx={(theme) => ({
          [theme.breakpoints.only('lg')]: {
            width: 150,
          },
          [theme.breakpoints.down('lg')]: {
            width: 100,
          },
        })}
      >
        {$t({ id: 'companies-BankAccount' })}
      </GridCell>
      <GridCell
        width="175px"
        sx={(theme) => ({
          [theme.breakpoints.only('lg')]: {
            width: 150,
          },
          [theme.breakpoints.down('lg')]: {
            width: 100,
          },
        })}
      >
        {$t({ id: 'companies-ContactPerson' })}
      </GridCell>
      <SortableCell
        label={$t({ id: 'companies-Students' })}
        sort={sort}
        columnTextId="student_count"
        width="94px"
        onChangeSort={handleSort}
      />
      <GridCell width="40px" />
      <GridCell width="40px" />
    </GridHead>
  );
};

interface CompanyRowProps {
  company: Company;
}

export const CompanyRow: FC<CompanyRowProps> = ({ company }) => {
  const [isExpanded, expand, collapse] = useFlag();
  const [rowHovered, setRowHovered] = useState(false);

  const {
    name,
    id,
    tax_number,
    bank_account,
    contact_name,
    student_count,
    note,
    telephone,
    email,
  } = company;

  const hoverProps: IHoverProps = {
    onMouseEnter: () => setRowHovered(true),
    onMouseLeave: () => setRowHovered(false),
    sx: (theme: Theme) => ({
      ...(rowHovered && {
        'td.MuiTableCell-root': {
          backgroundColor: theme.palette.background.default,
          '.MuiTypography-root': {
            color: theme.palette.primary.main,
          },
        },
      }),
    }),
  };

  const borderBottom = isExpanded ? false : undefined;

  return (
    <>
      <TableRow component={Link} to={`/companies/${id}`} {...hoverProps}>
        <GridCell borderBottom={borderBottom} pl={2.5}>
          <TypographyWithOverflowHint variant="h3" color="text.primary">
            {name}
          </TypographyWithOverflowHint>
        </GridCell>

        <GridCell borderBottom={borderBottom}>
          <TypographyWithOverflowHint color="text.primary">{tax_number}</TypographyWithOverflowHint>
        </GridCell>

        <GridCell borderBottom={borderBottom}>
          <TypographyWithOverflowHint color="text.primary">
            {getUserAddress(company)}
          </TypographyWithOverflowHint>
        </GridCell>

        <GridCell borderBottom={borderBottom}>
          <TypographyWithOverflowHint color="text.primary">
            {bank_account}
          </TypographyWithOverflowHint>
        </GridCell>

        <GridCell borderBottom={borderBottom}>
          <TypographyWithOverflowHint color="text.primary">
            <CompanyContactsTooltip items={[{ text: telephone }, { text: email }]}>
              {contact_name}
            </CompanyContactsTooltip>
          </TypographyWithOverflowHint>
        </GridCell>

        <GridCell borderBottom={borderBottom}>
          <Typography color="text.primary">{student_count || '-'}</Typography>
        </GridCell>

        <GridCell
          borderBottom={borderBottom}
          onClick={(e) => {
            e.preventDefault();
            isExpanded ? collapse() : expand();
          }}
        >
          {!!student_count && (
            <IconButton
              inverse
              sx={(theme) => ({
                mr: 1.5,
                transform: isExpanded ? 'rotate(180deg)' : undefined,
                color: rowHovered ? theme.palette.common.grey2 : undefined,
              })}
            >
              <DropdownIcon />
            </IconButton>
          )}
        </GridCell>

        <GridCell noHorizontalPadding noVerticalPadding borderBottom={borderBottom}>
          {!!note && (
            <CompanyNote
              text={note}
              sx={{
                '& .MuiIcon-root': {
                  color: rowHovered ? theme.palette.primary.main : theme.palette.common.grey,
                },
              }}
            />
          )}
        </GridCell>
      </TableRow>
      {isExpanded && (
        <CompanyStudentsList
          companyId={id}
          hoverProps={hoverProps}
          hovered={rowHovered}
          studentsCount={student_count}
        />
      )}
    </>
  );
};

interface CompanyStudentsListProps {
  companyId: Company['id'];
  hoverProps: IHoverProps;
  hovered: boolean;
  studentsCount: number;
}

export const CompanyStudentsList: FC<CompanyStudentsListProps> = ({
  companyId,
  hoverProps,
  hovered,
  studentsCount,
}) => {
  const location = useAppLocation();
  const { $t } = useIntl();

  const { isLoading, hasNextPage, fetchNextPage, isFetchingNextPage, data } =
    useGetStudentsForCompanyListQuery(
      {
        query: '',
        companyId,
      },
      { enabled: !!companyId, refetchOnMount: 'always' },
    );

  const entries = useMemo(
    () =>
      data?.pages.reduce<StudentForCompany[]>((prev, curr) => [...prev, ...curr.results], []) ?? [],

    [data?.pages],
  );

  return (
    <>
      {entries.map((entry, index, students) => {
        const isLastStudent = index === students.length - 1;
        const name = getUserFullName(entry.relation);
        const productNames = entry.products?.map((product) => product.name).join(', ');

        return (
          <TableRow
            key={entry.relation.id}
            component={Link}
            to={`/companies/${companyId}`}
            state={{ backgroundLocation: location }}
            {...hoverProps}
          >
            <GridCell
              p={0}
              pl={2.5}
              borderBottom={!hasNextPage && isLastStudent ? undefined : false}
            >
              <Stack direction="row" alignItems="center" gap={1.5}>
                <Icon
                  sx={(theme) => ({
                    color: hovered ? theme.palette.common.grey2 : theme.palette.common.grey,
                  })}
                >
                  <ArrowAngleIcon />
                </Icon>
                <Stack
                  width="100%"
                  direction="row"
                  alignItems="center"
                  gap={2}
                  py={1}
                  sx={(theme) => ({
                    overflow: 'hidden',
                    borderBottom: isLastStudent ? 'none' : `1px solid ${theme.palette.divider}`,
                    '.MuiTableRow-root:last-of-type &': {
                      borderBottom: 'none',
                    },
                  })}
                >
                  <AvatarAuth user={entry.relation} withAvatarPreview />
                  <TypographyWithOverflowHint variant="h3" color="text.primary" noWrap>
                    {name}
                  </TypographyWithOverflowHint>
                </Stack>
              </Stack>
            </GridCell>

            <GridCell
              colSpan={6}
              py={1.25}
              borderBottom={hasNextPage && isLastStudent ? false : undefined}
            >
              <TypographyWithOverflowHint color="text.primary" noWrap>
                {productNames}
              </TypographyWithOverflowHint>
            </GridCell>

            <GridCell py={1.25} borderBottom={!hasNextPage && isLastStudent ? undefined : false} />
          </TableRow>
        );
      })}

      {(isLoading || isFetchingNextPage) && (
        <SkeletonRows
          columnsCount={SKELETON_COLUMNS}
          amount={Math.min(PAGE_SIZE, studentsCount - (entries?.length || 0))}
        />
      )}

      {hasNextPage && !isFetchingNextPage && (
        <TableRow {...hoverProps}>
          <GridCell noHorizontalPadding noVerticalPadding colSpan={8}>
            <Stack p={1} alignItems="center">
              <Button startIcon={<EyeIcon />} variant="text" onClick={() => fetchNextPage()}>
                {$t({ id: 'action-ShowMoreButton' })}
              </Button>
            </Stack>
          </GridCell>
        </TableRow>
      )}
    </>
  );
};

import { IconButton, Stack } from '@mui/material';
import {
  DEFAULT_DATE_FORMAT_FNS,
  FilterKeys,
  GetPayableFeesArrangeBy,
  GetPayableFeesQueryFilters,
  GetPayableFeesQuerySort,
  INVOICES_REPORTING_SORT_OPTIONS,
  InvoicesReportingAggregatedSortOption,
  PAYABLE_FEES_ARRANGE_BY_FILTER_KEYS,
  PAYABLE_FEES_QUERY_FILTER_KEYS,
  ProductTypeId,
  SORT_DIRECTION,
  useGetInvoicesReportingAggregatedQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  PayableFeesChart,
  PayableFeesChartsCustomGrid,
  useCharts,
} from '@schooly/components/charts';
import {
  ArrangeByDropdown,
  ArrangedByCollapsableSectionSkeleton,
  PageHeader,
  PageHeaderSearchInput,
  StoredFilterSections,
  useArrangeByFromSearchParams,
  useFiltersStateFromSearchParams,
  useLastAppliedFiltersState,
  useSaveLastAppliedFiltersState,
  useSyncFiltersStateWithSearchParams,
} from '@schooly/components/filters';
import { MainGridNoResultsStub } from '@schooly/components/stubs';
import { usePrevious } from '@schooly/hooks/use-previous';
import { ArrowDownIcon, ArrowUpIcon, ChartIcon } from '@schooly/style';
import { format } from 'date-fns';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import useSchoolYears from '../../hooks/useSchoolYears';
import { ArrangedByPayableFeesGrid } from './PayableFeesArrangedByList';
import { PayableFeesCollapsableSection } from './PayableFeesCollapsableSection';
import { PayableFeesFilters } from './PayableFeesFilters';

type PayableFeesContentProps = {
  initialFilters?: GetPayableFeesQueryFilters;
  initialArrangeBy: GetPayableFeesArrangeBy;
};

export type PayableFeesArrangeByCustomGrid = {
  arrangeBy: GetPayableFeesArrangeBy;
  grid: PayableFeesChartsCustomGrid<GetPayableFeesQueryFilters> | null;
};

export const PAGE_SIZE = 30;

export const PayableFeesContent: FC<PayableFeesContentProps> = ({
  initialArrangeBy,
  initialFilters,
}) => {
  const { schoolId = '' } = useAuth();
  const { formatMessage } = useIntl();
  const { defaultValidity } = useSchoolYears();
  const { onToggleChartsOpened, isChartsOpened, showZeroValues } = useCharts();
  const payableFeesFiltersRef = useRef<PayableFeesFilters | null>(null);

  const [payableFeesQuerySort, setPayableFeesQuerySort] = useState<GetPayableFeesQuerySort>({
    by: 'status',
    direction: SORT_DIRECTION.DESC,
  });

  const { lastAppliedFilter, lastAppliedArrangeBy } = useLastAppliedFiltersState({
    type: StoredFilterSections.PayableFees,
    filterKeys: PAYABLE_FEES_QUERY_FILTER_KEYS,
    arrangeByKeys: PAYABLE_FEES_ARRANGE_BY_FILTER_KEYS,
    schoolId,
  });

  const arrangeByFromSearchParams = useArrangeByFromSearchParams(
    PAYABLE_FEES_ARRANGE_BY_FILTER_KEYS,
  );

  const [arrangeByWithCustomGrid, setArrangeByCustomGrid] =
    useState<PayableFeesArrangeByCustomGrid>({
      arrangeBy: lastAppliedArrangeBy || arrangeByFromSearchParams || initialArrangeBy,
      grid: null,
    });

  const prevArrangeByWithCustomGrid = usePrevious(arrangeByWithCustomGrid);

  useEffect(() => {
    if (!prevArrangeByWithCustomGrid && arrangeByWithCustomGrid && !isChartsOpened) {
      onToggleChartsOpened();
    }
  }, [arrangeByWithCustomGrid, isChartsOpened, onToggleChartsOpened, prevArrangeByWithCustomGrid]);

  const defaultFilters: GetPayableFeesQueryFilters = useMemo(
    () => ({
      [FilterKeys.Date]: [
        defaultValidity?.start || format(new Date(), DEFAULT_DATE_FORMAT_FNS),
        defaultValidity?.end || format(new Date(), DEFAULT_DATE_FORMAT_FNS),
      ],
    }),
    [defaultValidity],
  );

  const initialFiltersState = useFiltersStateFromSearchParams({
    filterKeys: PAYABLE_FEES_QUERY_FILTER_KEYS,
    defaultFilters,
    initialFilters,
  });

  const defaultUserFilters = useMemo(() => {
    return { ...defaultFilters, ...initialFilters };
  }, [defaultFilters, initialFilters]);

  const { data, isLoading, params, setParams } = useGetInvoicesReportingAggregatedQuery(
    {
      schoolId,
      filters: lastAppliedFilter
        ? {
            ...lastAppliedFilter,
            product_type_ids: lastAppliedFilter?.product_type_ids?.map(
              (t: ProductTypeId) => new ProductTypeId(t.typeId, t.productId),
            ),
          }
        : initialFiltersState,
      arrangeBy: arrangeByWithCustomGrid.arrangeBy,
      sort: {
        by: FilterKeys.Date,
        direction: SORT_DIRECTION.ASC,
      },
    },
    { refetchOnMount: 'always' },
  );

  const handleSetFiltersSearch = useCallback(
    (search: string) => {
      setParams((p) => ({ ...p, filters: { ...p.filters, search } }));
    },
    [setParams],
  );

  const handleSetCustomGrid = useCallback(
    (grid: PayableFeesChartsCustomGrid<GetPayableFeesQueryFilters> | null) => {
      setArrangeByCustomGrid((oldGrid) => {
        return {
          ...oldGrid,
          grid,
        };
      });
    },
    [],
  );

  const handleSetCustomGridTotalCount = useCallback((totalCount: number) => {
    setArrangeByCustomGrid((oldGrid) => {
      if (!oldGrid.grid) return oldGrid;

      return {
        ...oldGrid,
        grid: {
          ...oldGrid.grid,
          totalCount,
        },
      };
    });
  }, []);

  const handleSetArrangeBy = useCallback(
    (arrangeBy: GetPayableFeesArrangeBy) => {
      setArrangeByCustomGrid({ arrangeBy, grid: null });
      setParams((p) => ({ ...p, arrangeBy }));
    },
    [setParams],
  );

  useSyncFiltersStateWithSearchParams({
    pathname: '/payablefees',
    filters: params.filters,
    arrangeBy: arrangeByWithCustomGrid ? arrangeByWithCustomGrid.arrangeBy : null,
    charts: isChartsOpened,
    zeroes: showZeroValues,
  });

  useSaveLastAppliedFiltersState({
    type: StoredFilterSections.PayableFees,
    filters: params.filters,
    arrangeBy: arrangeByWithCustomGrid ? arrangeByWithCustomGrid.arrangeBy : null,
    schoolId: schoolId || '',
  });

  const handleSetFilters = useCallback(
    (filters: GetPayableFeesQueryFilters) => {
      setParams((p) => ({ ...p, filters }));
    },
    [setParams],
  );

  const handleChangeSortOption = useCallback(
    (option: InvoicesReportingAggregatedSortOption) => {
      setParams((p) => ({ ...p, sort: { ...p.sort, by: option } }));
    },
    [setParams],
  );
  const handleToggleSortDirection = useCallback(() => {
    setParams((p) => ({
      ...p,
      sort: {
        ...p.sort,
        direction:
          p.sort.direction === SORT_DIRECTION.ASC ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC,
      },
    }));
  }, [setParams]);

  const noResults = !isLoading && !data?.chartData?.length;
  const showCharts = isChartsOpened && !noResults;

  const renderGrid = () => {
    if (arrangeByWithCustomGrid?.grid) {
      return (
        <>
          <PayableFeesCollapsableSection
            currency={arrangeByWithCustomGrid.grid.currency}
            arrangeByKey={params.arrangeBy}
            isExpanded
            row={arrangeByWithCustomGrid.grid.row}
            totalCountOverride={arrangeByWithCustomGrid.grid.totalCount}
          >
            <ArrangedByPayableFeesGrid
              arrangeByKey={params.arrangeBy}
              arrangeByValue={arrangeByWithCustomGrid.grid.row.value}
              count={arrangeByWithCustomGrid.grid.row.count}
              sort={payableFeesQuerySort}
              filters={arrangeByWithCustomGrid.grid.filters}
              schoolId={schoolId}
              onChangeSort={setPayableFeesQuerySort}
              onUpdateTotalCount={handleSetCustomGridTotalCount}
            />
          </PayableFeesCollapsableSection>
        </>
      );
    }

    if (!data || isLoading) return <ArrangedByCollapsableSectionSkeleton />;
    if (!data.rows.length) return <MainGridNoResultsStub textId="payableFees-NoResults-title" />;

    return (
      <>
        {data.rows.map((row) => {
          return (
            <PayableFeesCollapsableSection
              key={row.name}
              currency={data.currency}
              arrangeByKey={params.arrangeBy}
              isExpanded={data.rows.length === 1}
              row={row}
            >
              <ArrangedByPayableFeesGrid
                count={row.count}
                sort={payableFeesQuerySort}
                filters={params.filters}
                arrangeByKey={params.arrangeBy}
                arrangeByValue={row.value}
                schoolId={schoolId}
                onChangeSort={setPayableFeesQuerySort}
              />
            </PayableFeesCollapsableSection>
          );
        })}
      </>
    );
  };

  return (
    <>
      <Stack gap={1}>
        <PageHeader pageTitleTextId="section-payableFees" actionsContent={<Stack width={200} />}>
          <PageHeaderSearchInput
            value={params.filters.search || ''}
            onChangeText={handleSetFiltersSearch}
            placeholder={formatMessage({ id: 'searchPlaceholder' })}
          />
        </PageHeader>
        <PayableFeesFilters
          arrangeBy={arrangeByWithCustomGrid.arrangeBy}
          onSetArrangeBy={handleSetArrangeBy}
          defaultFilters={defaultFilters}
          onSetFilters={handleSetFilters}
          filters={params.filters}
          schoolId={schoolId || ''}
          ref={payableFeesFiltersRef}
          defaultSchoolYear={defaultValidity}
          defaultUserFilters={defaultUserFilters}
          defaultUserArrangeBy={initialArrangeBy}
        />

        {showCharts && !!data?.rows.length && (
          <Stack mt={showCharts || (!showCharts && arrangeByWithCustomGrid) ? 3 : 0}>
            <PayableFeesChart
              data={data}
              entityType="student"
              breakDownByOptions={[
                FilterKeys.FeeStatus,
                // TODO: unblock when supported https://schooly.atlassian.net/browse/TR-7658
                // FilterKeys.Product,
                // FilterKeys.ProductType,
                // FilterKeys.Frequency,
              ]}
              arrangeBy={arrangeByWithCustomGrid.arrangeBy}
              filters={params.filters}
              onOpenCustomGrid={handleSetCustomGrid}
              onOpenArrangeByDropdown={payableFeesFiltersRef.current?.openArrangeBy}
              loading={isLoading}
            />
          </Stack>
        )}
      </Stack>

      {!!data?.rows.length && (
        <Stack
          sx={(theme) => ({
            mt: 3.25,
            py: 1,
            borderBottom: `1px solid ${theme.palette.divider}`,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: theme.spacing(2.5),
          })}
        >
          <Stack flexDirection="row" gap={2}>
            <ArrangeByDropdown
              onSelectOption={handleChangeSortOption}
              options={INVOICES_REPORTING_SORT_OPTIONS}
              selectedOption={params.sort.by}
              label={formatMessage({ id: 'sortBy' })}
            />

            <IconButton onClick={handleToggleSortDirection}>
              {params.sort.direction === SORT_DIRECTION.DESC ? <ArrowDownIcon /> : <ArrowUpIcon />}
            </IconButton>
          </Stack>
          {!isChartsOpened && (
            <IconButton onClick={onToggleChartsOpened} sx={{ mr: 1 }}>
              <ChartIcon />
            </IconButton>
          )}
        </Stack>
      )}
      {renderGrid()}
    </>
  );
};

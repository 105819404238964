import { PayableFeeStatus, useGetInvoiceQuery } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { Loading, ModalLarge } from '@schooly/style';
import { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useRouter } from '../../context/router/useRouter';
import AccessDenied from '../AccessDenied';
import { InvoiceEditContent } from './InvoiceEditContent';

export const EDITABLE_INVOICE_STATUSES: PayableFeeStatus[] = [
  'unpaid',
  'overdue',
  'due',
  'partially_paid',
  'paid',
];

const EDIT_INVOICES_DEFAULT_PAGE = 'students';

export const InvoiceEditModal: FC = () => {
  const { goBack, getBackground, setBackground } = useRouter();
  const background = getBackground();

  useEffect(() => {
    // For cases when the user reloads the invoice editing page or follows the link
    if (!background.element) {
      setBackground(EDIT_INVOICES_DEFAULT_PAGE);
    }
  }, [background.element, getBackground, setBackground]);

  const { permissions } = useAuth();

  const canEditInvoice = permissions.includes('product_and_invoice_manager');

  const { id: invoiceId } = useParams();

  const {
    isLoading: isInvoiceLoading,
    isFetching: isInvoiceFetching,
    data,
  } = useGetInvoiceQuery(
    { id: invoiceId! },
    {
      enabled: Boolean(invoiceId && canEditInvoice),
      refetchOnMount: 'always',
    },
  );

  const deprecatedInvoice = data?.invoice
    ? !EDITABLE_INVOICE_STATUSES.includes(data.invoice.status)
    : false;

  if (!canEditInvoice || deprecatedInvoice) {
    return (
      <ModalLarge open onClose={goBack}>
        <AccessDenied />
      </ModalLarge>
    );
  }

  if (isInvoiceFetching || isInvoiceLoading || !data) {
    return (
      <ModalLarge open onClose={goBack}>
        <Loading />
      </ModalLarge>
    );
  }

  return <InvoiceEditContent invoice={data.invoice} />;
};

import { Stack, Typography } from '@mui/material';
import {
  AgeGroup,
  AvailableCriteria,
  FilterElementType,
  GroupSubject,
  Individual,
  MessageCriteria,
  MessageCriteriaType,
  MessageCriterion,
  Recipient,
  SchoolLevel,
  SchoolProperty,
  SimpleListResult,
} from '@schooly/api';
import { Genders, Nationalities, PROPERTIES_TEXT_IDS, PropertiesTextIds } from '@schooly/constants';
import { TagSelect } from '@schooly/style';
import React from 'react';

import { RoundCardProps } from '../../components/uikit-components/RoundCard';
import { SchoolPropertyType, SchoolUserRole } from '../../constants/school';
import { MessageFormCriteria } from '../../context/messages/MessageContext';
import { getCriteriaItemKey, getCriteriaName } from '../../helpers/misc';
import getTypedObjectKeys from '../../utils/getTypedObjectKeys';
import searchWords from '../../utils/searchWords';
import { GroupCriteriaTag } from '../Groups/ManageGroupModal/GroupCriteriaInfo.styled';

export const getCriteriaCategory = (criteria: AvailableCriteria): PropertiesTextIds => {
  switch (criteria.type) {
    case FilterElementType.Subject:
      return 'subject';
    case FilterElementType.Group:
      return 'group';
    case FilterElementType.Gender:
      return 'gender';
    case FilterElementType.Nationality:
      return 'nationality';
    default:
    case FilterElementType.Property: {
      return criteria.school_property?.type as PropertiesTextIds;
    }
  }
};

export function getCriteriaByCategory(selectedCriteria: AvailableCriteria[]) {
  return selectedCriteria.reduce(
    (acc: Partial<Record<PropertiesTextIds, AvailableCriteria[]>>, criteria: AvailableCriteria) => {
      const category: PropertiesTextIds = getCriteriaCategory(criteria);
      const duplicateName = acc[category]?.find(
        (c) => getCriteriaName(c) === getCriteriaName(criteria),
      );

      if (duplicateName) {
        return acc;
      }

      if (acc[category]) {
        acc[category]!.push(criteria);

        return acc;
      }

      return { ...acc, [category]: [criteria] };
    },
    {},
  );
}

export function getCategoriesFromCriteria(selectedCriteria: AvailableCriteria[]) {
  const criteriaByCategory = getCriteriaByCategory(selectedCriteria);

  const displayedCategories = getTypedObjectKeys(criteriaByCategory);

  if (!criteriaByCategory || !displayedCategories.length) {
    return null;
  }

  const notEmptyCategories = getTypedObjectKeys(PROPERTIES_TEXT_IDS).filter((c) =>
    displayedCategories.includes(c),
  );

  return { notEmptyCategories, displayedCategories, criteriaByCategory };
}

interface GetDisplayedCriteriaProps {
  selectedCriteria: AvailableCriteria[];
  handleCriteriaClick?: (criteria: AvailableCriteria) => void;
  handleGroupCriteriaClick?: (criteria: AvailableCriteria[]) => void;
  criteriaWithErrors?: AvailableCriteria[];
}

export const getDisplayedCriteria = ({
  selectedCriteria,
  handleCriteriaClick,
  handleGroupCriteriaClick,
  criteriaWithErrors,
}: GetDisplayedCriteriaProps) => {
  const criteriaByCategory = getCriteriaByCategory(selectedCriteria);

  const displayedCategories = getTypedObjectKeys(criteriaByCategory);
  const displayedCategoriesWithGrouping = displayedCategories.filter(
    (c) => criteriaByCategory[c]?.[0].propGroup,
  );

  if (!criteriaByCategory || !displayedCategories.length) {
    return null;
  }

  const notEmptyCategories = getTypedObjectKeys(PROPERTIES_TEXT_IDS).filter((c) =>
    displayedCategories.includes(c),
  );

  if (displayedCategoriesWithGrouping.length) {
    displayedCategoriesWithGrouping.forEach((key) => {
      const criteriaList = [...(criteriaByCategory[key] ?? [])];
      criteriaByCategory[key] = getSelectedCriteriaWithGrouping(criteriaList);
    });
  }

  return (
    <Stack direction="row" alignItems="center" flexWrap="wrap">
      {notEmptyCategories.map((category, _, array) => (
        <React.Fragment key={category}>
          {array.length > 1 && category !== array[0] && (
            <span className="AvailableCriteriaCard__separator">,</span>
          )}
          {(criteriaByCategory[category] as AvailableCriteria[])!.map((criteria, criteriaIndex) => {
            const error = criteriaWithErrors?.some(
              ({ group }) => !!group?.id && !!criteria.group?.id && group.id === criteria.group.id,
            );

            if (criteria.propGroupItems?.length && criteria.propGroup?.id) {
              return (
                <React.Fragment key={criteria.propGroup.id}>
                  <TagSelect
                    label={
                      <Typography variant="h3" color={(theme) => theme.palette.common.grey2}>
                        {criteria.propGroup.name}
                      </Typography>
                    }
                    key={criteria.propGroup.id}
                    onClick={() => {
                      const { propGroupItems } = criteria;
                      handleGroupCriteriaClick &&
                        propGroupItems &&
                        handleGroupCriteriaClick(propGroupItems);
                    }}
                    sx={(theme) => ({
                      color: error ? theme.palette.error.main : theme.palette.primary.main,
                      ':hover': {
                        cursor: 'pointer',
                        background: error
                          ? theme.palette.error.superLight
                          : theme.palette.common.lightBg,
                      },
                      ':hover p': {
                        color: error && theme.palette.error.main,
                      },
                      '&.MuiChip-root': {
                        backgroundColor: theme.palette.background.paper,
                      },
                    })}
                  />
                  {criteriaIndex <
                    (criteriaByCategory[category] as AvailableCriteria[])!.length - 1 && (
                    <span className="AvailableCriteriaCard__separator">+</span>
                  )}
                </React.Fragment>
              );
            }

            return (
              <React.Fragment key={getCriteriaItemKey(criteria)}>
                <TagSelect
                  label={
                    <Typography variant="h3" color={(theme) => theme.palette.common.grey2}>
                      {getCriteriaName(criteria)}
                    </Typography>
                  }
                  key={getCriteriaItemKey(criteria)}
                  onClick={() => handleCriteriaClick?.(criteria)}
                  sx={(theme) => ({
                    color: error ? theme.palette.error.main : theme.palette.primary.main,
                    ':hover': {
                      cursor: 'pointer',
                      background: error
                        ? theme.palette.error.superLight
                        : theme.palette.common.lightBg,
                    },
                    ':hover p': {
                      color: error && theme.palette.error.main,
                    },
                    '&.MuiChip-root': {
                      backgroundColor: theme.palette.background.paper,
                    },
                  })}
                />
                {criteriaIndex <
                  (criteriaByCategory[category] as AvailableCriteria[])!.length - 1 && (
                  <span className="AvailableCriteriaCard__separator">+</span>
                )}
              </React.Fragment>
            );
          })}
        </React.Fragment>
      ))}
    </Stack>
  );
};

export function renderCriteriaTags(userCriteria: AvailableCriteria[]) {
  const criteriaData = getCategoriesFromCriteria(userCriteria);

  return criteriaData?.displayedCategories?.map((category, _, array) => (
    <React.Fragment key={category}>
      {array.length > 1 && category !== array[0] && `${' '},${' '}`}
      {(criteriaData?.criteriaByCategory[category] as AvailableCriteria[])!.map(
        (criteria, criteriaIndex) => {
          return (
            <React.Fragment key={getCriteriaItemKey(criteria)}>
              <GroupCriteriaTag>
                <Typography>{getCriteriaName(criteria)}</Typography>
              </GroupCriteriaTag>
              {criteriaIndex <
                (criteriaData?.criteriaByCategory[category] as AvailableCriteria[])!.length - 1 &&
                `${' '}+${' '}`}
            </React.Fragment>
          );
        },
      )}
    </React.Fragment>
  ));
}

export const getParentsFromIndividuals = (individuals?: Individual[]) =>
  individuals?.filter(
    (i) =>
      i.recipient_type === SchoolUserRole.Parent && i.individual_type === SchoolUserRole.Parent,
  );

export const getStaffFromIndividuals = (individuals?: Individual[]) =>
  individuals?.filter(
    (i) => i.recipient_type === SchoolUserRole.Staff && i.individual_type === SchoolUserRole.Staff,
  );

export const getParentsOfStudentsFromIndividuals = (individuals?: Individual[]) =>
  individuals?.filter(
    (i) =>
      i.recipient_type === SchoolUserRole.Parent && i.individual_type === SchoolUserRole.Student,
  );

export const getStaffOfStudentsFromIndividuals = (individuals?: Individual[]) =>
  individuals?.filter(
    (i) =>
      i.recipient_type === SchoolUserRole.Staff && i.individual_type === SchoolUserRole.Student,
  );

export const getActualParentsRecipient = (individuals?: Recipient[]) =>
  individuals?.filter((i) => i.school_user_relation_role === SchoolUserRole.Parent);

export const getActualStaffRecipient = (individuals?: Recipient[]) =>
  individuals?.filter((i) => i.school_user_relation_role === SchoolUserRole.Staff);

export const convertIndividualToUserSearchResult = (
  individual: Individual | Recipient,
): SimpleListResult => {
  const { school_user_relation } = individual;

  return {
    ...school_user_relation,
    relation_id: school_user_relation.relation_id!,
    user_id: school_user_relation.user_id!,
  };
};

const getCriteriaTags = (
  criteria: MessageCriteria,
  properties?: SchoolProperty[],
  subjects?: GroupSubject[],
) => {
  const item = criteria.filter_element;

  switch (item.type) {
    case MessageCriteriaType.Nationality:
      return item.element_enum_index?.map((i) => Nationalities[i]);
    case MessageCriteriaType.Gender:
      return item.element_enum_index?.map((i) => Genders[i]);
    case MessageCriteriaType.Subject:
      return subjects?.filter((subj) => item.element_id?.includes(subj.id)).map((s) => s.name);
    case MessageCriteriaType.Group:
      return item.groups?.map((g) => g.name);
    default:
      return properties
        ?.filter((p) => criteria.filter_element.element_id?.includes(p.id))
        .map((t) => t.name);
  }
};

interface GetListFromMessageCriteriaProps {
  criteria: MessageCriteria;
  properties?: SchoolProperty[];
  subjects?: GroupSubject[];
  ageGroups?: AgeGroup[];
}

const getListFromMessageCriteria = ({
  criteria,
  properties,
  subjects,
  ageGroups,
}: GetListFromMessageCriteriaProps) => {
  const item = criteria.filter_element;

  switch (item.type) {
    case MessageCriteriaType.Nationality:
      return item.element_enum_index?.map((i) => ({ name: Nationalities[i], id: criteria.id }));
    case MessageCriteriaType.Gender:
      return item.element_enum_index?.map((i) => ({ name: Genders[i], id: criteria.id }));
    case MessageCriteriaType.Subject:
      return subjects
        ?.filter((subj) => item.element_id?.includes(subj.id))
        .map((s) => ({ name: s.name, id: criteria.id, subject: { id: criteria.id } }));
    case MessageCriteriaType.Group:
      return item.groups?.map((g) => ({
        name: g.name,
        id: criteria.id,
        group: { id: criteria.id },
      }));
    case MessageCriteriaType.Age_group:
      return ageGroups
        ?.filter((g) => item.element_id?.includes(g.id))
        .map((t) => ({ name: t.name, id: t.id, archived: t.archived }));
    default:
      return properties
        ?.filter((p) => item.element_id?.includes(p.id))
        .map((t) => ({ name: t.name, id: t.id, archived: t.archived }));
  }
};

export const getCriteriaNames = (
  criteria?: MessageCriteria[],
  properties?: SchoolProperty[],
  subjects?: GroupSubject[],
) => {
  const criteriaByName = criteria?.reduce((acc, cur) => {
    const name = MessageCriteriaType[cur.filter_element.type];

    const tags = getCriteriaTags(cur, properties, subjects);

    return { ...acc, [name]: tags } as Record<string, string[]>;
  }, {});

  return criteriaByName;
};

export const getParentsOfStudentsCriteria = (criteria?: MessageCriteria[]) =>
  criteria?.filter(
    (c) =>
      c.criterion_type === MessageCriterion.Parents && c.recipient_type === SchoolUserRole.Parent,
  );

export const getStaffCriteria = (criteria?: MessageCriteria[]) =>
  criteria?.filter(
    (c) => c.criterion_type === MessageCriterion.Staff && c.recipient_type === SchoolUserRole.Staff,
  );

interface ConvertMessageCriteriaToAvailableCriteriaProps {
  criteria: MessageCriteria;
  item: {
    name: string;
    id: string;
    archived?: boolean;
  };
  subjects?: GroupSubject[];
  ageGroups?: AgeGroup[];
  schoolLevels?: SchoolLevel[];
}

export const convertMessageCriteriaToAvailableCriteria = ({
  criteria,
  item,
  subjects,
  ageGroups,
  schoolLevels,
}: ConvertMessageCriteriaToAvailableCriteriaProps): AvailableCriteria => {
  if (criteria.filter_element.type === MessageCriteriaType.Subject) {
    return {
      type: criteria.filter_element.type as unknown as FilterElementType,
      subject: { ...subjects?.find((s) => s.name === item.name) },
    } as AvailableCriteria;
  }

  let availableCriteria = {
    type:
      criteria.filter_element.type === MessageCriteriaType.Age_group
        ? 0
        : criteria.filter_element.type,
    name: item.name,
    school_property: {
      id: item.id,
      order: 0,
      name: item.name,
      archived: item.archived,
      group_default: false,
      type: `${
        MessageCriteriaType[criteria.filter_element.type]
      }`.toLowerCase() as SchoolPropertyType,
      category: {
        id: criteria.id,
        name: '',
        order: 0,
        final: false,
        current: false,
        user_type: 0,
      },
    },
  } as AvailableCriteria;

  if (criteria.filter_element.type === MessageCriteriaType.Age_group) {
    const ageGroup = ageGroups?.find((g) => g.id === item.id);
    const propGroup = {
      id: ageGroup?.level_id ?? null,
      name: schoolLevels?.find((l) => l.id === ageGroup?.level_id)?.name,
      count: ageGroups?.filter((g) => g.level_id === ageGroup?.level_id).length ?? 0,
    };
    availableCriteria = { ...availableCriteria, propGroup };
  }

  return availableCriteria;
};

interface GetAvailableCriteriaFromMessageCriteriaProps {
  criteria?: MessageCriteria[];
  properties?: SchoolProperty[];
  subjects?: GroupSubject[];
  ageGroups?: AgeGroup[];
  schoolLevels?: SchoolLevel[];
}

export const getAvailableCriteriaFromMessageCriteria = ({
  criteria,
  properties,
  subjects,
  ageGroups,
  schoolLevels,
}: GetAvailableCriteriaFromMessageCriteriaProps) =>
  criteria?.reduce<AvailableCriteria[]>((prev, curr) => {
    const list = getListFromMessageCriteria({
      criteria: curr,
      properties,
      subjects,
      ageGroups,
    });

    const availableCriteria = list?.map((i) =>
      convertMessageCriteriaToAvailableCriteria({
        criteria: curr,
        item: i,
        subjects,
        ageGroups,
        schoolLevels,
      }),
    );

    const item = curr.filter_element;

    if (item.type === MessageCriteriaType.Group) {
      const groupCriteria = item?.groups?.map((g) => ({ type: item.type, group: g }));
      return prev.concat(groupCriteria as any);
    }

    if ([MessageCriteriaType.Gender, MessageCriteriaType.Nationality].includes(item.type)) {
      const genderCriteria = curr?.filter_element?.element_enum_index?.map((ind) => ({
        type: curr.filter_element.type,
        enum_index: ind,
      }));
      return prev.concat(genderCriteria as any);
    }

    return prev.concat(availableCriteria as any);
  }, []);

interface GetStudentsAvailableCriteriaProps {
  criteria?: MessageCriteria[];
  properties?: SchoolProperty[];
  subjects?: GroupSubject[];
  ageGroups?: AgeGroup[];
  schoolLevels?: SchoolLevel[];
}

export const getStudentsAvailableCriteria = ({
  criteria,
  properties,
  subjects,
  ageGroups,
  schoolLevels,
}: GetStudentsAvailableCriteriaProps) => {
  const filteredCriteria = getParentsOfStudentsCriteria(criteria);

  return getAvailableCriteriaFromMessageCriteria({
    criteria: filteredCriteria,
    properties,
    subjects,
    ageGroups,
    schoolLevels,
  });
};

interface GetStaffAvailableCriteriaProps {
  criteria?: MessageCriteria[];
  properties?: SchoolProperty[];
  subjects?: GroupSubject[];
  ageGroups?: AgeGroup[];
  schoolLevels?: SchoolLevel[];
}

export const getStaffAvailableCriteria = ({
  criteria,
  properties,
  subjects,
  ageGroups,
  schoolLevels,
}: GetStaffAvailableCriteriaProps) => {
  const filteredCriteria = getStaffCriteria(criteria);
  return getAvailableCriteriaFromMessageCriteria({
    criteria: filteredCriteria,
    properties,
    subjects,
    ageGroups,
    schoolLevels,
  });
};

export const getParentsOfStudentsCriteriaNames = (
  criteria?: MessageCriteria[],
  properties?: SchoolProperty[],
  subjects?: GroupSubject[],
) => {
  const list = getParentsOfStudentsCriteria(criteria);
  return getCriteriaNames(list, properties, subjects);
};

export const getStaffCriteriaNames = (
  criteria?: MessageCriteria[],
  properties?: SchoolProperty[],
  subjects?: GroupSubject[],
) => {
  const list = getStaffCriteria(criteria);
  return getCriteriaNames(list, properties, subjects);
};

export const getGroupedCriteria = (
  filter: string,
  criteria?: RoundCardProps<AvailableCriteria>[],
) => {
  return criteria?.reduce<Array<RoundCardProps<AvailableCriteria>>>((acc, el, index, arr) => {
    const propGroupId = el.item.propGroup?.id;
    const prevEl = arr[index - 1];
    const prevElPropGroupId = prevEl?.item.propGroup?.id;
    const isNewPropGroup = propGroupId !== prevElPropGroupId;

    if (propGroupId && isNewPropGroup) {
      const propGroupItem = filter
        ? searchWords(el.item.school_property!.name, filter) && el.item
        : el.item;
      acc.push({
        item: {
          type: el.item.type,
          propGroupItems: propGroupItem ? [propGroupItem] : [],
          propGroup: el.item.propGroup,
        },
      });
    } else if (propGroupId) {
      const propGroupItem = filter
        ? searchWords(el.item.school_property!.name, filter) && el.item
        : el.item;
      if (propGroupItem) {
        acc[acc.length - 1].item.propGroupItems?.push(el.item);
      }
    } else {
      acc.push(el);
    }
    return acc;
  }, []);
};

const getSelectedCriteriaWithGrouping = (criteriaList: AvailableCriteria[]) => {
  const groupsSelected: string[] = [];
  const groupsNotSelected: string[] = [];

  return criteriaList?.reduce<AvailableCriteria[]>((acc, c) => {
    if (c.propGroup?.id && groupsSelected.includes(c.propGroup?.id)) return acc;

    if (!c.propGroup?.id || groupsNotSelected.includes(c.propGroup?.id)) {
      acc.push(c);
      return acc;
    }

    const selectedOfGroup = criteriaList.filter((item) => item.propGroup?.id === c.propGroup?.id);
    if (selectedOfGroup.length === c.propGroup?.count) {
      groupsSelected.push(c.propGroup?.id);
      acc.push({
        type: c.type,
        propGroupItems: [...selectedOfGroup],
        propGroup: c.propGroup,
      });
    } else {
      groupsNotSelected.push(c.propGroup?.id);
      acc.push(c);
    }

    return acc;
  }, []);
};

export const getCriteriaForMessageForm = (criteria: AvailableCriteria[]) => {
  return criteria.reduce<MessageFormCriteria>(
    (acc, { type, school_property, group, subject, enum_index }) => {
      if (school_property?.type) {
        switch (school_property.type) {
          case 'status':
            if (school_property.archived) return acc;
            if (!acc.status) acc.status = [];
            acc.status.push(school_property.id);
            return acc;
          case 'age_group':
            if (school_property.archived) return acc;
            if (!acc.age_group) acc.age_group = [];
            acc.age_group.push(school_property.id);
            return acc;
          case 'house':
            if (school_property.archived) return acc;
            if (!acc.house) acc.house = [];
            acc.house.push(school_property.id);
            return acc;
          case 'department':
            if (school_property.archived) return acc;
            if (!acc.department) acc.department = [];
            acc.department.push(school_property.id);
            return acc;
        }
      }

      if (type === FilterElementType.Group) {
        if (!group) return acc;
        if (!acc.group) acc.group = [];
        acc.group.push(group.id);
        return acc;
      }
      if (type === FilterElementType.Subject) {
        if (!subject || subject.archived) return acc;
        if (!acc.subject) acc.subject = [];
        acc.subject.push(subject.id);
        return acc;
      }
      if (type === FilterElementType.Gender) {
        if (enum_index === undefined) return acc;
        if (!acc.gender) acc.gender = [];
        acc.gender.push(enum_index);
        return acc;
      }
      if (type === FilterElementType.Nationality) {
        if (enum_index === undefined) return acc;
        if (!acc.nationality) acc.nationality = [];
        acc.nationality.push(enum_index);
        return acc;
      }
      return acc;
    },
    {},
  );
};

export const getIndividualIdsForMessageForm = (individuals: Individual[]): string[] => {
  return (
    individuals?.reduce<string[]>(
      (acc, { school_user_relation }) =>
        school_user_relation.relation_id ? [...acc, school_user_relation.relation_id] : acc,
      [],
    ) ?? []
  );
};

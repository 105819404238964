import { Button, ClickAwayListener, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useAuth } from '@schooly/components/authentication';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { SchoolUserRole } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import { DeleteIcon, PlusIcon, SimpleButton } from '@schooly/style';
import { FC, useCallback, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { MessageForm, MessageFormCriteria } from '../../../../context/messages/MessageContext';
import { useMessage } from '../../../../context/messages/useMessage';
import { RecipientsByIndividualFormRef } from '../MessagesCreateRecipientsSelect/RecipientsByIndividualSelect';
import { ParentsByStudentsIndividualForm } from './ParentsByStudentsIndividualForm';
import {
  RecipientsByCriteriaForm,
  RecipientsByCriteriaFormProps,
  RecipientsByCriteriaFormRef,
} from './RecipientsByCriteriaForm';
import { StaffByIndividualForm } from './StaffIndividualForm';

export const hasCriteria = (criteria: MessageFormCriteria) =>
  Object.entries(criteria).some(([key, v]) => {
    if (key === 'date') return false;
    return v?.length;
  });

interface MessagesCreateModalRecipientsProps {
  shouldValidate: boolean;
}

export const MessagesCreateModalRecipients: FC<MessagesCreateModalRecipientsProps> = ({
  shouldValidate,
}) => {
  const { formatMessage } = useIntl();
  const { schoolId = '' } = useAuth();
  const { getConfirmation } = useConfirmationDialog();
  const { prevCriteriaDate, actions, notActualGroups, isCheckGroupsInProgress } = useMessage();
  const { formState, register, watch, setValue, clearErrors, trigger, setError } =
    useFormContext<MessageForm>();

  const parentCriteriaErrorRequired = Boolean(
    formState.errors.parents_of_students_criteria?.message ===
      formatMessage({ id: 'input-ErrorRequired' }),
  );
  const staffCriteriaErrorRequired = Boolean(
    formState.errors.staff_criteria?.message === formatMessage({ id: 'input-ErrorRequired' }),
  );
  const parentByStudentIdsError = Boolean(formState.errors.parents_of_students_ids);
  const staffIdsError = Boolean(formState.errors.staff_ids);

  const recipientsRequiredError =
    parentCriteriaErrorRequired ||
    staffCriteriaErrorRequired ||
    parentByStudentIdsError ||
    staffIdsError;

  const parentCriteria = watch('parents_of_students_criteria');
  const staffCriteria = watch('staff_criteria');
  const parentByStudentIds = watch('parents_of_students_ids');
  const staffIds = watch('staff_ids');

  const hasParentCriteria = hasCriteria(parentCriteria);
  const hasStaffCriteria = hasCriteria(staffCriteria);
  const hasParentByIndividualStudent = Boolean(parentByStudentIds.length);
  const hasIndividualStaff = Boolean(staffIds.length);
  const hasRecipients =
    hasParentCriteria || hasParentByIndividualStudent || hasStaffCriteria || hasIndividualStaff;

  const recipientsByCriteriaFormRef = useRef<RecipientsByCriteriaFormRef>(null);
  const recipientsByIndividualFormRef = useRef<RecipientsByIndividualFormRef>(null);

  const [staffSectionOpen, openStaffSection, closeStaffSection] = useFlag(
    hasStaffCriteria || hasIndividualStaff,
  );

  const [addStaffTooltipOpened, openAddStaffTooltip, closeAddStaffTooltip] = useFlag();

  const handleCloseFilledSection = useCallback(
    async (onClose: () => void, confirmationTextId: string) => {
      const isConfirmed = await getConfirmation({
        textId: confirmationTextId,
      });

      if (isConfirmed) {
        onClose();
      }
    },
    [getConfirmation],
  );

  const clearRecipientsError = useCallback(() => {
    clearErrors([
      'parents_of_students_criteria',
      'staff_criteria',
      'parents_of_students_ids',
      'staff_ids',
    ]);
  }, [clearErrors]);

  const handleParentCriteriaChange = useCallback<RecipientsByCriteriaFormProps['onCriteriaChange']>(
    (cb) => {
      const newCriteria = cb(parentCriteria);

      if (newCriteria.date) {
        setValue('criteria_date', newCriteria.date[0]);
        actions.updatePrevCriteriaDate(prevCriteriaDate || newCriteria.date[0]);
      }

      if (newCriteria.group && notActualGroups.length) {
        actions.setNotActualGroups(notActualGroups.filter((g) => newCriteria.group?.includes(g)));
      }

      setValue('parents_of_students_criteria', newCriteria, { shouldDirty: true });
    },
    [parentCriteria, setValue, actions, prevCriteriaDate, notActualGroups],
  );

  const handleStaffCriteriaChange = useCallback<RecipientsByCriteriaFormProps['onCriteriaChange']>(
    (cb) => {
      const newCriteria = cb(staffCriteria);

      if (newCriteria.date) {
        setValue('criteria_date', newCriteria.date[0]);
      }

      setValue('staff_criteria', newCriteria, { shouldDirty: true });
    },
    [setValue, staffCriteria],
  );

  const validateCriteria = useCallback(
    async (v: MessageFormCriteria, isParent = false) => {
      if (!shouldValidate) return;

      if (hasIndividualStaff || hasParentByIndividualStudent || hasRecipients) {
        clearRecipientsError();
        return;
      }
      return formatMessage({ id: 'input-ErrorRequired' });
    },
    [
      clearRecipientsError,
      formatMessage,
      shouldValidate,
      hasRecipients,
      hasIndividualStaff,
      hasParentByIndividualStudent,
    ],
  );

  const validateParentsByIndividualStudents = useCallback(
    (v: string[]) => {
      if (!shouldValidate) return;
      if (hasIndividualStaff || v.length || hasRecipients) {
        clearRecipientsError();
        return;
      }

      return formatMessage({ id: 'input-ErrorRequired' });
    },
    [clearRecipientsError, formatMessage, hasIndividualStaff, shouldValidate, hasRecipients],
  );

  const validateIndividualStaff = useCallback(
    (v: string[]) => {
      if (!shouldValidate) return;
      if (hasParentByIndividualStudent || v.length || hasRecipients) {
        clearRecipientsError();
        return;
      }

      return formatMessage({ id: 'input-ErrorRequired' });
    },
    [
      clearRecipientsError,
      formatMessage,
      hasParentByIndividualStudent,
      shouldValidate,
      hasRecipients,
    ],
  );

  const handleOpenCCStaffTooltip = useCallback(() => {
    const hasCriteriasToCopy =
      parentCriteria.age_group ||
      parentCriteria.subject ||
      parentCriteria.house ||
      parentCriteria.group;

    if (hasCriteriasToCopy) {
      openAddStaffTooltip();
    } else {
      openStaffSection();
    }
  }, [openAddStaffTooltip, openStaffSection, parentCriteria]);

  const copyParentsSelectedCriterias = useCallback(() => {
    setValue('staff_criteria.age_group', parentCriteria.age_group);
    setValue('staff_criteria.subject', parentCriteria.subject);
    setValue('staff_criteria.house', parentCriteria.house);
    setValue('staff_criteria.group', parentCriteria.group);
  }, [parentCriteria, setValue]);

  const criteriaGroupsSorted = useCallback(
    (groupIds?: string[]) => {
      return groupIds?.sort((aId, bId) => {
        const aInGroup = notActualGroups.includes(aId);
        const bInGroup = notActualGroups.includes(bId);

        if (aInGroup && !bInGroup) {
          return -1;
        } else if (!aInGroup && bInGroup) {
          return 1;
        } else {
          return 0;
        }
      });
    },
    [notActualGroups],
  );

  //handles errors when the form is submitted
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (!formState.isSubmitted) return;

      if (name === 'parents_of_students_criteria') {
        const recipientsAdded = Object.values(value.parents_of_students_criteria || {}).some(
          (v) => v?.length,
        );

        if (recipientsRequiredError && recipientsAdded) {
          clearRecipientsError();
        }
      }

      if (name === 'staff_criteria') {
        const recipientsAdded = Object.values(value.staff_criteria || {}).some((v) => v?.length);

        if (recipientsRequiredError && recipientsAdded) {
          clearRecipientsError();
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [
    clearErrors,
    clearRecipientsError,
    formState.isSubmitted,
    formatMessage,
    hasRecipients,
    recipientsRequiredError,
    setError,
    shouldValidate,
    trigger,
    watch,
  ]);

  const handleCloseParentCriteriaSection = useCallback(
    (close: () => void) => {
      setValue('parents_of_students_criteria', {});
      setValue('criteria_date', undefined);
      close();
    },
    [setValue],
  );

  return (
    <>
      <Stack direction="row" justifyContent="space-between" pb={2.5}>
        <Typography variant="h2">
          <FormattedMessage id="messages-ToParents" />
        </Typography>
        {!staffSectionOpen && (
          <ClickAwayListener onClickAway={closeAddStaffTooltip}>
            <div>
              <Tooltip
                open={addStaffTooltipOpened}
                componentsProps={{
                  tooltip: {
                    sx: {
                      minWidth: 230,
                    },
                  },
                }}
                disableTouchListener
                title={
                  <Stack gap={0.5}>
                    <Button
                      variant="text"
                      onClick={openStaffSection}
                      sx={{
                        width: '100%',
                        justifyContent: 'flex-start',
                        p: 0.5,
                        borderRadius: 0.5,
                      }}
                    >
                      {formatMessage({ id: 'messages-ChooseStaffManually' })}
                    </Button>

                    <Button
                      variant="text"
                      onClick={() => {
                        openStaffSection();
                        setTimeout(() => recipientsByCriteriaFormRef.current?.openRecipientsForm());
                        copyParentsSelectedCriterias();
                      }}
                      sx={{
                        width: '100%',
                        justifyContent: 'flex-start',
                        p: 0.5,
                        borderRadius: 0.5,
                      }}
                    >
                      {formatMessage({ id: 'messages-CopyParentToStaff' })}
                    </Button>
                  </Stack>
                }
              >
                <SimpleButton startIcon={<PlusIcon />} onClick={handleOpenCCStaffTooltip}>
                  {formatMessage({ id: 'messages-CCStaff' })}
                </SimpleButton>
              </Tooltip>
            </div>
          </ClickAwayListener>
        )}
      </Stack>

      <Stack gap={1.5}>
        <RecipientsByCriteriaForm
          schoolId={schoolId}
          title={formatMessage({ id: 'messages-OfStudentsByCriteria' })}
          error={recipientsRequiredError}
          inputRef={
            register('parents_of_students_criteria', {
              validate: shouldValidate ? (v) => validateCriteria(v, true) : undefined,
            }).ref
          }
          criteria={{
            ...parentCriteria,
            group: criteriaGroupsSorted(parentCriteria.group),
          }}
          userType={SchoolUserRole.Student}
          onCriteriaChange={handleParentCriteriaChange}
          isInitOpen
          onClose={
            hasParentCriteria
              ? (close) =>
                  handleCloseFilledSection(() => {
                    handleCloseParentCriteriaSection(close);
                  }, 'messages-CloseParentCriteriaSectionConfirmation')
              : handleCloseParentCriteriaSection
          }
          invalidGroupIds={notActualGroups}
          loadingGroups={isCheckGroupsInProgress}
        />

        <ParentsByStudentsIndividualForm
          schoolId={schoolId}
          title={formatMessage({ id: 'messages-OfStudentsIndividually' })}
          error={recipientsRequiredError}
          validate={shouldValidate ? validateParentsByIndividualStudents : undefined}
          isInitOpen={hasParentByIndividualStudent}
          buttonTextId={'messages-AddByIndividualStudents'}
          onClose={
            hasParentByIndividualStudent
              ? (close) =>
                  handleCloseFilledSection(() => {
                    setValue('parents_of_students_ids', []);
                    close();
                  }, 'messages-CloseParentByStudentSectionConfirmation')
              : undefined
          }
        />
      </Stack>

      {staffSectionOpen && (
        <>
          <Stack direction="row" justifyContent="space-between" py={2.5} pr={1.5}>
            <Typography variant="h2">
              <FormattedMessage id="messages-CCStaff" />
            </Typography>
            <IconButton
              onClick={
                hasIndividualStaff || hasStaffCriteria
                  ? () =>
                      handleCloseFilledSection(() => {
                        setValue('staff_criteria', {});
                        setValue('staff_ids', []);
                        closeStaffSection();
                      }, 'messages-CloseStaffSectionConfirmation')
                  : closeStaffSection
              }
              inverse
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
          <Stack gap={1.5}>
            <RecipientsByCriteriaForm
              ref={recipientsByCriteriaFormRef}
              schoolId={schoolId}
              title={formatMessage({ id: 'messages-StaffByCriteria' })}
              error={recipientsRequiredError}
              inputRef={
                register('staff_criteria', {
                  validate: shouldValidate ? (v) => validateCriteria(v) : undefined,
                }).ref
              }
              criteria={{
                ...staffCriteria,
                date: parentCriteria.date,
                group: criteriaGroupsSorted(staffCriteria.group),
              }}
              userType={SchoolUserRole.Staff}
              onCriteriaChange={handleStaffCriteriaChange}
              isInitOpen={hasStaffCriteria}
              onClose={
                hasStaffCriteria
                  ? (close) =>
                      handleCloseFilledSection(() => {
                        setValue('staff_criteria', {});
                        close();
                      }, 'messages-CloseStaffCriteriaSectionConfirmation')
                  : undefined
              }
              invalidGroupIds={notActualGroups}
              loadingGroups={isCheckGroupsInProgress}
            />

            <StaffByIndividualForm
              ref={recipientsByIndividualFormRef}
              schoolId={schoolId}
              title={formatMessage({ id: 'messages-StaffIndividually' })}
              error={recipientsRequiredError}
              validate={shouldValidate ? validateIndividualStaff : undefined}
              isInitOpen={hasIndividualStaff}
              buttonTextId={'messages-AddStaffIndividually'}
              onClose={
                hasIndividualStaff
                  ? (close) =>
                      handleCloseFilledSection(() => {
                        setValue('staff_ids', []);
                        close();
                      }, 'messages-CloseIndividualStaffSectionConfirmation')
                  : undefined
              }
            />
          </Stack>
        </>
      )}

      {recipientsRequiredError && (
        <Typography color="error.main" mt={0.25} ml={1.5}>
          <FormattedMessage id="messages-SelectIndividualOrCriterion" />
        </Typography>
      )}
    </>
  );
};

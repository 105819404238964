import {
  Avatar,
  Box,
  IconButton,
  TableHeadProps,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { GetStaffQuerySort, SORT_DIRECTION, StaffSearchResult } from '@schooly/api';
import { AvatarAuth } from '@schooly/components/avatar-auth';
import { CombinedRow } from '@schooly/components/filters';
import { RenderSchoolProperty } from '@schooly/components/render-school-property';
import {
  Genders,
  getLabelIdForOption,
  SchoolPropertyType,
  SchoolUserRole,
} from '@schooly/constants';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import {
  ChevronUpIcon,
  GridCell,
  GridHead,
  SortableCell,
  SyncIcon,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { getUserFullName } from '@schooly/utils/get-user-full-name';
import { FC, MouseEventHandler, ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import useAppLocation from '../../hooks/useAppLocation';

type StaffHeaderProps = {
  sort?: GetStaffQuerySort;
  onChangeSort: (v: GetStaffQuerySort) => void;
  rightIcon?: ReactNode;
} & TableHeadProps;

export const StaffHeader: FC<StaffHeaderProps> = ({ onChangeSort, rightIcon, sort, ...rest }) => {
  const { formatMessage } = useIntl();
  const handleSort = (columnTextId: GetStaffQuerySort['columnTextId']) => () => {
    onChangeSort({
      columnTextId,
      direction:
        columnTextId === sort?.columnTextId
          ? sort.direction === SORT_DIRECTION.ASC
            ? SORT_DIRECTION.DESC
            : SORT_DIRECTION.ASC
          : SORT_DIRECTION.ASC,
    });
  };

  return (
    <GridHead borderBottom {...rest}>
      <GridCell width="40px" />
      <SortableCell
        label={formatMessage({ id: 'people-Name' })}
        sort={sort}
        columnTextId="last_name"
        width="40%"
        onChangeSort={handleSort}
      />
      <SortableCell
        label={formatMessage({ id: 'peopleDetail-JobTitle' })}
        sort={sort}
        columnTextId="job_title"
        onChangeSort={handleSort}
        width="20%"
      />
      <SortableCell
        label={formatMessage({ id: 'peopleDetail-Gender' })}
        sort={sort}
        columnTextId="gender"
        onChangeSort={handleSort}
        width="20%"
      />
      <SortableCell
        label={formatMessage({ id: 'peopleDetail-Status' })}
        sort={sort}
        columnTextId="status"
        onChangeSort={handleSort}
        width="20%"
      />
      <GridCell width="40px" pr={1} right>
        {rightIcon}
      </GridCell>
    </GridHead>
  );
};

type StaffRowProps = {
  combinedStaffRow: CombinedRow<StaffSearchResult>;
  schoolId: string;
};

export const StaffRow: FC<StaffRowProps> = ({ combinedStaffRow, schoolId }) => {
  const location = useAppLocation();
  const { formatMessage } = useIntl();
  const { propertiesMap } = useSchoolProperties({
    schoolId,
    userType: SchoolUserRole.Staff,
  });

  const [isExpanded, setExpanded] = useState(false);
  const { registration, id } = combinedStaffRow.item;

  const user = registration.school_user_relation;
  const name = getUserFullName(user);

  const status = propertiesMap.status.find((p) => p.id === id);
  const statusTooltipTitle = formatMessage({
    id: `schoolProperty-Archived-${SchoolPropertyType.Status}`,
  });

  const additionalRowsLength = combinedStaffRow.additionalRows.length;

  const handleExpandClick: MouseEventHandler = (e) => {
    e.preventDefault();
    setExpanded((e) => !e);
  };

  const gender = Number.isInteger(user.gender)
    ? formatMessage({
        id: getLabelIdForOption({
          key: Genders[user.gender!],
          optionsKey: 'gender',
        }),
      })
    : '-';

  return (
    <>
      <TableRow
        component={Link}
        to={`/staff/${combinedStaffRow.id}`}
        state={{ backgroundLocation: location }}
        sx={(theme) => ({
          td: {
            backgroundColor: isExpanded
              ? `${theme.palette.background.default} !important`
              : undefined,
          },
          '&:hover td': {
            backgroundColor: theme.palette.background.default,
          },
        })}
      >
        <GridCell right sx={(theme) => ({ '.svg-icon': { color: theme.palette.common.orange } })}>
          {registration.school_user_relation.suggested_changes && (
            <Tooltip title={formatMessage({ id: 'people-PendingChangeRequests' })}>
              <IconButton size="small">
                <SyncIcon />
              </IconButton>
            </Tooltip>
          )}
        </GridCell>
        <GridCell pl={6} sx={{ position: 'relative' }}>
          <Box
            sx={(theme) => ({
              position: 'absolute',
              left: theme.spacing(1),
              marginTop: theme.spacing(-0.5),
            })}
          >
            <AvatarAuth user={user} withAvatarPreview />
          </Box>
          <TypographyWithOverflowHint variant="h3" color="text.primary" noWrap>
            {name}
          </TypographyWithOverflowHint>
        </GridCell>
        <GridCell>
          <Typography color="text.primary">{registration.job_title || '–'}</Typography>
        </GridCell>
        <GridCell>
          <Typography color="text.primary">{gender}</Typography>
        </GridCell>
        <GridCell>
          <RenderSchoolProperty
            property={status}
            defaultValue="-"
            tooltipTitle={statusTooltipTitle}
          />
          {combinedStaffRow.additionalPermanentRows.map(({ id, item }) => {
            const status = propertiesMap.status.find((p) => p.id === item.id);

            return (
              <RenderSchoolProperty
                property={status}
                defaultValue="-"
                tooltipTitle={statusTooltipTitle}
              />
            );
          })}
        </GridCell>
        <GridCell onClick={handleExpandClick}>
          {!!additionalRowsLength &&
            (isExpanded ? (
              <IconButton size="small" sx={{ ml: 0.75 }}>
                <ChevronUpIcon />
              </IconButton>
            ) : (
              <Avatar
                sx={(theme) => ({
                  my: -1,
                  backgroundColor: 'white',
                  fontSize: theme.spacing(1.5),
                  width: theme.spacing(3),
                  height: theme.spacing(3),
                })}
              >
                +{additionalRowsLength}
              </Avatar>
            ))}
        </GridCell>
      </TableRow>
      <>
        {isExpanded &&
          combinedStaffRow.additionalRows.map(
            ({ item: { registration, id }, additionalPermanentRows }) => {
              const status = propertiesMap.status.find((p) => p.id === id);

              return (
                <TableRow
                  key={registration.id}
                  sx={(theme) => ({
                    td: {
                      backgroundColor: `${theme.palette.background.default} !important`,
                    },
                  })}
                >
                  <GridCell />
                  <GridCell />
                  <GridCell>
                    <Typography color="text.primary">{registration.job_title || '—'}</Typography>
                  </GridCell>
                  <GridCell />
                  <GridCell>
                    <RenderSchoolProperty
                      property={status}
                      defaultValue="-"
                      tooltipTitle={statusTooltipTitle}
                    />

                    {additionalPermanentRows.map(({ id, item }) => {
                      const status = propertiesMap.status.find((p) => p.id === item.id);
                      return (
                        <RenderSchoolProperty
                          property={status}
                          defaultValue="-"
                          tooltipTitle={statusTooltipTitle}
                        />
                      );
                    })}
                  </GridCell>
                  <GridCell />
                </TableRow>
              );
            },
          )}
      </>
    </>
  );
};
